import React, { useRef } from "react";
import { Button, Col, Image, Row, Space, Typography } from "antd";
import CountUp from "react-countup";
import { motion, useInView } from "framer-motion";

const {Text, Title} = Typography
const MarketReach = ()=> {
  const targetRef = useRef(null);
  const isInView = useInView(targetRef, { once: true });

  const data = [
    {
      count: 150,
      sign: "k+",
      title: "Active Users",
      id: "first",
    },
    {
      count: 4.9,
      title: "Rating Out of 5",
    },
    {
      count: 99,
      sign: "k+",
      title: "Positive Reviews",
    },
    {
      count: 85,
      sign: "k+",
      title: "Users Satisfied",
    },
  ];
  return (
    <Row gutter={[24,64]} className='padding'>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <Space size={20} direction='vertical'>
              <Title className='gradient-text'>Empower Your Crypto Brand with Mozeff 3.0 Consultant Services</Title>
              <Text className='text-gray text-justify'>
              At Mozeff 3.0, we redefine crypto marketing with innovative strategies tailored to your blockchain or cryptocurrency project. Our expertise spans strategic branding, data-driven social media campaigns, influencer partnerships, and in-depth market trend analysis. We focus on delivering impactful campaigns that connect with your audience, build credibility, and drive real growth. Whether you're launching a new token, NFT project, or blockchain solution, our team ensures your brand stands out in the ever-evolving crypto landscape. 
              </Text>
              <motion.Flex
                  initial={{ y: -50, opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .2, duration: .3, }}
                  viewport={{once: true}}
              >
                  <Button className='btn w-auto mt-3'>
                      Let's Connect
                  </Button>
              </motion.Flex>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
          <Space className="center">
            <Image src="/assets/images/marketing-3.jpg" height={450} preview={false} />
          </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
          <motion.div ref={targetRef}>
            <Row gutter={[24, 24]}>
              {data?.map((item, index) => (
                <Col span={24} className="text-center"  key={index}>
                  <Space
                    direction="vertical"
                    align="center"
                    className={item?.id === "first" ? null : "marketCount"}
                  >
                    <Typography.Title level={1} className="text-white m-0 fontmarket">
                      {isInView ? (
                        <CountUp duration={2} end={item.count} />
                      ) : (
                        0
                      )}
                      {item?.sign}
                    </Typography.Title>
                    <Typography.Text className="text-white">
                      {item?.title}
                    </Typography.Text>
                  </Space>
                </Col>
              ))}
            </Row>
          </motion.div>
        </Col>
    </Row>
  )
}

export {MarketReach}