import React from 'react'
import { Col,  Flex, Row, Typography } from 'antd'
import { Breadcrumb, MeetTeam, OurHistory, OurMission, OurVision, Typewriter, UnderlineDesign } from '../../components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography


const Aboutus = () => {

    const messages = [
        'Innovating the Future of Finance'
    ]

    const data = {
        subtitle:'Empowering Financial Growth with Web3 Technology',
        img:'abouts.avif',
        partitle:'Our Value',
        detail:[
            {
                desc:'Mozeff was founded with a clear vision: to revolutionize the financial industry by harnessing the power of Web3 technology. We believe that the future of finance should be accessible, transparent, and secure for everyone. Our mission is to break down barriers and create financial solutions that empower individuals and businesses alike.'
            },
            {
                desc:'At the core of our values are innovation, integrity, and inclusivity. We are committed to building a financial ecosystem that is fair, equitable, and sustainable, ensuring that everyone has the opportunity to thrive in the digital economy.'
            }
        ]
    }

  return (
    <div >
        <Breadcrumb title='About Us' pageName='About Us' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'}>
                <Col span={24}>
                    <Flex vertical gap={10} justify='center' align='center' className='text-center mt-5'>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once:true}}
                        >
                            <Text className='text-gray'>
                                {
                                    data?.subtitle
                                }
                            </Text>
                        </motion.div>
                    </Flex>                   
                </Col>
                <Col span={24}>
                    <motion.div
                        initial={{ y: 100, opacity: 0}}
                        whileInView={{
                            opacity: 1,
                            y:0
                        }}
                        transition={{ delay: .4, duration: .3, ease: 'easeInOut' }}
                        viewport={{once:true}}
                    >
                        <Flex justify='center' className='imgcs'>
                            <img src={'/assets/images/'+data?.img} className='img' />
                        </Flex>
                    </motion.div>
                </Col>
                <Col span={24}>
                    <Flex vertical justify='center'>
                        <motion.div
                            initial={{ opacity: 0}}
                            whileInView={{
                                opacity: 1
                            }}
                            transition={{ delay:.5, duration: .3, ease: 'easeInOut' }}
                            viewport={{once:true}}
                        >
                            <Title level={1} className='gradient-text text-center mb-1'>
                                {data?.partitle}
                            </Title>
                            <UnderlineDesign />
                        </motion.div>
                        {
                            data?.detail?.map((item,index)=>
                                <motion.div
                                    key={index}
                                    initial={{ y: '-10%', opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once:true}}
                                    className='py-3'
                                >
                                    <Text className='text-gray fs-16 text-justify' key={index}>
                                        {
                                            item?.desc
                                        }
                                    </Text>
                                </motion.div>
                            )
                        }
                        <Flex justify='center'>
                            <Link to={'tel:0332020302'} className='btn w-auto'>
                                <div className='content vertical '>
                                    <div className="text">Join us now</div>
                                    <div className="hover-text">Join us now</div>
                                </div>
                            </Link>
                        </Flex>
                    </Flex>
                </Col>
                <Col span={24}>
                    <OurMission />
                </Col>
                <Col span={24}>
                    <OurVision />
                </Col>
                <Col span={24}>
                    <OurHistory />
                </Col>
                <Col span={24}>
                    <MeetTeam />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {Aboutus}