import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { blogData, singleBlogData } from '../../../data'
import { Avatar, Card, Col, Divider, Flex, Image, List, Row, Space, Tag, Typography } from 'antd'
import { Breadcrumb } from '../../Breadcrumb'
import CommentForm from './CommentForm'
import { FeatureBlog } from '../FeatureBlog'

const {Title, Text } = Typography
const SingleViewBlog = () => {
  const { blog_id } = useParams()
  const singleBlog = singleBlogData.find(item => item.blog_id == blog_id)
  const blog = blogData.find(item => item.blog_id == blog_id)

  return (
    <div>
      <Breadcrumb title='Blog' pageName='Blog' breadcrumb={[{to:'/',name:'Home'}]}/>
      <div className='container'>
        <div className='padding'>
          <Row gutter={[24,24]}>
            <Col span={24}>
              <Space direction='vertical' size={10}>
                <Title className='text-white m-0'>{blog.title}</Title>
                <Text className='text-gray'> {blog.date} </Text>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }} xl={{span: 17}}>
                <Space className='mb-3'>
                  <Image src='/assets/images/img_1.jpg' alt={''} preview={false} className='rounded radius-10' />
                </Space>
                <Space direction="vertical" size={20} >
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white '>{singleBlog.title_1}</Title>
                    {singleBlog.desc_1.map((text)=>(
                        <Text className='text-gray text-justify'>{text}</Text>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>{singleBlog.title_2}</Title>
                    {singleBlog.desc_2.map((text)=>(
                        <Text className='text-gray text-justify '>{text}</Text>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>{singleBlog.title_3}</Title>
                    {singleBlog.desc_3.map((text)=>(
                        <Text className='text-gray text-justify '>{text}</Text>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>{singleBlog.title_4}</Title>
                    {singleBlog.desc_4.map((text)=>(
                        <Text className='text-gray text-justify '>{text}</Text>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>{singleBlog.title_5}</Title>
                    {singleBlog.desc_5.map((text)=>(
                        <Text className='text-gray text-justify '>{text}</Text>
                    ))}
                  </Space>
                  <Space size={15}>
                    <Avatar size="large" src="/assets/images/img_4.jpg" />
                    <Space direction="vertical" size={0}>
                      <Title level={5} className='m-0 text-white'>Written by</Title>
                      <Text className='text-gray fs-12'>{blog?.author}</Text>
                    </Space>
                  </Space>
                </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 7 }}>
              <Row gutter={[16,16]}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 24 }}>
                  <Card className='blog-about-card author-card'>
                    <Space size={10} direction='vertical'>
                      <Text className='m-0 text-gray fw-400 fs-13'>AUTHOR</Text>
                      <Space size={10}>
                        <Avatar size={55} src='/assets/images/logo-r.png'  />
                        <Space direction='vertical' size={0}>
                          <Title className='m-0 text-white' level={4}>Mozeff</Title>
                          <Text className='fs-12 text-gray'>Web 3.0 Consulting Services</Text>
                        </Space>
                      </Space>
                    </Space>
                  </Card>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 24 }}>
                  <Divider className='bg-divider my-2' />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 24 }}>
                    <FeatureBlog />
                </Col>
                <Col span={24}>
                  <Divider className='bg-divider my-2' />
                </Col>
                <Col span={24}>
                  <Space direction='vertical'>
                    <Title level={4} className='text-white'>More Posts</Title>
                    <List
                      className='text-white sider-list'
                      dataSource={blogData}
                      renderItem={(item) => (
                        <List.Item>
                          <Space>
                            <Row gutter={[16, 16]}>
                              <Col span={10}>
                                <div>
                                  <Image src={item.image} alt={item.title} preview={false} />
                                </div>
                              </Col>
                              <Col span={14}>
                                <Space className='w-100 h-100 ' size={0} direction='vertical'>
                                  <Text className='text-gray'>{item.date}</Text>
                                  <Title ellipsis={true} rows={2} className='m-0 h-100 text-white' level={5}>{item.title}</Title>
                                </Space>
                              </Col>
                            </Row>
                          </Space>
                        </List.Item>
                      )}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider className='bg-divider' />
            </Col>
            <Col span={24}>
                <Space direction='vertical'>
                <Title level={4} className='text-white'>Popular Tags</Title>
                <Space wrap>
                  <Tag className='blog-tag'>Bitcoin</Tag>
                  <Tag className='blog-tag'>Web 2</Tag>
                  <Tag className='blog-tag'>Nft</Tag>
                  <Tag className='blog-tag'>Crypto</Tag>
                  <Tag className='blog-tag'>Web 3</Tag>
                  <Tag className='blog-tag'>Blockchain</Tag>
                  <Tag className='blog-tag'>Web 2</Tag>
                  <Tag className='blog-tag'>Crypto</Tag>
                </Space>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <CommentForm />
            </Col>
          </Row>
        </div>
      </div>

    </div>
  )
}

export {SingleViewBlog}