import { Card, Col, Image, Row, Space, Typography } from 'antd'
import React from 'react'

const {Title, Text} = Typography
const HotCollection =()=> {
    const data =[
        {
            img: '/assets/images/nftslide-1.jpg',
            crypto: 'Aurora Borealis',
            token:"ERC1987",
        },
        {
            img: '/assets/images/nftslide-2.jpg',
            crypto: 'Orion’s Jewel (Epic)',
            token:"BTC20",
        },
        {
            img: '/assets/images/nftslide-3.jpg',
            crypto: 'Celestial Eden (Rare)',
            token:"BNB20",
        },
        {
            img: '/assets/images/nftslide-4.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
        },
        {
            img: '/assets/images/nftslide-4.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
        },
        {
            img: '/assets/images/nftslide-4.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            <Col span={24}>
                <Title className='text-white text-center'>Hot Collections</Title>
            </Col>
            {
                data.map((item, index)=>(
                    <Col xs={{ span: 12 }} sm={{ span: 8 }} md={{ span: 6 }} lg={{ span: 4 }} key={index}>
                        <Card className='nft-collection'>
                            <Space direction='vertical' className='w-100 h-100'>
                                <Image src={item.img} alt={item.img} width='100%' objectFit='cover' className='collection-img'  height={160} />
                                <Space className='w-100 collection-content' direction='vertical' size={0} align='center'>
                                    <Title className='m-0' level={5}>{item.crypto}</Title>
                                    <Text className='text-gray'>{item.token}</Text>
                                </Space>
                            </Space>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    </div>
  )
}

export {HotCollection}