import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Title, Text } = Typography
const IntroGateway = () => {

    const data = [
        {
            icon:'perf.png',
            title:'Performance',
            desc:'Ultimate performance at low cost'
        },
        {
            icon:'wide.png',
            title:'World Wide Service',
            desc:'Servicing over 2000 customers from 100+ countries'
        },
        {
            icon:'currencystore.png',
            title:'Multiple Cryptocurrencies',
            desc:'We are offering 10+ minable cryptocurrencies.'
        },
    ]

  return (
    <Row gutter={[24,64]} align={'middle'} className='padding' justify={'space-between'}>
        <Col lg={{span: 11}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
            <Flex justify='center' className='position-relative'>
                <Image src='/assets/images/gt-1.png' alt='mozeff' width={'90%'} preview={false} />
                <div class="thumb_coin">
                    <img src="/assets/images/coin.png" alt="mozeff" />
                </div>
            </Flex>
        </Col>
        <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
            <Flex vertical align='flex-start' gap={20} className='p-left'>
                <Title className='gradient-text m-0'>
                    About Crypto Payment Gateway
                </Title>
                <Text className='text-gray text-justify'>
                    Mozeff is one of the leading cryptocurrency mining platforms, offering cryptocurrency mining capacities in every range - for newcomers. Our mission is to make acquiring cryptocurrencies easy and fast for everyone.
                </Text>
                {data.map((item)=> (
                    <Space size={12} align='start' className='mb-1'>
                        <div className='icon-div'>
                            <Image src={"/assets/icons/"+item?.icon} alt="check" width={20} height={20} preview={false} />
                        </div>
                        <Flex vertical>
                            <Title level={4} className='gradient-text m-0'>{item?.title}</Title>
                            <Text className='text-gray'>
                                {
                                    item?.desc
                                }
                            </Text>
                        </Flex>
                    </Space>
                ))}
            </Flex>
        </Col>
    </Row>
  )
}

export {IntroGateway}