import React, { useRef, useState } from 'react'
import { Button, Card, Col,  Flex, Row, Typography } from 'antd'
import { motion, useScroll, useTransform } from "framer-motion";
import { EfficientSvg, EfficientSvg1 } from '../../../SvgsFile';
import { Typewriter } from '../../PageComponents';
import { Link } from 'react-router-dom';
const { Text, Title } = Typography

const EfficientSmart = () => {

    // const targetRef = useRef(null)
    // const { scrollYProgress } = useScroll({
    //     target: targetRef,
    //     offset:["end end", "end start"]
    // })
    // const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
    // const scale = useTransform( scrollYProgress, [0, .5], [1, .5] )
    const messages = [
        "Efficient Contract Execution"
    ]

  return (
    <motion.div className='padding'
        // ref={targetRef}
        // style={{
        //     opacity,
        //     scale,
        // }}
    >
        <Row gutter={[24,24]} justify={'space-between'} align={'middle'}>
            <Col lg={{span: 10}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                <Card className='card-grad card-extra'>
                    <motion.div
                        initial={{ x: '-30vw' }}
                        animate={{ x: 500 }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: "loop",
                        }}
                        className='top-small-bar'
                    ></motion.div>
                    <Flex justify='center'>
                        <div style={{width: 220}}>
                            <svg width="100%" height="100%" viewBox="0 0 250 250">
                                <circle cx="125.5" cy="127.5" r="120" stroke="white" stroke-width="1" fill="none" />
                                <motion.circle cx="75" cy="160" r="50" stroke="white" strokeWidth="1" fill="none" 
                                    strokeDasharray="7,5"  
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 10,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }}
                                />
                                <motion.circle cx="177" cy="160" r="50" stroke="white" strokeWidth="1" fill="none" 
                                    strokeDasharray="7,5"  
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 10,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.circle cx="127.5" cy="70" r="50" stroke="white" strokeWidth="1" fill="none" strokeDasharray="5,5" 
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 10,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />

                                <circle cx="5.5" cy="127.5" r="5" fill="white">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 125.5 127.5"
                                        to="360 125.5 127.5"
                                        dur="4s"
                                        repeatCount="indefinite"
                                        begin="2s"
                                    />
                                </circle>

                                <circle cx="125.5" cy="247.5" r="5" fill="white">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 125.5 127.5"
                                        to="360 125.5 127.5"
                                        dur="4s"
                                        repeatCount="indefinite"
                                        begin="2s"
                                    />
                                </circle>

                                <circle cx="125.5" cy="247.5" r="5" fill="white">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 125.5 127.5"
                                        to="360 125.5 127.5"
                                        dur="4s"
                                        repeatCount="indefinite"
                                        begin="3s"
                                    />
                                </circle>
                            </svg>
                        </div>
                    </Flex>
                    <Title level={5} className='m-0 text-white'>Smart Contracts: Automating trust, securing the future</Title>
                    <div className='parent-shap'>
                        <div className='bg-shape'></div>
                    </div>
                </Card>                        
            </Col>
            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} >
                <Flex vertical gap={30}>
                    <Flex gap={10}>
                        {/* <motion.div className='sm-icon'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                            style={{width: 70, height:70}}
                        >
                            <EfficientSvg />
                        </motion.div>
                        <motion.div className='grad-line'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        ></motion.div> */}
                        <motion.div className='sm-icon'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                            style={{width: 40, height:40}}
                        >
                            <EfficientSvg1 />
                        </motion.div>    
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Title level={1} className='m-0 gradient-text'>
                            <Typewriter messages={messages} speed={150} />
                        </Title>
                        </motion.div>
                    </Flex>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Text className='text-gray fs-16'
                        >
                            Our platform ensures your smart contracts are executed seamlessly, optimizing both speed and accuracy.
                        </Text>
                    </motion.div>
                    <motion.Flex
                        initial={{ y: -50, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .6, duration: .3, }}
                        viewport={{once: true}}
                    >
                        <Link to={'/learnmore'} className='btn w-auto'>
                            <div className='content vertical '>
                                <div className="text">Learn More</div>
                                <div className="hover-text">Learn More</div>
                            </div>
                        </Link>
                    </motion.Flex>
                </Flex>
            </Col>
        </Row>
    </motion.div>
  )
}

export {EfficientSmart}