import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Typewriter = ({ messages, speed = 100 }) => {
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageIndex, setMessageIndex] = useState(0);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessage((prev) => {
                const target = messages[messageIndex];
                if (!deleting) {
                    if (prev.length < target.length) return target.slice(0, prev.length + 1);
                    setDeleting(true);
                } else {
                    if (prev.length > 0) return prev.slice(0, -1);
                    setDeleting(false);
                    setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
                }
                return prev;
            });
        }, speed);
        return () => clearInterval(interval);
    }, [deleting, messageIndex, messages, speed]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <span>{currentMessage}</span>
            <motion.span
                animate={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, repeat: Infinity, repeatType: "loop" }}
            >
                |
            </motion.span>
        </motion.div>
    );
};

export {Typewriter};
