import React from 'react'
import { List, Card, Typography, Row, Col, Space, Flex,  } from 'antd'
import { blogData } from '../../../data'
import { useNavigate } from 'react-router-dom'
import { UnderlineDesign } from '../../UnderlineDesign'
import { motion } from 'framer-motion'

const { Text, Title } = Typography
const BlogList = () => {
  const navigate = useNavigate()
  return (
    <div className="py-4">
      <List
        grid={{ gutter: 16, column: 1 }}
        split={true}
        dataSource={blogData}
        pagination={{ pageSize: 3 }}
        className='pagination'
        renderItem={(item) => (
          <List.Item>
            <Card className='blog-card'>
              <Row gutter={[16, 16]}>
                <Col span={10}>
                  <div className='blog-img' >
                    <img src={item.image} alt={item.title} />
                  </div>
                </Col>
                <Col span={14}>
                  <Flex className='h-100 right pr-3 cursor' vertical gap={10} onClick={() => navigate(`/blog/${item.blog_id}`)}>
                    <motion.div
                        initial={{ y: '-10%', opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                        className='mb-1'
                    >
                        <Flex gap={8} vertical>
                            <Text className="text-gray">{item.category}</Text>
                            <UnderlineDesign />
                        </Flex>
                    </motion.div>
                    <Title className='m-0 text-white' ellipsis level={3}>{item.title}</Title>
                    <p className='text-gray w-100' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>{item.description}</p>
                  </Flex>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    </div>
  )
}

export { BlogList }