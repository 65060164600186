import React from 'react'
import { Flex, Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import './index.css'

const { Title, Text } = Typography

const Breadcrumb = ({title,pageName,breadcrumb}) => {
  return (
    <div className='breadcrumb-cs'>
        <div className='container' style={{position:'relative'}}>
            <img src='/assets/images/arm-b.png' className='left-arm' />
            <Flex justify='center'>
                <Title level={1} className='title-size text-white m-0'>{title}</Title>
            </Flex>
            <Flex justify='center'>
                {
                    breadcrumb.map(
                        (breadCrumb,index)=> 
                            <Text strong key={'bread-crumb-'+index}>
                                <NavLink to={breadCrumb?.to} className='text-white'>{breadCrumb?.name}</NavLink>
                                <span className="mx-2 text-white">/</span>
                            </Text>
                        )
                }
                <Text strong  className="text-white">{pageName}</Text>
            </Flex>
            <img src='/assets/images/arm-b.png' className='right-arm' />
        </div>
    </div>
  )
}

export {Breadcrumb}