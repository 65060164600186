import React from "react";
import { motion } from "framer-motion";

const PeertopeerSvg = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        {"\n   gear\n   "}
        <motion.path
          initial={{ opacity: 1 }}
          animate={{ rotate: 360 }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "linear",
          }}
          d="M1425 2788 c-11 -80 -20 -95 -62 -108 -21 -7 -42 -16 -47 -21 -19 -17 -47 -9 -100 30 l-53 39 -67 -67 c-36 -36 -66 -70 -66 -74 0 -4 16 -29 35 -56 19 -26 35 -55 35 -63 0 -9 -11 -40 -24 -71 -26 -58 -29 -59 -118 -72 l-48 -7 0 -98 c0 -110 -7 -101 93 -115 51 -8 54 -11 82 -92 l18 -51 -37 -52 c-20 -28 -36 -55 -34 -60 2 -5 31 -38 66 -74 l63 -66 59 41 58 41 63 -25 c68 -27 73 -34 84 -114 l7 -53 98 0 97 0 15 68 14 67 54 27 c30 15 61 27 69 27 8 1 39 -18 69 -40 l54 -41 70 69 69 70 -42 61 -43 62 24 52 c33 72 35 75 78 82 20 4 49 9 65 12 l27 6 0 98 0 97 -71 14 -71 14 -25 64 -25 63 41 59 42 59 -69 70 -69 69 -54 -39 c-30 -22 -58 -39 -64 -40 -5 0 -37 11 -70 24 l-60 25 -14 70 -13 71 -98 0 -98 0 -7 -52z m180 -14 c8 -20 15 -44 15 -53 0 -33 76 -85 140 -96 24 -4 45 2 85 25 l54 31 36 -32 c54 -48 61 -66 37 -99 -37 -50 -42 -91 -18 -148 28 -66 50 -89 94 -97 68 -13 73 -19 70 -88 l-3 -62 -54 -17 c-67 -22 -79 -32 -102 -86 -26 -61 -24 -110 6 -154 14 -20 25 -40 25 -43 0 -10 -82 -95 -92 -95 -6 0 -22 8 -36 18 -50 35 -86 42 -133 25 -69 -23 -92 -46 -114 -110 l-20 -58 -56 -3 c-65 -3 -89 12 -89 58 0 35 -53 100 -80 100 -11 0 -22 5 -25 10 -3 6 -25 10 -48 10 -40 0 -62 -8 -115 -41 -22 -13 -25 -11 -67 32 l-44 47 30 56 c33 60 33 84 2 149 -25 51 -52 75 -92 82 -56 9 -64 18 -69 77 -3 43 0 56 14 70 11 9 22 17 27 17 60 -1 108 42 133 119 l17 53 -30 58 -30 58 26 29 c14 17 36 38 48 48 21 17 22 17 63 -10 51 -34 97 -35 161 -5 46 22 79 63 79 101 0 47 17 60 80 60 59 0 60 0 75 -36z"
        />
        {" \n   circle\n   "}
        <motion.path
          initial={{ scale: 0.8, opacity: 1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 3, repeat: Infinity, ease: "linear" }}
          d="M1423 2579 c-65 -22 -131 -60 -164 -96 -77 -83 -121 -224 -100 -325 25 -121 105 -229 202 -276 57 -28 160 -46 214 -37 57 8 145 37 145 47 0 4 6 8 13 8 17 0 98 85 125 130 100 171 46 398 -123 505 -91 58 -218 76 -312 44z m192 -24 c215 -56 324 -291 226 -489 -124 -253 -489 -258 -615 -7 -74 145 -46 303 73 414 52 49 74 61 151 83 61 17 95 17 165 -1z"
        />
        {"\n   lines\n   "}
        <motion.path
          initial={{ opacity: 1 }}
          animate={{ y: [0, 30, -30, 0] }}
          transition={{
            duration: 4,
            repeat: 2,
            ease: "linear",
            yoyo: Infinity,
          }}
          d="M1498 1430 l4 -100 -469 0 c-351 0 -472 -3 -481 -12 -8 -8 -12 -57 -12 -155 l0 -143 35 0 35 0 0 120 0 120 446 0 445 0 -3 -120 -3 -120 38 0 38 0 -3 120 -3 120 445 0 445 0 -3 -120 -3 -120 38 0 38 0 -5 152 -5 153 -475 3 -475 2 3 100 3 100 -38 0 -38 0 3 -100z m56 -118 c15 -18 15 -20 -4 -37 -19 -17 -22 -17 -36 -3 -13 12 -14 20 -5 37 14 26 23 26 45 3z"
        />
        {"\n   head\n   "}
        <path d="M505 936 c-37 -16 -92 -77 -101 -112 -11 -42 2 -124 23 -152 65 -88 182 -103 264 -35 93 78 74 240 -34 292 -49 24 -108 26 -152 7z m128 -37 c102 -53 111 -185 16 -245 -147 -93 -296 114 -161 225 28 24 46 31 80 31 24 0 53 -5 65 -11z" />
        {" \n   head\n   "}
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ rotateY: 360, opacity: 1 }}
          transition={{
            duration: 3,
          }}
          d="M1453 931 c-74 -34 -116 -125 -93 -206 15 -53 50 -92 108 -117 84 -37 180 1 222 87 73 152 -82 306 -237 236z m154 -41 c87 -52 83 -188 -7 -239 -91 -51 -193 2 -207 108 -6 47 24 108 65 134 37 23 109 21 149 -3z"
        />
        {"\n   head\n   "}
        <path d="M2411 932 c-122 -61 -135 -234 -23 -306 100 -66 224 -24 268 89 54 143 -108 287 -245 217z m155 -44 c109 -75 69 -237 -64 -255 -46 -6 -88 13 -126 57 -47 56 -27 161 38 201 43 26 111 25 152 -3z" />
        {" \n   body\n   "}
        <path d="M465 507 c-102 -34 -169 -85 -225 -170 -48 -75 -59 -117 -36 -152 l16 -25 355 0 355 0 16 25 c23 35 12 81 -37 154 -76 115 -181 173 -319 178 -52 2 -101 -2 -125 -10z m266 -59 c63 -29 140 -103 169 -164 25 -50 25 -71 3 -83 -24 -12 -631 -13 -655 0 -24 13 -23 32 4 86 51 102 171 184 283 193 81 7 129 -1 196 -32z" />
        {" \n   body\n   "}
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ rotateY: 360, opacity: 1 }}
          transition={{
            duration: 3,
          }}
          d="M1409 501 c-101 -32 -185 -104 -234 -201 -30 -58 -32 -101 -7 -123 17 -15 57 -17 365 -17 343 0 346 0 367 22 26 28 25 41 -5 105 -29 63 -103 145 -161 179 -94 55 -220 69 -325 35z m195 -26 c37 -8 87 -25 110 -39 56 -33 123 -107 148 -164 l21 -46 -22 -17 c-19 -15 -53 -17 -327 -18 -259 -1 -310 1 -330 14 l-23 15 22 52 c41 100 160 192 264 205 26 3 50 7 52 9 6 6 8 5 85 -11z"
        />
        {" \n   body\n   "}
        <path d="M2353 496 c-102 -38 -174 -102 -223 -200 -35 -70 -37 -89 -10 -116 19 -19 33 -20 368 -20 325 0 350 1 365 18 24 26 21 65 -8 123 -92 180 -305 264 -492 195z m275 -38 c88 -33 201 -162 202 -231 0 -10 -11 -22 -25 -27 -26 -10 -567 -13 -622 -4 -17 3 -34 13 -37 21 -9 24 27 94 78 150 101 112 255 147 404 91z" />
      </g>
    </motion.svg>
  );
};

export {PeertopeerSvg};
