import { Button, Col, Flex, Form, Row, Typography } from 'antd'
import React from 'react'
import { MyInput } from '../../PageComponents'

const {Title} = Typography
function CommentForm() {
  const [form] = Form.useForm();

  return (
    <div className='py-4'>
        <Form
            form={form}
            layout='vertical'
            requiredMark={false}
        >
      <Row gutter={[16,16]}>
        <Col span={24}>
          <Title className='text-white' level={1}>Post Commment</Title>
        </Col>
        <Col span={12}>
          <MyInput 
              label='First Name'
              name='firstName'
              message='Please enter first name'
              placeholder='Jane'
          />                
        </Col>
        <Col span={12}>
          <MyInput 
              label='Last Name'
              name='lastName'
              message='Please enter Last name'
              placeholder='Jane'
          />                
        </Col>
        <Col span={24}>
          <MyInput 
            label='Email'
            name='email'
            required
            message='Please enter email'
            placeholder='example@example.com'
          />
        </Col>
        <Col span={24}>
          <MyInput
              textArea
              label='Message'
              name='message'
              required
              message='Please enter message'
              autoSize={{ minRows: 8, maxRows: 10 }}
              placeholder='Write your message here'
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Flex justify='right' >
            <Button className="btn rounded">Submit</Button>
          </Flex>
        </Col>
      </Row>
      </Form>
    </div>
  )
}

export default CommentForm