import { Col, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { SerivcesPieChart } from '../../ChartComponents'
import { UnderlineDesign } from '../../UnderlineDesign'

const {Title, Text} = Typography
const CryptoPieChart = ()=> {
  const datapie = [
    'Decentralized Data Management',
    'Smart Contract Automation',
    'Personalized User Experience',
  ]
  return (
    <Row gutter={[24,64]} className='padding'>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Space direction="vertical" className='h-100 space-mbl' >
              <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                  viewport={{once: true}}
              >
                  <Title level={2} className='gradient-text mb-1'>
                    A New Era of Money
                  </Title>
                  <UnderlineDesign />
              </motion.div>
              <Text className='text-gray text-justify'>Explore how cryptocurrencies like Bitcoin and Ethereum are transforming the way we perceive and use money globally. are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work.Own, trade, and showcase your digital assets with NFTs, the future of creative expression."</Text>
              {datapie.map((item)=> (
              <Space size={12} align='start' className='mb-1'>
                  <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                  <Title level={5} className='gradient-text m-0'>{item}</Title>
              </Space>
          ))}
          </Space>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
        <SerivcesPieChart shades={['#0D0A1F', '#1E1852', '#4726AC', '#8948F8', '#C985FF']} />
      </Col>
    </Row>
  )
}

export {CryptoPieChart}