import React from 'react'
import { Breadcrumb } from '../../components/Breadcrumb/index'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { UnderlineDesign, NftSlider, Typewriter ,HotCollection, NewNft, HowNftWorks, SerivcesPieChart, ServicesLineChart, ServicesBarChart, PricingCards } from '../../components'

const {Title, Text} = Typography
const NftPage = ()=>{
  const messages =[
    'Discover rare digital art and collect NFTs'
  ]
  const datapie = [
    'Decentralized Data Management',
    'Smart Contract Automation',
    'Personalized User Experience',
]
const dataline = [
  {
    icon:"/assets/icons/blockchain.png",
    txt: "Smarter Digital Experiences"
  },
  {
    icon:"/assets/icons/contract.png",
    txt: "Automate with Smart Contracts"
  },
  {
    icon:"/assets/icons/token.png",
    txt: "DeFi for the Future"
  },
]
  return (
    <div>
        <Breadcrumb title='NFT' pageName='NFT' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>
          <Row gutter={[24, 24]} className='padding'>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Space className='w-100 space-mbl'>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                  viewport={{once: true}}
                >      
                  <Title level={1} className='m-0 gradient-text'>
                      <Typewriter messages={messages} speed={150} />
                  </Title>
                </motion.div>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Space direction='vertical' size={10} className='space-mbl'>
                <Text className='text-white fs-16'>Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable</Text>
                <motion.div
                  initial={{ y: '-10%', opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                  viewport={{once: true}}
                  className=''
              >
                  <Flex justify="center" gap={8} align="start" vertical>
                      <Text className="text-gray">Discover NFT's</Text>
                      <UnderlineDesign />
                  </Flex>
                </motion.div>
              </Space>
            </Col>
            <Col span={24}>
                <NftSlider />
            </Col>
            <Col span={24}>
              <PricingCards />
            </Col>
            <Col span={24}>
                <HowNftWorks />
            </Col>
            <Col span={24}>
              <Row gutter={[24,64]} className='padding'>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Space direction="vertical" className='h-100 space-mbl' >
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Title level={2} className='gradient-text mb-1'>
                              Unlocking Digital Art Ownership
                            </Title>
                            <UnderlineDesign />
                        </motion.div>
                        <Text className='text-gray text-justify'>Own, trade, and showcase your digital assets with NFTs, the future of creative expression. NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work.Own, trade, and showcase your digital assets with NFTs, the future of creative expression."</Text>
                        {datapie.map((item)=> (
                        <Space size={12} align='start' className='mb-1'>
                            <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                            <Title level={5} className='gradient-text m-0'>{item}</Title>
                        </Space>
                    ))}
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <SerivcesPieChart />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[24,64]} className='padding'>
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 12}}>
                    <ServicesLineChart />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Space direction="vertical" className='h-100 space-mbl' >
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Title level={2} className='gradient-text mb-1'>
                              Gaming and NFTs
                            </Title>
                            <UnderlineDesign />
                        </motion.div>
                        <Text className='text-gray text-justify'>Revolutionize your gaming experience with NFT-based collectibles and in-game assets. NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work. Revolutionize your gaming experience with NFT-based collectibles and in-game assets."</Text>
                        <Space size={5} className='mt-3'>
                          {dataline.map((item)=> (
                              <Space size={12} align='start' className='mb-1'>
                                  <Image src={item.icon} alt={item.txt} width={40} height={40} preview={false} />
                                  <Title level={5} className='gradient-text m-0'>{item.txt}</Title>
                              </Space>
                              ))}
                        </Space>
                    </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24} >
                <Space size={15} direction='vertical' align='center' className='w-100 padding space-mbl' >
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                      <Title level={2} className='gradient-text mb-1'>
                        NFTs in Real Estate
                      </Title>
                      <Flex justify='center'>
                        <UnderlineDesign />
                      </Flex>
                    </motion.div>
                </Space>
                <ServicesBarChart />
            </Col>
          </Row>
        </div>

    </div>
  )
}

export {NftPage}