import React, { useState } from 'react'
import { Form, Flex, Input, Button, Dropdown, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { Text } = Typography

const SearchFilter = ({placeholder}) => {

    const [ order, setOrder ] = useState(0)

    const items = [
        { label: <Button className='fs-13 border0 bg-transparent'>Web3</Button>, key: 0 },
        { label: <Button className='fs-13 border0 bg-transparent'>Web2</Button>, key: 1 },
    ];

    const onClick = ({ key }) => {
        setOrder(key);
    };    

  return (
    <div className='mt-3'>
        <Form>
            <Flex gap={5} align='center'>
                <Input 
                    className='custom-input placeholder-invert' 
                    suffix={<SearchOutlined style={{fontSize:16}} />} 
                    placeholder={placeholder}
                />
                <Dropdown
                    menu={{ items, onClick }}
                    trigger={['click',]}
                    className='margin-top'
                >
                    <Button className='btn-filter'>
                        <Text className='text-white'>
                            {
                                order == 0 ? 'Web3' : 'Web2'
                            }
                        </Text>
                        <img src="/assets/icons/filter.png" width={20} alt="" />
                    </Button>
                </Dropdown>
            </Flex>
        </Form>
    </div>
  )
}

export {SearchFilter}