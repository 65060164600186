import React from 'react'
import { Button, Card, Col, Flex, Row, Form, Typography, Image } from 'antd'
import { Breadcrumb, Empowercomponent, MyDatepicker, MyInput, Partners, Typewriter } from '../../components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography
const AppointmentPage = () => {

    const [form] = Form.useForm();

    const messages = [
        'Get your appointment quickly'
    ]
    
  return (
    <div>
        <Breadcrumb title='Appointment form' pageName='Appointment form' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[64,24]} justify={'center'} className='mt-10'>
                <Col span={24}>
                    <Flex vertical justify='center' align='center'>
                        <motion.div
                            initial={{ opacity: 0}}
                            whileInView={{
                                opacity: 1
                            }}
                            transition={{ duration: .3, ease: 'easeInOut' }}
                            viewport={{once: true}}    
                        >
                            <Title level={1} className='gradient-text' style={{width: 'content-fit'}}>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Text className='text-gray text-justify'>
                                If you have any questions or are interested in collaboration, feel free to reach out. The Mozeff team is here to help.
                            </Text>
                        </motion.div>
                    </Flex>                   
                </Col>
                <Col lg={{span: 18}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                    <motion.div
                        initial={{ y: '-100%', opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, }}
                        viewport={{once: true}}
                    >
                        <Form 
                            form={form}
                            layout='vertical'
                            requiredMark={false}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <MyInput 
                                        label='First Name'
                                        name='firstName'
                                        required
                                        message='Please enter first name'
                                        placeholder='Jane'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <MyInput 
                                        label='Last Name'
                                        name='lastname'
                                        required
                                        message='Please enter last name'
                                        placeholder='Smith'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <MyInput 
                                        label='Email'
                                        name='email'
                                        required
                                        message='Please enter email'
                                        placeholder='example@example.com'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <MyInput 
                                        label='Phone no'
                                        name='phoneno'
                                        required
                                        message='Please enter phone no'
                                        placeholder='example@example.com'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <MyDatepicker
                                        datePicker 
                                        label='Appointment Date'
                                        name='appointmentdate'
                                        required
                                        message='Please enter appointmentdate'
                                        placeholder='Select appointment date'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <MyInput 
                                        textArea
                                        label='Message'
                                        name='message'
                                        required
                                        message='Please enter message'
                                        autoSize={{ minRows: 8, maxRows: 10 }}
                                        placeholder='Write your message here'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Button className="btn rounded" block>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </motion.div>
                </Col>
                <Col span={24}>
                    <Partners />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {AppointmentPage}