import React from 'react'
import { Card, Col, Flex, Row, Typography } from 'antd'
import { motion} from 'framer-motion'
import { AiSvg, CryptoCurrencySvg, InnovativeSvg, PartnershipSvg, SeamlessSvg } from '../../../SvgsFile'
import Slider from 'react-slick'
import { Typewriter } from '../../PageComponents'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Text, Title } = Typography
const WhyUsHome = () => {

    const messages = [
        "Experience The Benefits Of Our Expertise",
    ];
    
    const data = [
        {
            svg: <InnovativeSvg />,
            title:'Innovative Approach',
            desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, dolorem! Dicta, temporibus.'
        },
        {
            svg: <SeamlessSvg />,
            title:'Seamless Experience',
            desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, dolorem! Dicta, temporibus.'
        },
        {
            svg: <PartnershipSvg />,
            title:'Ongoing Partnership',
            desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, dolorem! Dicta, temporibus.'
        },
        {
            svg: <AiSvg />,
            title:'Artificial Intellegence',
            desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, dolorem! Dicta, temporibus.'
        },
        {
            svg: <CryptoCurrencySvg />,
            title:'Cryptocurrency',
            desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, dolorem! Dicta, temporibus.'
        },
    ]

    var testimonialSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }

  return (
    <motion.div className='padding mt-5'
        // ref={targetRef}
        // style={{
        //     opacity,
        //     scale,
        // }}
        >
        <Flex justify='center' className='mt-5 py-3' align='center' gap={4} vertical>
            <Title className='m-0 text-white'>Welcome to Mozeff Web3 Incubator</Title>
            <Text className='m-0 text-white'>
                Bridging webs 2-3 for business hustles
            </Text>
        </Flex>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Flex vertical justify='center' gap={30} align='center'>
                    <motion.div
                        initial={{ y: '-10%', opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                        className='mt-3'
                    >
                        <Flex justify="center" gap={8} align="center" vertical>
                            <Text className="text-gray">Why Us ?</Text>
                            <UnderlineDesign />
                        </Flex>
                    </motion.div>
                    <Flex vertical gap={5} align='center'>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: 10, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Text className='text-gray fs-16'>
                                Your Personalised Incubator
                            </Text>
                        </motion.div>
                    </Flex>
                </Flex>
            </Col>
            
            <Col span={24} >
                <Slider {...testimonialSlider}>
                {
                    data?.map((items,index)=>
                        <motion.div 
                            key={index}
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5 , duration: .3, type:'spring', stiffness: 200 }}
                            viewport={{once: true}}
                            className='px-2'
                        >
                        <Card className='gradient border-gray h-100'>
                            <Flex vertical gap={10} justify='center' align='center'>
                                <div style={{width: 70,height:70}}>
                                    {
                                        items?.svg
                                    }
                                </div>
                                <Title className='text-white m-0' level={4}>
                                    {
                                        items?.title
                                    }
                                </Title>
                                <Text className='text-gray'>
                                    {
                                        items?.desc
                                    }
                                </Text>
                            </Flex>
                            <div className='parent-shap'>
                                    <div className='bg-shape'></div>
                                </div>
                        </Card>
                    </motion.div>
                   
                    )
                }
                </Slider>
            </Col>
        </Row>

    </motion.div>
  )
}

export {WhyUsHome}