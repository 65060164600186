import React from 'react'
import { Col,  Flex, Image, Row, Space, Typography } from 'antd'
import { BlockChainCard, BlockchainIntro, Breadcrumb, PricingCards, SerivcesPieChart, ServicesBarChart, ServicesLineChart, Typewriter, UnderlineDesign, WhyEffect } from '../../components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const { Text, Title } = Typography
const BlockChainPage = () => {

    const messages = [
        'We are on mission'
    ]

    const data = {
        subtitle:'Our technology is revolutionizing the financial services industry by empowering millions across the globe to authenticate and transact',
        img:'abouts.avif',
        partitle:'Our Value',
        detail:[
            {
                desc:'Mozeff was founded with a clear vision: to revolutionize the financial industry by harnessing the power of Web3 technology. We believe that the future of finance should be accessible, transparent, and secure for everyone. Our mission is to break down barriers and create financial solutions that empower individuals and businesses alike.'
            },
            {
                desc:'At the core of our values are innovation, integrity, and inclusivity. We are committed to building a financial ecosystem that is fair, equitable, and sustainable, ensuring that everyone has the opportunity to thrive in the digital economy.'
            }
        ]
    }

    const datapie = [
        'Decentralized Data Management',
        'Smart Contract Automation',
        'Personalized User Experience',
    ]
    const dataline = [
      {
        icon:"/assets/icons/blockchain.png",
        txt: "Smarter Digital Experiences"
      },
      {
        icon:"/assets/icons/contract.png",
        txt: "Automate with Smart Contracts"
      },
      {
        icon:"/assets/icons/token.png",
        txt: "DeFi for the Future"
      },
    ]
  return (
    <div >
        <Breadcrumb title='Blockchain Technology' pageName='Blockchain Technology' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'}>
                <Col span={24}>
                    <Flex vertical gap={10} justify='center' align='center' className='text-center mt-5'>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once:true}}
                        >
                            <Text className='text-gray'>
                                {
                                    data?.subtitle
                                }
                            </Text>
                        </motion.div>
                    </Flex>                   
                </Col>
                <Col span={24}>
                    <WhyEffect />   
                </Col>
                <Col span={24}>
                    <BlockChainCard />   
                </Col>
                <Col span={24}>
                    <BlockchainIntro />   
                </Col>
                <Col span={24}>
                    <PricingCards />   
                </Col>
                <Col span={24}>
                    <Row gutter={[24,64]} className='padding'>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Space direction="vertical" className='h-100 space-mbl' >
                                <motion.div
                                    initial={{ y: 100, opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once: true}}
                                >
                                    <Title level={2} className='gradient-text mb-1'>
                                        Foundation of Digital Trust
                                    </Title>
                                    <UnderlineDesign />
                                </motion.div>
                                <Text className='text-gray text-justify'>Blockchain ensures transparency and security, paving the way for a decentralized future. cryptocurrency NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work.Own, trade, and showcase your digital assets with NFTs, the future of creative expression."</Text>
                                {datapie.map((item)=> (
                                <Space size={12} align='start' className='mb-1'>
                                    <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                                    <Title level={5} className='gradient-text m-0'>{item}</Title>
                                </Space>
                                ))}
                            </Space>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <SerivcesPieChart />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[24,64]} className='padding'>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <ServicesLineChart />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Space direction="vertical" className='h-100 space-mbl' >
                                <motion.div
                                    initial={{ y: 100, opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once: true}}
                                >
                                    <Title level={2} className='gradient-text mb-1'>
                                        Automation on Blockchain
                                    </Title>
                                    <UnderlineDesign />
                                </motion.div>
                                <Text className='text-gray text-justify'>Smart contracts streamline complex processes with self-executing, reliable agreements. for your digital assets. NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work. Revolutionize your gaming experience with NFT-based collectibles and in-game assets."</Text>
                                <Space size={5} className='mt-3'>
                                {dataline.map((item)=> (
                                    <Space size={12} align='start' className='mb-1'>
                                        <Image src={item.icon} alt={item.txt} width={40} height={40} preview={false} />
                                        <Title level={5} className='gradient-text m-0'>{item.txt}</Title>
                                    </Space>
                                    ))}
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} >
                    <Space size={15} direction='vertical' align='center' className='w-100 padding space-mbl' >
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Title level={2} className='gradient-text mb-1'>
                                Blockchain Beyond Cryptocurrency
                            </Title>
                            <Flex justify='center'>
                                <UnderlineDesign />
                            </Flex>
                        </motion.div>
                    </Space>
                    <ServicesBarChart />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {BlockChainPage}