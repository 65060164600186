import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from '../components/Navbar';
import ScrollTop from '../components/ScrollTop';
import { Aboutus, AppointmentPage, ArtificialIntellegencepage, BlockChainPage, BlogPage, CareerPage, Contactus, CryptoCurrencyPage, CryptoPaymentGatewaypage, ForgotPassword, HomePage, JobsPage, LearnMorePage, Login, NftPage, PrivacyPolicy, Register, Servicespage, TermofUse, TokenomicPage, VisaCardPage, WalletsPage, MarketingPage } from '../pages';
import { Footer, JobAppForm, SingleViewBlog, SingleViewCareer } from '../components';
import { AnimatePresence, motion } from 'framer-motion';
import { SingleViewServices } from '../components/ServicesComponents/SingleViewServices';

const RouterCust = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const [isFooterLinkClicked, setIsFooterLinkClicked] = useState(false);
  const location = useLocation();

  return (
    <>
      <ScrollTop />
      <Navbar isFooterLinkClicked={isFooterLinkClicked} />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route 
            index 
            path="/" 
            element={
              <Transition>
                <HomePage />
              </Transition>
            } 
          />
          <Route 
            path="/about" 
            element={
              <Transition>
                <Aboutus />
              </Transition>
            } 
          />
          <Route 
            path="/contact" 
            element={
              <Transition>
                <Contactus />
              </Transition>
            } 
          />
          <Route 
            path="/privacypolicy" 
            element={
              <Transition>
                <PrivacyPolicy />
              </Transition>
            } 
          />          
          <Route 
            path="/termsuse" 
            element={
              <Transition>
                <TermofUse />
              </Transition>
            } 
          />
          <Route 
            path="/learnmore" 
            element={
              <Transition>
                <LearnMorePage />
              </Transition>
              
            } 
          />
          <Route 
            path="/services" 
            element={
              <Transition>
                <Servicespage />
              </Transition>
              
            } 
          />
          <Route 
            path="/login" 
            element={
              <Transition>
                <Login />
              </Transition>
              
            } 
          />
          <Route 
            path="/register" 
            element={
              <Transition>
                <Register />
              </Transition>
              
            } 
          />
          <Route 
            path="/forgotpassword" 
            element={
              <Transition>
                <ForgotPassword />
              </Transition>
              
            } 
          />
          <Route 
            path="/appointment" 
            element={
              <Transition>
                <AppointmentPage />
              </Transition>
              
            } 
          />
          <Route 
            path="/jobs" 
            element={
              <Transition>
                <JobsPage />
              </Transition>
              
            } 
          />
          <Route 
            path="/blog" 
            element={
              <Transition>
                <BlogPage />
              </Transition>
            } 
          />
          <Route 
            path="/blog/:blog_id" 
            element={
              <Transition>
                <SingleViewBlog />
              </Transition>
            } 
          />
          <Route 
            path="/services/details/:service_id" 
            element={
              <Transition>
                <SingleViewServices />
              </Transition>
            } 
          />
          <Route 
            path="/career" 
            element={
              <Transition>
                <CareerPage />
              </Transition>
            } 
          />
          <Route 
            path="/career/:career_id" 
            element={
              <Transition>
                <SingleViewCareer />
              </Transition>
            } 
          />
          <Route 
            path="/jobform" 
            element={
              <Transition>
                <JobAppForm />
              </Transition>
            } 
          />
          <Route 
            path="/services/ai" 
            element={
              <Transition>
                <ArtificialIntellegencepage />
              </Transition>
            } 
          />
          <Route 
            path="/services/blockchain" 
            element={
              <Transition>
                <BlockChainPage />
              </Transition>
            } 
          />
          <Route 
            path="/services/tokenomic" 
            element={
              <Transition>
                <TokenomicPage />
              </Transition>
            } 
          />
          <Route 
            path="/services/nft" 
            element={
              <Transition>
                <NftPage />
              </Transition>
            } 
          />
          <Route 
            path="/services/wallets" 
            element={
              <Transition>
                <WalletsPage />
              </Transition>
            } 
          />
          <Route 
            path="/services/cryptocurrency" 
            element={
              <Transition>
                <CryptoCurrencyPage />
              </Transition>
            } 
          />
          <Route 
            path="/services/visacard" 
            element={
              <Transition>
                <VisaCardPage />
              </Transition>
            } 
          />
          <Route 
            path="/services/cryptopaymentgateway" 
            element={
              <Transition>
                <CryptoPaymentGatewaypage />
              </Transition>
            } 
          />
          <Route 
            path="/services/marketing" 
            element={
              <Transition>
                <MarketingPage />
              </Transition>
            } 
          />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
};


const Transition = ({ children }) => {
  return (
    <motion.div
      className="page-wrapper"
      initial={{ y: "10%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }} 
      exit={{ y: "-10%", opacity: 0 }}
      transition={{ duration: .3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

export { RouterCust };
