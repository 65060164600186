import { Col, Divider, Row, Space, Typography } from 'antd'
import React from 'react'
import {motion} from 'framer-motion'
import { Breadcrumb, Typewriter ,CareerJobs } from '../../components'
import { div } from 'framer-motion/client'

const {Title} = Typography
const CareerPage = ()=> {

  const messages =[
    'Start Career'
  ]

  return (
    <div>
      <Breadcrumb title='Career' pageName='Career' breadcrumb={[{to:'/',name:'Home'}]}/>
      <div className='container'>
        <Row gutter={[16, 16]}>
          <Col span={24}>
          <Space align='center' className='mt-3 center'>
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                viewport={{once: true}}
            >
                
                <Title level={1} className='m-0 gradient-text'>
                    <Typewriter messages={messages} speed={150} />
                </Title>
            </motion.div>
          </Space>
          </Col>
          <Col span={24}>
              <Divider />
          </Col>
          <Col span={24}>
              <CareerJobs />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export {CareerPage}