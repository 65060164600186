import { useState } from "react";
import "./index.css";
import { Card, Col, Flex, Image, Row, Space, Typography } from "antd";
import { motion } from "framer-motion";

const {Text, Title} = Typography
const FlexCards = () => {
  const [selectedCard, setSelectedCard] = useState("c1");

  const cards = [
    {
      id: "c1",
      title: "Bitcoin",
      description: "Winter has so much to offer - creative activities",
      img:"/assets/images/bitcoin.jpg"
    },
    {
      id: "c2",
      title: "Blockchain",
      description: "Gets better every day - stay tuned",
     
      img:"/assets/images/blockchain.jpg"
    },
    {
      id: "c3",
      title: "Wallet",
      description: "Help people all over the world",
      icon: "3",
      img:"/assets/images/crypto-wallet.jpg"
    },
    {
      id: "c4",
      title: "Mining",
      description: "Space engineering becomes more and more advanced",
      icon: "4",
      img:"/assets/images/mining.jpg"
    }
  ];
  const keypoint = [
    "Cryptocurrencies are virtual currencies secured by cryptography.",
    "Operates without central authority using blockchain.",
    "Bitcoin, Ethereum, Binance Coin, Solana, and more.",
    "Transparent and immutable ledger for recording transactions.",
    "Stored in hot wallets (online) or cold wallets (offline).",
]

  return (
    <Row gutter={[24,24]} className="padding">
      <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} >
      <Space className='w-100' size={25} align='start' direction="vertical">
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                viewport={{once: true}}
            >
                <Title level={1} className='m-0 gradient-text'>
                  Cryptocurrency: Digital Money Revolution
                </Title>
            </motion.div>
            {keypoint.map((item)=> (
              <Space size={12} align='start' className='mb-1'>
                  <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                  <Title level={5} className='gradient-text m-0'>{item}</Title>
              </Space>
              ))}
        </Space>
      </Col>
      <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} >
          <div className="container-flexcard">
            {cards.map((card) => (
              <div 
              key={card.id} 
              className={`card-container1 ${selectedCard === card.id ? "active" : ""} `}
              >
                <Card 
                  className="w-100 h-100 bg-transparent position-relative flexcard-pad border0" 
                  onMouseEnter={()=> setSelectedCard(card.id)} 
                  onMouseLeave={()=>setSelectedCard("c1")}
                  onTouchStart={()=> setSelectedCard(card.id)}
                  >
                    <img 
                      src={card.img} 
                      alt="" 
                      className="flexcard-img"
                      />
                    <Space 
                    className={`h-100 w-100 center ${selectedCard === card.id ? "" : "none"} `} 
                    direction="vertical"
                    style={{background: "#000000c8"}}
                     >
                      <motion.div
                          initial={{ y: 100, opacity: 0 }}
                          whileInView={{
                              opacity: 1,
                              y: 0,
                          }}
                          transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                          viewport={{once: true}}
                      >
                          <Title level={1} className='m-0 gradient-text'>
                            {card.title}
                          </Title>
                      </motion.div>
                    </Space>
                </Card>
              </div>
            ))}
          </div>
      </Col>

    </Row>
  );
};

export { FlexCards };