import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import React from 'react'
import { UnderlineDesign } from '../../UnderlineDesign'

const {Title, Text} = Typography
const EarnCrypto = ()=> {
  return (
    <Row gutter={[24,64]} className='padding'>
      <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
        <Space className='w-100 space-mbl' size={15} align='start' direction="vertical">
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                viewport={{once: true}}
            >
                <Title level={1} className='m-0 gradient-text'>
                    Deposit crypto earn <br />interest
                </Title>
            </motion.div>
            <Text className='text-gray fs-16'>Choose from 150+ cryptocurrencies, including Bitcoin, Ethereum, and many altcoins, to diversify your portfolio. Trade, invest, and exchange your digital assets with ease, all while benefiting from secure and fast transactions.</Text>
        </Space>
      </Col>
      <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
        <Space className='w-100'>
          <Image src="/assets/images/crypto-chart2.png" preview={false} />
        </Space>
      </Col>
    </Row>
  )
}

export {EarnCrypto}