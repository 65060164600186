import { Card, Col, Flex, Image, List, Row, Space, Tag, Typography } from 'antd'
import React from 'react'
import { blogData, careerData } from '../../../data'
import { useNavigate } from 'react-router-dom'
import { UnderlineDesign } from '../../UnderlineDesign'
import { motion } from 'framer-motion'

const {Title, Text } = Typography
function CareerJobs() {
  const navigate = useNavigate()

  return (
    <div>
          <List
          grid={{ gutter: 16, column: 1 }}
          split={true}
          dataSource={careerData}
          pagination={{ pageSize: 3 }}
          className='pagination'
          renderItem={(item) => (
            <List.Item>
              <Flex justify='space-between' align='center'>
                <motion.div
                  initial={{ y: '-10%', opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                  viewport={{once: true}}
                  className='mb-2'
              >
                  <Flex gap={8} vertical>
                      <Title level={4} className="text-gray m-0">{item.type}</Title>
                      <UnderlineDesign />
                  </Flex>
              </motion.div>
              <Tag className='blog-tag'>{item.availjob} role</Tag>  
              </Flex>
              <Card className='career-card'>
                  <Flex justify='space-between' gap={30} className='py-2 w-100' align='center' wrap>
                    <Space direction='vertical w-100' size={15} wrap>
                      <Title className='m-0 text-white w-100'  ellipsis={true} level={4}>{item.title}</Title>
                      <p className='text-gray' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden', flexWrap:"wrap" }}>{item.description}</p>
                    </Space>
                    <Space size={10}>
                      <Space direction='vertical'>
                        <Title className='m-0 text-white' ellipsis level={3}>{item.location}</Title>
                        <Text className='text-gray' >{item.time}</Text>
                      </Space>
                      <Image src='/assets/icons/right-nav.png' width={50} height={50} preview={false} onClick={() => navigate(`/career/${item.job_id}`)}/>
                    </Space>
                  </Flex>
              </Card>
            </List.Item>
          )}
        />
    </div>
  )
}

export {CareerJobs}