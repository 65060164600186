import React from "react";
import { Card, Col, Flex, Row, Typography } from "antd";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import {
  AiSvg,
  CryptoCurrencySvg,
  NftSvg,
  SmartContractSvg,
  DigitalCurrencySvg,
  DecentralizedFinanceSvg,
  DeeplearningSvg,
  SemanticsWebSvg,
  ThreeDgraphicSvg,
  BlocksChainSvg,
  MachinelearningSvg,
  PeertopeerSvg,
  EnhanceConnectSvg
} from "../../../SvgsFile";
import { UnderlineDesign } from "../../UnderlineDesign";

const { Text, Title } = Typography;
const Servicescomponent = () => {
  const data = [
    {
      img: <AiSvg />,
      title: "Artificial Integence",
    },
    {
      img: <NftSvg />,
      title: "NFTS",
    },
    {
      img: <CryptoCurrencySvg />,
      title: "Crypto Currency",
    },
    {
      img: <BlocksChainSvg />,
      title: "Blockchain",
    },
    {
      img: <MachinelearningSvg />,
      title: "Machine Learning",
    },
    {
      img: <DeeplearningSvg />,
      title: "Deep Learning",
    },
    {
      img: <SmartContractSvg />,
      title: "Smart Contract",
    },
    {
      img: <DigitalCurrencySvg />,
      title: "Digital Currency",
    },
    {
      img: <DecentralizedFinanceSvg />,
      title: "Decentralized Finance",
    },
    {
      img: <SemanticsWebSvg />,
      title: "Semantic Web",
    },
    {
      img: <ThreeDgraphicSvg />,
      title: "3D Graphic",
    },
    {
      img: <PeertopeerSvg />,
      title: "Peer-to-peer Network",
    },
    {
      img: <EnhanceConnectSvg />,
      title: "Enhance Connectivity",
    },
  ];

  return (
    <div className="padding">
      <Row gutter={[24, 24]} align={"middle"}>
        <Col span={24}>
          <Flex vertical justify="center" gap={30} align="center">
            <motion.div
              initial={{ y: "-100%", opacity: 0 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                delay: 0.2,
                duration: .3,
                type: "spring",
                stiffness: 300,
              }}
              viewport={{ once: true }}
            >
              <Flex justify="center" gap={8} align="center" vertical>
                <Text className="text-gray">Services</Text>
                <UnderlineDesign />
              </Flex>
            </motion.div>
            <Flex vertical gap={5} align="center">
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{
                  opacity: 1,
                }}
                transition={{ duration: .3, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                <Title level={1} className="text-center m-0 text-white">
                  All You Want, <span className="bg-text">Delivered</span> On Time
                </Title>
              </motion.div>
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  delay: 0.5,
                  duration: .3,
                  type: "spring",
                  stiffness: 300,
                }}
                viewport={{ once: true }}
              >
                <Text className="text-gray fs-16" justify>
                  Your Personalised Incubator
                </Text>
              </motion.div>
            </Flex>
          </Flex>
        </Col>
        <Col span={24}>
          <Marquee
            gradientWidth={300}
            gradient={true}
            gradientColor="rgba(0, 0, 0, 1), rgba(24, 35, 47, .2)"
            className="marquee-cs"
          >
            {data?.map((items, index) => (
                <Card className="gradient border-gray" key={index}>
                    <Flex vertical gap={20} align="center">
                        <div style={{width: 70, height: 70}}>
                            {
                                items?.img
                            }
                        </div>
                        <Title level={5} className="m-0 text-white">
                            {items?.title}
                        </Title>
                    </Flex>
                    <div className="parent-shap">
                        <div className="bg-shape"></div>
                    </div>
                </Card>
            ))}
          </Marquee>
        </Col>
        <Col span={24}>
          <Marquee
            gradientWidth={300}
            gradient={true}
            gradientColor="rgba(0, 0, 0, 1), rgba(24, 35, 47, .2)"
            className="marquee-cs"
            direction="right"
          >
            {data?.map((items, index) => (
                <Card className="gradient border-gray" key={index}>
                    <Flex vertical gap={10} align="center">
                        <div style={{width: 70, height: 70}}>
                            {
                                items?.img
                            }
                        </div>
                        <Title level={5} className="m-0 text-white">
                            {items?.title}
                        </Title>
                    </Flex>
                    <div className="parent-shap">
                        <div className="bg-shape"></div>
                    </div>
                </Card>
            ))}
          </Marquee>
        </Col>
      </Row>
    </div>
  );
};

export { Servicescomponent };
