import React, { useRef } from 'react'
import {Row, Col, Typography, Flex, Card, Image, Button} from 'antd';
import { motion, useScroll, useTransform } from 'framer-motion';
const { Title, Text } = Typography;
function RealityDream() {

        // const targetRef = useRef(null)
        // const { scrollYProgress } = useScroll({
        //     target: targetRef,
        //     offset:["end end", "end start"]
        // })
        // const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
        // const scale = useTransform( scrollYProgress, [0, .5], [1, 1.5] )

        return (
            <motion.div className='padding'
            // ref={targetRef}
            // style={{
            //     opacity,
            //     scale,
            // }}
            >
                <Row gutter={[24,24]} justify={'center'}>
                    <Col span={24}>
                        <Card className='template faqs p-3'>
                            
                            <Flex vertical justify='center' gap={20} align='center'>
                                <motion.div
                                    initial={{ y: -100, opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once: true}}
                                >
                                    <Image src='/assets/images/logo-r.png' width={140} preview={false} />
                                </motion.div>
                                <motion.div
                                    initial={{ y: '-100%', opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once: true}}
                                >
                                    <Title level={1} className='text-center m-0 text-white'>
                                        Your Personalised <br /> Incubator
                                    </Title>
                                </motion.div>
                                <motion.div
                                    initial={{ y: 100, opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once: true}}
                                >
                                    <Text className='text-gray fs-16'>
                                        We bring your vision to life with creativity <br /> and precision. Let's make it happen.
                                    </Text>
                                </motion.div>
                                <motion.Flex
                                    initial={{ y: -50, opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .2, duration: .3, }}
                                    viewport={{once: true}}
                                >
                                    <Button className='btn w-auto'>
                                        Book a Demo
                                    </Button>
                                </motion.Flex>
                            </Flex>
                            <div className='gradient-rad'></div>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        )
    }

export {RealityDream}