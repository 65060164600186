import { Divider, Typography } from 'antd'
import React from 'react'
import { Row, Col, Flex } from 'antd'
import { motion } from 'framer-motion'
import { BlogJoinUs, BlogList, BlogSider, Breadcrumb, TrendingTopic, Typewriter } from '../../components'

const { Title, Text } = Typography
const BlogPage = () => {
  

  return (
      <div >
        <Breadcrumb title='Blog' pageName='Blog' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'}>
                <Col span={24}>
                  <TrendingTopic />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
                  <BlogList />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                  <BlogSider />
                </Col>
                <Col span={24}>
                  <Divider className='bg-divider' />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                  <BlogJoinUs />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export { BlogPage }