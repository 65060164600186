import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { Breadcrumb, PricingCards,  ServicesBarChart, TokenomicLineChart, UnderlineDesign } from '../../components'
import { servicesData } from '../../data'
import { motion } from 'framer-motion'
import { TokenomicPieChart } from '../../components/TokenomicComponents/TokenomicPieChart'

const {Title, Text} = Typography
const TokenomicPage = ()=> {
const dataintro = [
  'Decentralized Data Management',
  'Smart Contract Automation',
  'Personalized User Experience',
  'Smart Contract Automation',
]
  return (
    <div>
      <Breadcrumb title='Tokenomic' pageName='Tokenomic' breadcrumb={[{to:'/',name:'Home'}]}/>
      <div className='container'>
        <div className='padding'>
          <Row gutter={[24,64]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <Space size={10} direction='vertical'>
                <Flex vertical>
                  <Title className='gradient-text mb-1'>Tokenomic</Title>
                  <UnderlineDesign />
                </Flex>
                <Text className='text-gray text-justify'>{servicesData[4].desc}</Text>
                {dataintro.map((item)=> (
                  <Space size={12} align='start' className='mb-1'>
                      <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                      <Title level={5} className='gradient-text m-0'>{item}</Title>
                  </Space>
                ))}
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <Flex justify='end'>
                <Image src='/assets/images/img_5.jpg' height={350} className='rounded' preview={false} />
              </Flex>
            </Col>
            <Col span={24}>
              <PricingCards />
            </Col>
            <Col span={24}>
                <Row gutter={[24,64]} className='padding'>
                  <Col  xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <div className='parent-div'>
                      <div className='bg-shap'></div>
                      <img src='/assets/images/img_3.jpg' className='upper-img'/>
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <Space size={10} direction="vertical">
                      <Flex vertical className='mb-2'>
                        <Title className='gradient-text mb-1'>{servicesData[4].title_1}</Title>
                        <UnderlineDesign />
                      </Flex>
                      {servicesData[4].desc_1.map((item)=> (
                        <Space size={12} align='start' className='mb-2'>
                          <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                          <Text className='text-gray fs-13'>{item}</Text>
                        </Space>
                      ))}
                    </Space>
                  </Col>
                </Row>
            </Col>
            <Col span={24}>
                <TokenomicPieChart />
            </Col>
            <Col span={24}>
                <TokenomicLineChart />
            </Col>
            <Col span={24} >
                <Space size={15} direction='vertical' align='center' className='w-100 padding space-mbl' >
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Title level={2} className='gradient-text'>
                          Crafting Effective Tokenomics for Blockchain Success
                        </Title>
                        <Flex justify='center'>
                          <UnderlineDesign />
                        </Flex>
                    </motion.div>
                </Space>
                <ServicesBarChart />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export {TokenomicPage}