import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Text, Title } = Typography
const AiIntroduction = () => {

    const data = [
        'Decentralized Data Management',
        'Smart Contract Automation',
        'Personalized User Experience',
        'Tokenized AI Models',
        'Trust and Transparency'
    ]

  return (
    <Row gutter={[24,64]} className='padding'>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Space size={10} direction='vertical'>
                <Title className='m-0 gradient-text'>Artificial Intelligence</Title>
                <UnderlineDesign />
                <Text className='text-gray text-justify'>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis illo quod, repudiandae cum explicabo sed ducimus voluptas perspiciatis autem assumenda sit officia tempore ipsum nulla corporis nesciunt numquam unde hic?
                </Text>
                {data.map((item)=> (
                    <Space size={12} align='start' className='mb-1'>
                        <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                        <Title level={5} className='gradient-text m-0'>{item}</Title>
                    </Space>
                    ))}
            </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Flex justify='end'>
                <Image src='/assets/images/ai.jpg' height={350} width={'100%'} className='rounded' preview={false} />
            </Flex>
        </Col>
    </Row>
  )
}

export {AiIntroduction}