import React from 'react'
import { Card, Col, Flex, Image, Row, Space, Typography } from 'antd'
import { UnderlineDesign } from '../../UnderlineDesign'

const {Title, Text} = Typography
const VisaCards = ()=> {
    const data = [
        {
            id:1,
            img:'classiccard.png',
            title:'Classic Card',
            desc:'Be it traveling, shopping or dining, Visa Classic cards are accepted at tens of millions of locations around the world.',
        },
        {
            id: 2,
            img:'visagold.png',
            title:'Visa Gold Card',
            desc:'Enjoy the finer side of life with a high spending line, a revolving line of credit, emergency card replacement, and cash disbursement services.',
        },
        {
            id: 3,
            img:'visaplatinum.png',
            title:'Visa Platinum Card',
            desc:'From golf to dining to shopping and more, Visa Platinum lets you enjoy a host of attractive lifestyle privileges and experiences.',
        },
        {
            id: 4,
            img:'visasign.png',
            title:'Visa Signature Card',
            desc:'Designed to enrich your lifestyle, Visa Signature provides you with exceptional spending power, a high level of rewards, priority customer service.',
        },
        {
            id: 5,
            img:'visainfinite.png',
            title:'Visa Infinite Card',
            desc:'Enjoy excellent spending power with a high credit limit, the convenience of Visa Infinite Concierge, personalized privileges, rewards and service.',
        },
    ]
  return (
    <div className='padding'>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Flex vertical align='center' gap={10} className='mb-3'>
                    <Title className='gradient-text text-center m-0'>
                        Visa Cards
                    </Title>
                    <UnderlineDesign />
                    <Text className='text-gray'>
                        Your Personalised Incubator
                    </Text>
                </Flex>
            </Col>
            {
                data?.map((items, index)=>
                    <Col lg={{span: 8}} md={{span: 8}} sm={{span: 12}} xs={{span: 24}} key={index}>
                        <Card className='bg-transparent border0 rounded shadow card-design h-100'>
                            <Flex vertical gap={10} className='py-3'>
                                <Image src={"/assets/images/"+items?.img} height={200} width={'100%'} preview={false} className='rounded' alt="" />
                                <Title level={3} className='m-0 gradient-text'>
                                    {
                                        items?.title
                                    }
                                </Title>
                                <Text className='text-white'>
                                    {items?.desc}
                                </Text>
                            </Flex>
                        </Card>         
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {VisaCards}