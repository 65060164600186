import React from 'react'
import { Button, Card, Col, Flex, Row, Form, Typography, Image, Divider, Checkbox } from 'antd'
import { Breadcrumb, MyInput } from '../../components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography
const ForgotPassword = () => {

    const [form] = Form.useForm();

  return (
    <div>
        <Breadcrumb title='Sign Up' pageName='Sign Up' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row justify={'center'}>
                <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                    <Card className='bg-transparent shadow border0 my-5'>
                        <Flex vertical align="center" justify="center" gap={20}>
                            <motion.div
                                initial={{ y: '-100%', opacity: 0 }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                }}
                                transition={{ delay: .1, duration: .3 }}
                                viewport={{once: true}}>
                                <Image
                                    src={"/assets/images/logo-r.png"}
                                    alt='Mozeff'
                                    preview= {false}
                                    width={'100px'}
                                />
                            </motion.div>
                            <Flex vertical gap={3} align='center'>
                                <motion.div
                                    initial={{ y: '-100%', opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .2, duration: .3, }}
                                    viewport={{once: true}}>
                                    <Title 
                                        level={3} 
                                        className="text-white m-0"
                                        >
                                        Welcome to Mozeff
                                    </Title>
                                </motion.div>
                                <motion.div
                                    initial={{ y: '-100%', opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .2, duration: .3, }}
                                    viewport={{once: true}}>
                                    <Text 
                                        level={5} 
                                        className="text-gray"
                                        >
                                        Request new password
                                    </Text>
                                </motion.div>
                            </Flex>
                        </Flex>
                        <motion.div
                            initial={{ y: '100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, }}
                            viewport={{once: true}}
                        >
                            <Form 
                                form={form}
                                layout='vertical'
                                requiredMark={false}
                                className='mt-3'
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <MyInput 
                                            label='Email / Phone number'
                                            name='email'
                                            required
                                            message='Please enter email / phone number'
                                            placeholder=''
                                        />
                                    </Col>
                                    {/* <Col span={24}>
                                        <MyInput 
                                            label='Password'
                                            type='password'
                                            name='password'
                                            required
                                            message='Please enter password'
                                            placeholder=''
                                        />
                                    </Col> */}
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                        <Button className="btn rounded mt-2" block>Request for new password</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .4, duration: .3, }}
                            viewport={{once: true}}
                        >
                            <Divider style={{borderColor:'gray'}} className='text-gray mt-4'>
                                OR
                            </Divider>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, }}
                            viewport={{once: true}}
                        >
                            <Flex gap={10} justify='center' className='mt-4'>
                                <Link to={'/'} className='btn-sign fb-color'>
                                    <Image src='/assets/icons/fb.png' width={25} preview={false} />
                                    <Text className='text-white'>Continue with Facebook</Text>
                                </Link>
                                <Link to={'/'} className='btn-sign gm-color'>
                                    <Image src='/assets/icons/gm.png' width={25} preview={false} />
                                    <Text className='text-white'>Continue with Google</Text>
                                </Link>
                            </Flex>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, }}
                            viewport={{once: true}}
                        >
                            <Flex gap={10} justify='center' className='mt-3'>
                                <Text className='text-gray'>
                                    If you have account then
                                    <Link to={'/login'} className='px-2 fw-400'>
                                        Sign In
                                    </Link>
                                </Text>
                            </Flex>
                        </motion.div>
                    </Card>  
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {ForgotPassword}