import { HeartOutlined } from '@ant-design/icons'
import { Card, Col, Flex, Image, Row, Space, Typography } from 'antd'
import React from 'react'
import Marquee from 'react-fast-marquee'

const {Title, Text} = Typography
const NewNft = ()=> {
    const data =[
        {
            img: '/assets/images/nft-1.jpg',
            crypto: 'Aurora Borealis',
            token:"ERC1987",
            value: "0.029 ETH",
            like: 10,
        },
        {
            img: '/assets/images/nft-2.jpg',
            crypto: 'Orion’s Jewel (Epic)',
            token:"BTC20",
            value: "0.029 ETH",
            like: 70,  
        },
        {
            img: '/assets/images/nft-3.jpg',
            crypto: 'Celestial Eden (Rare)',
            token:"BNB20",
            value: "0.029 ETH",
            like: 100,
        },
        {
            img: '/assets/images/nft-4.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 100,
        },
        {
            img: '/assets/images/nft-5.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 40,
        },
        {
            img: '/assets/images/nft-5.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
        {
            img: '/assets/images/nft-6.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
        {
            img: '/assets/images/nft-7.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
        {
            img: '/assets/images/nft-8.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
        {
            img: '/assets/images/nft-9.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        }, 
        {
            img: '/assets/images/nft-10.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        }, 
        {
            img: '/assets/images/nft-11.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        }, 
        {
            img: '/assets/images/nft-12.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
        {
            img: '/assets/images/nft-13.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
        {
            img: '/assets/images/nft-14.jpg',
            crypto: 'Mr. Monkey',
            token:"SOL20",
            value: "0.029 ETH",
            like: 30,
        },
    ]
  return (
    <div>

        <Row gutter={[16,16]}>
            <Col span={24}>
                <Title className='text-white text-center'>New NFT</Title>
            </Col>
            <Col span={24}>
            <Marquee
                gradientWidth={240}
                gradient={true}
                gradientColor="rgba(0, 0, 0, 1), rgba(24, 35, 47, .2)"
                className="marquee-cs"
                direction="right"
              >
            {
                data.map((item, index)=>(
                    
                        <Card className='new-nft' key={index}>
                            <Space direction='vertical' className='w-100 h-100'>
                                <Image src={item.img} alt={item.img} width='100%' objectFit='cover' className='collection-img'  height={200} />
                                <Flex justify='space-between'  className='w-100 h-100' direction='vertical' size={0} >
                                    <Space direction='vertical'>
                                        <Title className='text-white m-0' level={5}>{item.crypto}</Title>
                                        <Text className='text-gray'>{item.token}</Text>
                                        <Text className='  gradient-txt text-white pointer' >Place a bid</Text>
                                    </Space>
                                    <Space direction="vertical">
                                        <Text className='text-white fs-12'>{item.value} 1/20</Text>
                                        <Text className='text-gray fs-12'>{item.like} <HeartOutlined size="small" /> </Text>
                                    </Space>
                                </Flex>
                            </Space>
                        </Card>
                ))
            }
            
            </Marquee>
            </Col>
        </Row>
    </div>
  )
}

export {NewNft}