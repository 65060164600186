export const servicesData = [
  {
    key:1,
    title: 'BlockChain Solution',
    desc: "Blockchain technology is a groundbreaking solution that offers transparency, security, and efficiency across various industries. By leveraging a decentralized and tamper-proof ledger, blockchain is reshaping how businesses operate and interact. We help you optimize your business processes by automating tasks, reducing transaction costs, and eliminating intermediaries. Through blockchain technology, we empower businesses to create more transparent, secure, and efficient systems. We also offer blockchain integration services to connect your existing platforms with blockchain technology, enabling smooth and secure transactions across various ecosystems. Our team ensures that the blockchain solutions we create are compatible with other technologies, providing the flexibility and scalability needed for long-term success.",
    title_1: "Real-World Applications of Blockchain",
    desc_1: [
      'Blockchain ensures end-to-end visibility, tracking products from production to delivery.',
      'Blockchain enables faster cross-border payments, reduces transaction fees, and enhances security ',
      'Medical records can be securely stored and accessed via blockchain, ensuring patient privacy ',
      'Blockchain-based identity solutions provide secure, verifiable, and self-sovereign digital',
      'Blockchain can eliminate voter fraud by ensuring transparency and security in electoral processes.'
    ],
    detail_overview:"At Mozeff, we offer comprehensive blockchain solutions designed to revolutionize businesses and industries by leveraging the power of decentralized technology. Blockchain provides a secure, transparent, and immutable way of recording transactions and data, making it an ideal solution for industries ranging from finance and healthcare to supply chain management and beyond. Our team specializes in building custom blockchain systems that are secure, scalable, and tailored to meet the unique needs of your business. We provide end-to-end blockchain development services, including the design, implementation, and integration of blockchain networks, ensuring seamless adoption and efficient functionality. Whether you need a private blockchain for internal operations or a public blockchain for decentralized applications, our experts work with you to create a robust infrastructure that guarantees high performance, security, and scalability. Our blockchain solutions include the development of smart contracts, decentralized applications (dApps), token creation, and more."
  },
  {
    key:2,
    title: 'Smart Contract Security',
    desc: "Smart contracts are self-executing contracts with the terms of the agreement directly written into lines of code. They run on blockchain platforms like Ethereum, enabling automated and decentralized execution of agreements. While they promise to simplify processes, reduce intermediaries, and ensure trust, they also come with significant security concerns. Ensuring the security of smart contracts is crucial, as vulnerabilities in their code can lead to financial losses, legal issues, or exploitation.",
    title_1: "Key Features of Smart Contracts",
    desc_1: [
      'They are autonomous and do not require human intervention after deployment.',
      'All participants have access to the contract and can view its terms.',
      'Blockchain provides a high level of security, ensuring that the contract cannot be altered after deployment.',
      'Smart contracts automate processes, reducing time and cost by removing intermediaries.',
      'Once executed, the contract cannot be changed, providing certainty and trust in its execution.'
    ],
    detail_overview:"Smart contracts are digital, self-executing agreements with terms directly written into code, running on blockchain networks like Ethereum or Binance Smart Chain. They automatically execute and enforce contract terms without the need for intermediaries, reducing reliance on traditional legal systems or third-party institutions. By leveraging blockchain's decentralized nature, smart contracts ensure that all parties involved can trust the system, as they are transparent, immutable, and tamper-proof once deployed. These contracts are triggered when specific conditions are met, such as transferring assets, executing payments, or verifying information. Their applications span multiple industries, including decentralized finance (DeFi), supply chain management, real estate, healthcare, and even voting systems, enabling more efficient, secure, and automated processes. However, despite their potential, smart contracts also present challenges. Code vulnerabilities and bugs in the smart contract logic can lead to security risks or financial loss, which is why thorough auditing and testing are essential before deployment. Additionally, while smart contracts are legally binding within their respective blockchain environments, their acceptance and legal recognition in traditional legal systems remain complex. Despite these challenges, the adoption of smart contracts continues to grow, as they reduce costs, increase transparency, and improve trust, all while enabling faster and more efficient business transactions. The future of smart contracts lies in their ability to transform industries by automating complex workflows and bridging the gap between the digital and physical worlds."
  },
  {
    key:3,
    title: 'DeFi Applications',
    desc: "Decentralized Finance (DeFi) has revolutionized the financial landscape, providing peer-to-peer financial services without the need for traditional intermediaries like banks. At [Your Company Name], we specialize in building and securing DeFi applications that offer innovative, transparent, and secure financial solutions on blockchain networks. Our DeFi services ensure that your projects are robust, scalable, and resistant to the risks inherent in decentralized financial ecosystems.",
    title_1: "Our DeFi Application Services",
    desc_1: [
      'We offer end-to-end development of DeFi applications that allow users to engage in lending, borrowing, trading, staking',
      'Security is critical in the DeFi space due to the large amounts of assets involved. We provide DeFi protocol security audits to ensure the safety of your application.',
      'Providing liquidity is a key aspect of DeFi applications, and we offer solutions to help your platform attract and maintain liquidity.',
      'We develop decentralized exchanges (DEXs) that allow users to trade assets directly from their wallets.',
      'Oracles are crucial for bringing real-world data to DeFi applications. We integrate reliable oracles to fetch off-chain data'
    ],
    detail_overview:"At Mozeff, we specialize in building and securing decentralized finance (DeFi) applications that leverage blockchain technology to offer transparent, secure, and innovative financial solutions without relying on intermediaries. Our DeFi services cover a wide range of functionalities, from smart contract development for lending, borrowing, and trading to the creation of decentralized exchanges (DEXs), tokenization of assets, and liquidity solutions. We prioritize security at every step of the development process, offering in-depth smart contract audits, risk assessments, and bug bounty programs to protect your platform from vulnerabilities. Additionally, we provide solutions for decentralized governance through DAOs, cross-chain functionalities, and the integration of oracles for accurate off-chain data. With a focus on user experience and robust risk management, we help create secure, scalable, and innovative DeFi platforms that empower users while minimizing financial risks. Whether you’re building a lending platform, a DEX, or an insurance protocol, our team is equipped to deliver custom solutions that meet your unique needs in the rapidly evolving DeFi landscape."
  },
  {
    key:4,
    title: 'Nft Development',
    desc: "NFTs (Non-Fungible Tokens) are revolutionizing the digital world by allowing the creation of unique, verifiable digital assets on the blockchain. Whether you're an artist, a brand, or a business, we provide comprehensive NFT development services to help you bring your ideas to life in the blockchain ecosystem.",
    title_1: "Our NFT Development Services",
    desc_1: [
      'We help you create unique, verifiable NFTs for various purposes, whether its for art, gaming, collectibles.',
      'Our team specializes in developing smart contracts that manage the minting, transferring, and ownership of NFTs.',
      'We design and develop user-friendly NFT marketplaces for buying, selling, and trading NFTs.',
      'We provide minting services that enable the creation and deployment of NFTs on various blockchain',
      'Our NFTs are designed to be compatible across major blockchain platforms such as Ethereum'
    ],
    detail_overview:"At Mozeff, we offer comprehensive NFT development services that empower businesses and creators to enter the world of non-fungible tokens (NFTs) with custom solutions. Our team specializes in end-to-end NFT development, from creating unique and high-value NFTs to developing smart contracts for minting, buying, and selling these digital assets. We work with various blockchain platforms such as Ethereum, Binance Smart Chain, and Flow to build NFTs that offer true ownership, rarity, and authenticity. In addition to NFT creation, we also offer marketplace development, enabling seamless buying, selling, and trading of NFTs. Our platforms integrate user-friendly interfaces, secure wallet connections, and robust backend architecture to provide a smooth and secure experience for both creators and collectors. We also focus on community engagement, ensuring that NFT projects come with marketing strategies and features like rewards, staking, and royalties that promote long-term sustainability.Whether you're looking to tokenize digital art, music, videos, virtual real estate, or even in-game assets, our custom NFT solutions are designed to meet the needs of the modern digital economy. With a focus on innovation and security, we help businesses and individuals unlock the potential of NFTs and create lasting digital assets that redefine ownership in the digital world."
  },
  {
    key:5,
    title: 'Tokenomics Strategy',
    desc: "A Token Strategy is a critical component for any blockchain-based project, as it defines how the tokens will be distributed, utilized, and governed within the ecosystem. Whether you are creating a cryptocurrency, a utility token, or a token for a decentralized application (DApp), having a well-defined token strategy is crucial for the long-term success of the project. Here’s an overview of the key components and considerations for developing a token strategy.",
    title_1: "Our Tokenomics Strategy",
    desc_1: [
      'The first step in creating a token strategy is to clearly define the purpose of the token within your ecosystem.',
      'Tokenomics refers to the economic model of your token. It defines the supply, demand, and distribution',
      'Its essential to decide how tokens will be distributed to users.',
      'In some projects, a portion of the tokens is regularly burned',
      'Ensuring that your tokens can be easily bought, sold, and exchanged is critical for market adoption.'
    ],
    detail_overview:"At Mozeff, we specialize in creating robust and sustainable tokenomics strategies that drive the growth and success of blockchain projects. Tokenomics, the economic model behind a cryptocurrency or token, plays a crucial role in determining the value, utility, and long-term viability of a blockchain-based ecosystem. Our team designs tokenomics strategies tailored to meet your project’s unique goals, ensuring alignment between the token’s supply, demand, distribution, and incentives. We work closely with you to craft detailed models for token distribution, including allocation for founders, teams, investors, community, and staking rewards, among others. Our strategy takes into account factors such as token inflation, deflation, burn mechanisms, and the integration of governance features to ensure a sustainable and equitable model for all stakeholders. Our tokenomics solutions also emphasize security, compliance, and regulatory considerations, ensuring that your tokenomics model complies with relevant laws and best practices in the blockchain industry. We provide expertise in setting up decentralized governance models, creating utility tokens that serve specific functions within your ecosystem, and defining mechanisms to incentivize community participation, staking, and rewards. By integrating strong tokenomics into your project’s development, we help you build a thriving, decentralized economy with strong incentives for growth, long-term sustainability, and a dedicated user base. Our team is here to guide you through the complexities of token design and provide the strategic insights necessary to maximize your token’s value and impact in the blockchain space."
  }
]