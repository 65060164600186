import React from 'react'
import { Typography, Button, Row, Col, Space, Image } from 'antd'
import { useNavigate } from 'react-router-dom'

const { Title, Text } = Typography
const BlogJoinUs = () => {
  const navigate = useNavigate()
  return (
      <div>
        <Row gutter={[24,24]} justify={'center'}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Space direction='vertical' className='w-100 h-100 center'  align='center'>
              <Space direction='vertical' size={20} >
                <Text className='text-white gradient-txt'>Web 3.0</Text>
                <Title level={3} className='m-0 text-white'>Join the Mazeff Web 3.0</Title>
                <Text className='text-gray'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.</Text>
                <Space size={20} >
                  <Button className='btn' onClick={()=> navigate('/login')}>Join Now</Button>
                  <Button className='btn'>Learn More</Button>
                </Space>
              </Space>
            </Space>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Image src='/assets/images/history.jpg' preview={false} className='rounded img-join' />
          </Col>
        </Row>

      </div>
  )
}

export { BlogJoinUs }