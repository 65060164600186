import React from 'react'
import { Button, Card, Col,  Flex, Image, Row, Typography } from 'antd'
import { motion } from "framer-motion";
import { Typewriter } from '../../PageComponents';
import { Link } from 'react-router-dom';
import { UnderlineDesign } from '../../UnderlineDesign';
const { Text, Title } = Typography

const OurHistory = () => {

  return (
    <div className='padding'>
        <Row gutter={[24,24]} justify={'space-between'} align={'middle'}>
            <Col lg={{span: 10}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                <motion.div
                    initial={{ width: 0, opacity: 0 }}
                    whileInView={{
                        opacity: 1,
                        width: '100%',
                    }}
                    transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                    viewport={{once: true}}
                >
                    <Image src='/assets/images/history.jpg' preview={false} className='rounded' />    
                </motion.div>                       
            </Col>
            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} >
                <Flex vertical gap={30}>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                        style={{width:'fit-content'}}
                    >
                        <Title level={1} className='m-0 mb-1 gradient-text'>
                            Our History
                        </Title>
                        <UnderlineDesign />
                    </motion.div>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Text className='text-gray fs-16 text-justify'
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, possimus. Consequatur sequi officiis vel expedita aut. Ullam quam tempora nihil impedit adipisci. Animi fuga cumque eveniet explicabo asperiores omnis tempore!
                        </Text>
                    </motion.div>
                    <motion.Flex
                        initial={{ y: -50, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .6, duration: .3, }}
                        viewport={{once: true}}
                    >
                        <Link to={'tel:0332020302'} className='btn w-auto'>
                            <div className='content vertical '>
                                <div className="text">Join us now</div>
                                <div className="hover-text">Join us now</div>
                            </div>
                        </Link>
                    </motion.Flex>
                </Flex>
            </Col>
        </Row>
    </div>
  )
}

export {OurHistory}