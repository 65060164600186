import React from "react";
import { motion } from "framer-motion";

const PartnershipSvg = () => {
  return (
    <>
      <motion.svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 256 256"
        // enable-background="new 0 0 256 256"
        // xml:space="preserve"
        animate={{
          rotate: [0, 10, -10, 5, -5, 0],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
        }}
      >
        <motion.path
          fill="transparent"
          stroke="none"
          strokeWidth="0"
          d="
          M1.000000,116.000000
          C1.000000,77.701195 1.000000,39.402390 1.000000,1.051793
          C86.221916,1.051793 171.443878,1.051793 257.000000,1.051793
          C257.000000,39.020241 257.000000,77.041466 256.677002,115.825027
          C253.565842,109.820328 251.014526,102.937859 247.814896,96.371269
          C247.059982,94.821968 243.906494,93.273865 242.240219,93.612701
          C239.804901,94.107933 238.208588,96.122810 239.751373,99.502930
          C242.670990,105.899551 244.959778,112.581261 247.643158,119.089905
          C248.728851,121.723305 247.619598,123.082382 245.359787,123.945580
          C240.133957,125.941719 234.885361,127.878204 229.204529,130.004074
          C220.859360,108.896576 212.468063,87.672371 203.870590,65.926697
          C210.605881,63.298141 217.102539,60.762726 223.476700,58.275116
          C226.539856,65.941528 230.044830,73.376434 232.405029,81.158524
          C233.673828,85.342033 236.318726,85.868004 238.864288,84.672653
          C240.182037,84.053864 241.033615,80.042625 240.408539,78.109680
          C237.954544,70.521118 235.008087,63.073963 231.895844,55.721893
          C229.516830,50.101913 226.020584,48.071136 218.632736,50.796707
          C213.078705,52.845741 207.337036,54.535141 202.081223,57.183167
          C199.285812,58.591572 196.091446,61.358414 195.344406,64.124420
          C194.459610,67.400482 192.713943,67.988647 190.291504,69.069260
          C181.527023,72.978935 172.722198,72.529083 163.635025,70.399345
          C162.141525,70.049316 159.515045,72.043129 158.418274,73.643280
          C157.939575,74.341698 159.751587,77.986023 160.944443,78.229546
          C168.335037,79.738281 175.679993,82.588081 183.446136,80.150085
          C188.428818,78.585899 193.466171,77.195885 198.384232,75.755554
          C205.447357,93.688438 212.288696,111.058212 219.058044,128.245193
          C214.867279,131.097412 211.038040,133.756058 207.149979,136.325684
          C205.434601,137.459381 204.068558,138.525070 201.710831,136.470963
          C197.343155,132.665726 192.505707,129.344193 187.617661,126.204552
          C179.931488,121.267639 173.913864,113.485115 162.815979,114.786392
          C150.666565,116.210953 140.413391,110.881371 131.834671,102.331306
          C127.358910,97.870499 121.843353,97.726707 116.928047,101.480751
          C112.706314,104.705078 108.447311,107.992020 103.810295,110.530724
          C98.889000,113.225067 93.415916,114.292030 88.014877,111.486816
          C84.129242,109.468681 84.169121,107.768623 87.412064,105.110191
          C97.381897,96.937279 107.250366,88.640251 117.124130,80.350845
          C121.244492,76.891647 125.065811,72.883110 131.240417,73.992844
          C134.588593,74.594597 137.935135,75.415833 141.310562,75.588989
          C144.006943,75.727318 147.896088,78.206726 148.950684,73.228325
          C149.842346,69.019127 148.120514,67.523399 142.504883,66.984627
          C141.225266,66.861855 139.967896,66.521019 138.694824,66.314110
          C130.644241,65.005737 122.257042,63.302479 115.811584,69.968468
          C112.353409,73.544960 109.478912,73.998062 104.665504,73.250107
          C94.973541,71.744034 85.048470,71.795296 75.241470,70.950005
          C69.688431,70.471382 64.117233,70.101227 62.147015,62.915508
          C61.505920,60.577312 58.520729,58.311852 56.068890,57.156132
          C50.588589,54.572884 44.755150,52.745213 39.092125,50.541489
          C33.007256,48.173603 29.002140,49.672371 26.160156,55.536423
          C25.675621,56.536198 25.395399,57.633286 24.990822,58.673767
          C17.621109,77.626900 10.254664,96.581329 2.812422,115.505943
          C2.675415,115.854340 1.624497,115.843323 1.000000,116.000000
        "
        />
        <motion.path
          fill="none"
          stroke="none"
          strokeWidth="2"
          d="M257.000000,128.000000 C257.000000,170.963379 257.000000,213.926758 257.000000,256.945068 C171.775986,256.945068 86.551933,256.945068 1.000000,256.945068 C1.000000,214.313004 1.000000,171.625153 1.300449,128.179291 C8.578958,130.614029 15.441560,134.106659 22.589291,136.858765 C25.644985,138.035309 29.313595,137.545166 32.667725,138.050934 C34.532444,138.332108 36.527813,138.765152 38.108635,139.719116 C42.775307,142.535278 47.264771,145.645111 51.835812,148.648071 C46.784885,162.742905 55.349735,169.921875 65.855476,170.456696 C66.363258,177.267227 70.688919,182.367874 80.716057,183.370941 C81.623764,190.846497 86.656837,194.168015 93.268394,196.047607 C94.314377,196.344971 95.561348,197.280640 95.971962,198.239532 C97.966934,202.898422 100.551811,207.130280 105.918709,207.908401 C113.192795,208.963013 119.632622,207.307648 123.471817,200.173218 C137.950485,210.980057 148.778702,208.827087 153.575027,194.000534 C154.166290,194.000534 154.828156,193.944305 155.477966,194.008987 C164.622147,194.919388 171.027878,191.785995 173.430496,182.260880 C173.670319,181.310150 175.381271,180.202759 176.504623,180.086349 C185.291092,179.175629 190.414001,174.536194 191.444885,165.743393 C197.363800,166.805313 202.559250,165.888794 206.882034,160.828049 C211.188217,155.786728 209.734100,150.278030 209.749878,144.627594 C210.841431,144.100815 212.096893,143.689072 213.131226,142.962509 C217.889648,139.620041 222.261963,135.056274 228.927338,138.914749 C229.469925,139.228836 230.704178,138.403671 231.594971,138.052048 C240.066040,134.708252 248.532333,131.352386 257.000000,128.000000"
        />
        <motion.path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M257.000000,127.539368
	C248.532333,131.352386 240.066040,134.708252 231.594971,138.052048
	C230.704178,138.403671 229.469925,139.228836 228.927338,138.914749
	C222.261963,135.056274 217.889648,139.620041 213.131226,142.962509
	C212.096893,143.689072 210.841431,144.100815 209.749878,144.627594
	C209.734100,150.278030 211.188217,155.786728 206.882034,160.828049
	C202.559250,165.888794 197.363800,166.805313 191.444885,165.743393
	C190.414001,174.536194 185.291092,179.175629 176.504623,180.086349
	C175.381271,180.202759 173.670319,181.310150 173.430496,182.260880
	C171.027878,191.785995 164.622147,194.919388 155.477966,194.008987
	C154.828156,193.944305 154.166290,194.000534 153.575027,194.000534
	C148.778702,208.827087 137.950485,210.980057 123.471817,200.173218
	C119.632622,207.307648 113.192795,208.963013 105.918709,207.908401
	C100.551811,207.130280 97.966934,202.898422 95.971962,198.239532
	C95.561348,197.280640 94.314377,196.344971 93.268394,196.047607
	C86.656837,194.168015 81.623764,190.846497 80.716057,183.370941
	C70.688919,182.367874 66.363258,177.267227 65.855476,170.456696
	C55.349735,169.921875 46.784885,162.742905 51.835812,148.648071
	C47.264771,145.645111 42.775307,142.535278 38.108635,139.719116
	C36.527813,138.765152 34.532444,138.332108 32.667725,138.050934
	C29.313595,137.545166 25.644985,138.035309 22.589291,136.858765
	C15.441560,134.106659 8.578958,130.614029 1.300449,127.710632
	C1.000000,124.307091 1.000000,120.614182 1.000000,116.460632
	C1.624497,115.843323 2.675415,115.854340 2.812422,115.505943
	C10.254664,96.581329 17.621109,77.626900 24.990822,58.673767
	C25.395399,57.633286 25.675621,56.536198 26.160156,55.536423
	C29.002140,49.672371 33.007256,48.173603 39.092125,50.541489
	C44.755150,52.745213 50.588589,54.572884 56.068890,57.156132
	C58.520729,58.311852 61.505920,60.577312 62.147015,62.915508
	C64.117233,70.101227 69.688431,70.471382 75.241470,70.950005
	C85.048470,71.795296 94.973541,71.744034 104.665504,73.250107
	C109.478912,73.998062 112.353409,73.544960 115.811584,69.968468
	C122.257042,63.302479 130.644241,65.005737 138.694824,66.314110
	C139.967896,66.521019 141.225266,66.861855 142.504883,66.984627
	C148.120514,67.523399 149.842346,69.019127 148.950684,73.228325
	C147.896088,78.206726 144.006943,75.727318 141.310562,75.588989
	C137.935135,75.415833 134.588593,74.594597 131.240417,73.992844
	C125.065811,72.883110 121.244492,76.891647 117.124130,80.350845
	C107.250366,88.640251 97.381897,96.937279 87.412064,105.110191
	C84.169121,107.768623 84.129242,109.468681 88.014877,111.486816
	C93.415916,114.292030 98.889000,113.225067 103.810295,110.530724
	C108.447311,107.992020 112.706314,104.705078 116.928047,101.480751
	C121.843353,97.726707 127.358910,97.870499 131.834671,102.331306
	C140.413391,110.881371 150.666565,116.210953 162.815979,114.786392
	C173.913864,113.485115 179.931488,121.267639 187.617661,126.204552
	C192.505707,129.344193 197.343155,132.665726 201.710831,136.470963
	C204.068558,138.525070 205.434601,137.459381 207.149979,136.325684
	C211.038040,133.756058 214.867279,131.097412 219.058044,128.245193
	C212.288696,111.058212 205.447357,93.688438 198.384232,75.755554
	C193.466171,77.195885 188.428818,78.585899 183.446136,80.150085
	C175.679993,82.588081 168.335037,79.738281 160.944443,78.229546
	C159.751587,77.986023 157.939575,74.341698 158.418274,73.643280
	C159.515045,72.043129 162.141525,70.049316 163.635025,70.399345
	C172.722198,72.529083 181.527023,72.978935 190.291504,69.069260
	C192.713943,67.988647 194.459610,67.400482 195.344406,64.124420
	C196.091446,61.358414 199.285812,58.591572 202.081223,57.183167
	C207.337036,54.535141 213.078705,52.845741 218.632736,50.796707
	C226.020584,48.071136 229.516830,50.101913 231.895844,55.721893
	C235.008087,63.073963 237.954544,70.521118 240.408539,78.109680
	C241.033615,80.042625 240.182037,84.053864 238.864288,84.672653
	C236.318726,85.868004 233.673828,85.342033 232.405029,81.158524
	C230.044830,73.376434 226.539856,65.941528 223.476700,58.275116
	C217.102539,60.762726 210.605881,63.298141 203.870590,65.926697
	C212.468063,87.672371 220.859360,108.896576 229.204529,130.004074
	C234.885361,127.878204 240.133957,125.941719 245.359787,123.945580
	C247.619598,123.082382 248.728851,121.723305 247.643158,119.089905
	C244.959778,112.581261 242.670990,105.899551 239.751373,99.502930
	C238.208588,96.122810 239.804901,94.107933 242.240219,93.612701
	C243.906494,93.273865 247.059982,94.821968 247.814896,96.371269
	C251.014526,102.937859 253.565842,109.820328 256.677002,116.293686
	C257.000000,119.692909 257.000000,123.385818 257.000000,127.539368
M130.477493,194.041046
	C131.905304,195.108231 133.222000,196.389923 134.783569,197.198898
	C138.263596,199.001755 141.652740,198.431870 143.882690,195.251495
	C146.042679,192.170929 145.442093,189.119034 142.250488,186.707809
	C140.041275,185.038788 137.502228,183.464706 136.066162,181.233246
	C135.097595,179.728210 135.794052,177.151672 135.745697,175.054443
	C137.807999,175.166946 140.307159,174.573410 141.848877,175.527298
	C146.094971,178.154434 149.868469,181.531235 153.964569,184.419037
	C157.828049,187.142868 161.725891,186.424088 164.022705,182.836960
	C166.184433,179.460815 165.179932,175.723236 161.514221,173.073746
	C158.759964,171.083038 156.082367,168.985443 153.388916,166.911713
	C151.026520,165.092880 149.619415,162.253616 151.726624,160.225967
	C152.941559,159.056885 156.830704,159.286011 158.621826,160.367279
	C163.170898,163.113464 167.206192,166.707367 171.466476,169.936417
	C174.651215,172.350281 179.107391,172.098953 181.258423,169.184570
	C183.690781,165.889053 184.022858,162.652817 180.339401,159.805130
	C176.735794,157.019180 173.294891,154.020340 169.655991,151.283264
	C167.138474,149.389664 165.538544,146.925278 168.174515,144.699112
	C169.540070,143.545853 173.199005,143.525146 174.829941,144.558411
	C180.277328,148.009613 185.117172,152.408279 190.497711,155.980072
	C194.931335,158.923279 198.629547,158.076736 200.893372,154.401993
	C202.777695,151.343262 201.439789,147.023102 197.796951,144.340988
	C190.242371,138.778793 182.744217,133.137909 175.112289,127.684097
	C172.866196,126.079041 170.148849,123.697151 167.817368,123.876740
	C150.304504,125.225662 135.786560,119.192017 123.810738,107.362808
	C120.147789,110.110619 116.849785,113.108261 113.084930,115.306290
	C103.530190,120.884621 93.774994,124.779968 82.769142,118.395332
	C73.939377,113.273064 73.428482,104.560524 81.702965,98.643379
	C89.039589,93.396896 96.038841,87.678612 103.843376,81.662659
	C88.470688,78.006325 73.361069,83.014473 59.783878,75.381378
	C52.510468,93.800682 45.469463,111.631439 38.350689,129.659149
	C44.594841,133.589417 51.082233,137.672791 57.616573,141.785706
	C58.621937,140.789520 59.988937,139.909714 60.692825,138.657242
	C64.178955,132.454086 69.601830,130.007202 76.323120,131.105225
	C82.026169,132.036926 85.785797,135.608398 87.174896,141.493347
	C87.404823,142.467453 88.794411,143.886703 89.644897,143.884476
	C98.394753,143.861404 101.524979,149.677292 103.370262,156.912659
	C109.982666,156.782761 114.960030,159.172165 117.114471,165.650818
	C118.102341,168.621475 120.101341,169.539902 122.974335,170.081161
	C127.607597,170.954071 130.468185,173.935852 132.189087,178.452469
	C134.396637,184.246353 132.313995,189.080109 130.477493,194.041046
M53.020859,67.896393
	C52.299091,66.713715 51.844475,64.883041 50.810814,64.456894
	C45.397354,62.225098 39.837132,60.349289 34.219772,58.310501
	C34.135452,58.859661 34.148567,59.368896 33.977074,59.805637
	C26.319586,79.307076 18.672327,98.812660 10.934578,118.282265
	C10.129708,120.307465 8.765243,122.136749 11.797722,123.349167
	C17.373339,125.578346 22.960184,127.779442 28.814075,130.099960
	C36.951187,109.434570 44.974556,89.058044 53.020859,67.896393
M116.497032,195.001694
	C118.860954,192.312439 121.313179,189.694595 123.562607,186.912750
	C125.587997,184.407974 125.378716,181.667831 123.014870,179.618149
	C120.384895,177.337723 117.206902,177.214951 114.772209,179.857010
	C111.382141,183.535797 108.226036,187.455994 105.242622,191.475388
	C102.935738,194.583344 104.087547,197.239899 107.607956,199.226685
	C112.274338,201.860229 113.426376,197.253281 116.497032,195.001694
M97.396553,186.103378
	C100.854950,182.311310 104.343697,178.546326 107.761620,174.718094
	C110.240059,171.942108 110.102249,167.932343 107.221497,166.085602
	C104.003693,164.022812 101.253723,164.866241 98.953659,168.023804
	C96.750916,171.047791 94.254486,173.865784 91.785500,176.685318
	C89.926651,178.808090 88.131020,180.621155 89.907761,183.900757
	C91.597908,187.020523 93.835098,187.521545 97.396553,186.103378
M78.138512,141.698349
	C76.146454,139.261963 73.487495,138.198410 71.145790,140.492905
	C67.168709,144.389786 63.526852,148.664062 60.050415,153.023972
	C57.824009,155.816193 58.813938,159.015717 61.488144,160.644669
	C64.142548,162.261551 67.312042,161.711456 69.492035,158.968460
	C71.710609,156.176910 73.541321,153.002609 76.088203,150.567276
	C78.669960,148.098602 79.672028,145.582336 78.138512,141.698349
M80.976067,158.539490
	C79.005867,160.933380 76.900269,163.230530 75.102127,165.747360
	C73.158394,168.467957 73.466331,171.533325 76.532806,172.735428
	C78.621178,173.554123 82.228935,173.458099 83.759071,172.143539
	C87.302124,169.099701 90.456558,165.338272 92.823151,161.312073
	C93.868011,159.534500 93.425613,155.872742 92.206154,154.024612
	C90.608849,151.603851 87.686073,150.885330 85.327866,154.112518
	C84.260109,155.573730 82.769463,156.725937 80.976067,158.539490
z"
        />
        <motion.path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M130.104889,194.003967
	C132.313995,189.080109 134.396637,184.246353 132.189087,178.452469
	C130.468185,173.935852 127.607597,170.954071 122.974335,170.081161
	C120.101341,169.539902 118.102341,168.621475 117.114471,165.650818
	C114.960030,159.172165 109.982666,156.782761 103.370262,156.912659
	C101.524979,149.677292 98.394753,143.861404 89.644897,143.884476
	C88.794411,143.886703 87.404823,142.467453 87.174896,141.493347
	C85.785797,135.608398 82.026169,132.036926 76.323120,131.105225
	C69.601830,130.007202 64.178955,132.454086 60.692825,138.657242
	C59.988937,139.909714 58.621937,140.789520 57.616573,141.785706
	C51.082233,137.672791 44.594841,133.589417 38.350689,129.659149
	C45.469463,111.631439 52.510468,93.800682 59.783878,75.381378
	C73.361069,83.014473 88.470688,78.006325 103.843376,81.662659
	C96.038841,87.678612 89.039589,93.396896 81.702965,98.643379
	C73.428482,104.560524 73.939377,113.273064 82.769142,118.395332
	C93.774994,124.779968 103.530190,120.884621 113.084930,115.306290
	C116.849785,113.108261 120.147789,110.110619 123.810738,107.362808
	C135.786560,119.192017 150.304504,125.225662 167.817368,123.876740
	C170.148849,123.697151 172.866196,126.079041 175.112289,127.684097
	C182.744217,133.137909 190.242371,138.778793 197.796951,144.340988
	C201.439789,147.023102 202.777695,151.343262 200.893372,154.401993
	C198.629547,158.076736 194.931335,158.923279 190.497711,155.980072
	C185.117172,152.408279 180.277328,148.009613 174.829941,144.558411
	C173.199005,143.525146 169.540070,143.545853 168.174515,144.699112
	C165.538544,146.925278 167.138474,149.389664 169.655991,151.283264
	C173.294891,154.020340 176.735794,157.019180 180.339401,159.805130
	C184.022858,162.652817 183.690781,165.889053 181.258423,169.184570
	C179.107391,172.098953 174.651215,172.350281 171.466476,169.936417
	C167.206192,166.707367 163.170898,163.113464 158.621826,160.367279
	C156.830704,159.286011 152.941559,159.056885 151.726624,160.225967
	C149.619415,162.253616 151.026520,165.092880 153.388916,166.911713
	C156.082367,168.985443 158.759964,171.083038 161.514221,173.073746
	C165.179932,175.723236 166.184433,179.460815 164.022705,182.836960
	C161.725891,186.424088 157.828049,187.142868 153.964569,184.419037
	C149.868469,181.531235 146.094971,178.154434 141.848877,175.527298
	C140.307159,174.573410 137.807999,175.166946 135.745697,175.054443
	C135.794052,177.151672 135.097595,179.728210 136.066162,181.233246
	C137.502228,183.464706 140.041275,185.038788 142.250488,186.707809
	C145.442093,189.119034 146.042679,192.170929 143.882690,195.251495
	C141.652740,198.431870 138.263596,199.001755 134.783569,197.198898
	C133.222000,196.389923 131.905304,195.108231 130.104889,194.003967
z"
        />
        <motion.path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M53.009392,68.288956
	C44.974556,89.058044 36.951187,109.434570 28.814075,130.099960
	C22.960184,127.779442 17.373339,125.578346 11.797722,123.349167
	C8.765243,122.136749 10.129708,120.307465 10.934578,118.282265
	C18.672327,98.812660 26.319586,79.307076 33.977074,59.805637
	C34.148567,59.368896 34.135452,58.859661 34.219772,58.310501
	C39.837132,60.349289 45.397354,62.225098 50.810814,64.456894
	C51.844475,64.883041 52.299091,66.713715 53.009392,68.288956
z"
        />
        <motion.path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M116.241165,195.261749
	C113.426376,197.253281 112.274338,201.860229 107.607956,199.226685
	C104.087547,197.239899 102.935738,194.583344 105.242622,191.475388
	C108.226036,187.455994 111.382141,183.535797 114.772209,179.857010
	C117.206902,177.214951 120.384895,177.337723 123.014870,179.618149
	C125.378716,181.667831 125.587997,184.407974 123.562607,186.912750
	C121.313179,189.694595 118.860954,192.312439 116.241165,195.261749
z"
        />
        <motion.path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M97.126953,186.389435
	C93.835098,187.521545 91.597908,187.020523 89.907761,183.900757
	C88.131020,180.621155 89.926651,178.808090 91.785500,176.685318
	C94.254486,173.865784 96.750916,171.047791 98.953659,168.023804
	C101.253723,164.866241 104.003693,164.022812 107.221497,166.085602
	C110.102249,167.932343 110.240059,171.942108 107.761620,174.718094
	C104.343697,178.546326 100.854950,182.311310 97.126953,186.389435
z"
        />
        <motion.path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M78.443787,141.958130
	C79.672028,145.582336 78.669960,148.098602 76.088203,150.567276
	C73.541321,153.002609 71.710609,156.176910 69.492035,158.968460
	C67.312042,161.711456 64.142548,162.261551 61.488144,160.644669
	C58.813938,159.015717 57.824009,155.816193 60.050415,153.023972
	C63.526852,148.664062 67.168709,144.389786 71.145790,140.492905
	C73.487495,138.198410 76.146454,139.261963 78.443787,141.958130
z"
        />
        <motion.path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M81.223366,158.278915
	C82.769463,156.725937 84.260109,155.573730 85.327866,154.112518
	C87.686073,150.885330 90.608849,151.603851 92.206154,154.024612
	C93.425613,155.872742 93.868011,159.534500 92.823151,161.312073
	C90.456558,165.338272 87.302124,169.099701 83.759071,172.143539
	C82.228935,173.458099 78.621178,173.554123 76.532806,172.735428
	C73.466331,171.533325 73.158394,168.467957 75.102127,165.747360
	C76.900269,163.230530 79.005867,160.933380 81.223366,158.278915
z"
        />
      </motion.svg>
    </>
  );
};

export { PartnershipSvg };
