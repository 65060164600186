import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'

const { Text, Title } = Typography
const MarketIntro = ()=> {
  const data = [
    {
      icon:"/assets/icons/blockchain.png",
      txt: "Smarter Digital Experiences"
    },
    {
      icon:"/assets/icons/contract.png",
      txt: "Automate with Smart Contracts"
    },
    {
      icon:"/assets/icons/token.png",
      txt: "DeFi for the Future"
    },
  ]

  return (
    <Row gutter={[24,64]} className='padding'>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14 }}>
            <Space size={10} direction='vertical'>
                <Title className='gradient-text'>Empower Your Crypto Journey with Blockchain Innovation</Title>
                <Text className='text-gray text-justify'>
                Unlock fast, secure, and transparent crypto transactions with our user-friendly blockchain platform, designed for everyone. Our platform offers seamless transactions with minimal delays, ensuring a smooth and efficient experience for both newcomers and experienced users. With top-level security features, including encryption and two-factor authentication, your assets are protected at all times. Embrace the future of finance with a decentralized system that gives you full control over your digital assets, allowing you to trade, invest, and transfer cryptocurrency with confidence.
                </Text>
                <Space size={20} className='mt-3' classNames='wrap-mbl'>
                {data.map((item)=> (
                    <Space size={12} align='start' className='mb-1' wrap>
                        <Image src={item.icon} alt={item.txt} width={40} height={40} preview={false} />
                        <Title level={5} className='gradient-text m-0'>{item.txt}</Title>
                    </Space>
                    ))}
                </Space>
            </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
            <Flex justify='center'>
                <Image src='/assets/images/marketing-2.jpg' height={350}  className='rounded' preview={false} />
            </Flex>
        </Col>
    </Row>
  )
}

export {MarketIntro}