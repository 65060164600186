import React from "react";
import { motion } from "framer-motion";

const EnhanceConnectSvg = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path d="M1847 2872 c-19 -20 -23 -67 -7 -77 6 -3 10 -33 10 -66 l0 -59 40 0 40 0 0 59 c0 33 5 63 10 66 17 10 11 61 -8 78 -24 22 -65 21 -85 -1z m63 -22 c12 -7 12 -14 2 -43 -7 -18 -12 -50 -12 -70 0 -20 -4 -37 -10 -37 -5 0 -10 17 -10 37 0 20 -5 52 -12 70 -10 29 -10 36 2 43 8 6 17 10 20 10 3 0 12 -4 20 -10z" />
        <path d="M1211 2614 c-12 -15 -21 -32 -21 -38 0 -23 32 -56 53 -56 12 0 41 -20 67 -45 l46 -45 27 28 28 29 -48 50 c-27 27 -56 61 -66 76 -23 35 -59 35 -86 1z m83 -41 c8 -16 26 -39 40 -52 14 -14 26 -31 26 -39 0 -9 -18 2 -45 27 -24 22 -54 41 -65 41 -15 0 -20 7 -20 25 0 37 44 35 64 -2z" />
        <path d="M2462 2624 c-13 -9 -22 -26 -22 -41 0 -17 -13 -38 -37 -60 l-36 -35 29 -28 29 -29 68 64 c72 68 80 92 47 125 -24 24 -48 25 -78 4z m58 -45 c0 -22 -89 -111 -103 -102 -4 2 5 20 20 39 14 20 28 45 29 57 1 12 3 23 3 25 1 1 12 2 26 2 19 0 25 -5 25 -21z" />
        <motion.path
          initial={{ opacity: 1 }}
          animate={{ rotate: 360 }}
          transition={{ duration: 5, repeat: Infinity, ease: "linear" }}
          d="M1800 2570 c-11 -11 -20 -33 -20 -51 0 -23 -10 -41 -37 -69 -61 -60 -117 -61 -205 -3 l-28 18 -65 -65 c-36 -35 -65 -68 -65 -73 0 -5 11 -23 25 -41 32 -42 40 -85 25 -130 -15 -45 -72 -96 -108 -96 -55 0 -63 -16 -60 -115 l3 -89 38 -8 c66 -14 97 -36 119 -85 24 -53 19 -90 -17 -145 -14 -21 -25 -40 -25 -44 0 -15 116 -124 132 -124 11 0 33 9 49 21 17 12 47 23 68 26 73 10 151 -51 151 -118 0 -52 17 -60 115 -57 l90 3 12 48 c16 65 34 90 79 112 45 21 101 16 143 -14 16 -12 38 -21 49 -21 16 0 132 108 132 124 0 3 -13 27 -30 53 -35 55 -37 89 -10 143 22 42 56 65 117 78 l38 8 3 89 c3 99 -5 115 -60 115 -36 0 -93 51 -108 96 -15 45 -8 84 24 131 30 43 28 48 -49 123 l-56 55 -27 -17 c-60 -38 -93 -49 -130 -42 -53 10 -107 67 -116 121 -8 53 -25 63 -110 63 -53 0 -70 -4 -86 -20z m171 -58 c15 -76 94 -142 168 -142 18 0 55 12 82 27 l49 28 47 -47 47 -47 -27 -53 c-15 -29 -27 -64 -27 -78 1 -69 78 -163 145 -176 l35 -6 0 -67 0 -67 -44 -12 c-56 -15 -110 -68 -126 -126 -13 -47 -10 -67 23 -134 l21 -43 -47 -47 -47 -47 -49 28 c-60 33 -113 35 -163 4 -48 -29 -76 -66 -86 -114 l-8 -38 -74 0 -74 0 -7 31 c-10 53 -47 101 -93 123 -57 28 -99 26 -157 -6 l-48 -28 -46 45 c-25 24 -45 50 -45 57 0 7 9 27 20 45 47 78 11 196 -73 237 -24 12 -51 21 -60 21 -14 0 -17 11 -17 69 l0 69 36 6 c41 8 106 64 124 106 16 40 8 134 -13 158 -26 28 -21 44 29 92 l45 45 50 -27 c54 -31 93 -35 142 -14 40 16 95 77 103 114 12 54 10 53 86 50 l72 -3 7 -33z"
        />
        <motion.path
          initial={{ opacity: 1 }}
          animate={{
            rotateX: 360,
            rotateY: 360,
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
          d="M1825 2379 c-189 -27 -352 -206 -363 -399 -7 -126 32 -233 120 -327 87 -91 184 -134 305 -135 185 0 344 116 409 300 40 114 26 243 -39 354 -58 99 -187 190 -290 204 -23 3 -51 7 -62 8 -11 2 -47 -1 -80 -5z m228 -61 c79 -37 160 -116 199 -195 104 -212 -11 -477 -239 -552 -192 -63 -404 30 -491 217 -23 50 -27 70 -27 162 0 88 4 113 23 154 50 109 136 189 244 228 79 28 213 21 291 -14z"
        />
        <motion.path
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear",
          }}
          d="M1794 2256 c-59 -19 -129 -70 -163 -119 -91 -132 -76 -295 36 -409 64 -65 129 -92 223 -92 93 0 155 25 222 91 67 66 93 129 93 228 0 73 -4 86 -36 145 -75 137 -230 201 -375 156z m40 -38 c-4 -7 -40 -49 -79 -93 -85 -95 -105 -123 -105 -148 0 -26 45 -67 75 -67 l25 0 0 -105 c0 -58 -2 -105 -5 -105 -3 0 -26 19 -51 42 -62 58 -86 108 -92 194 -4 61 -1 80 20 125 14 30 40 69 59 88 48 49 174 105 153 69z m184 -12 c129 -65 188 -200 148 -338 -16 -54 -76 -137 -117 -159 -18 -9 -19 -5 -19 96 0 103 1 105 23 105 40 0 67 27 67 68 0 38 -31 81 -147 205 -38 40 -41 47 -23 47 11 0 42 -11 68 -24z m-106 2 c36 -29 188 -212 188 -227 0 -27 -21 -41 -62 -41 l-38 0 0 -114 c0 -85 -4 -116 -14 -125 -9 -8 -47 -11 -107 -9 l-94 3 -3 123 -3 122 -37 0 c-41 0 -62 14 -62 41 0 24 189 239 210 239 3 0 13 -6 22 -12z"
        />
        <motion.path
          initial={{ scale: 0.5, opacity: 1 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
          d="M1848 2067 c-35 -42 -36 -43 -15 -53 20 -8 22 -17 25 -126 l3 -118 29 0 29 0 3 117 c3 109 5 118 24 128 21 11 21 12 -17 53 -22 23 -41 42 -42 42 -1 -1 -18 -20 -39 -43z m57 -17 c8 -13 -3 -50 -15 -50 -12 0 -23 37 -15 50 3 5 10 10 15 10 5 0 12 -5 15 -10z"
        />
        <path d="M967 1992 c-23 -25 -21 -58 3 -82 21 -21 65 -27 75 -10 3 6 33 10 66 10 l59 0 0 40 0 40 -59 0 c-33 0 -63 5 -66 10 -10 17 -61 11 -78 -8z m126 -29 c57 -6 63 -23 8 -23 -22 0 -51 -4 -65 -10 -32 -12 -56 -3 -56 20 0 22 18 31 45 23 11 -3 41 -8 68 -10z" />
        <path d="M2735 2000 c-3 -5 -32 -10 -65 -10 l-58 0 -4 -40 -4 -40 58 0 c32 0 67 -5 78 -12 50 -32 110 35 76 86 -17 27 -68 37 -81 16z m55 -30 c20 -13 -1 -46 -26 -42 -11 2 -43 6 -71 9 -63 7 -70 23 -10 23 23 0 49 5 57 10 19 12 31 12 50 0z" />
        <path d="M161 1894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1312 1431 c-19 -22 -45 -41 -62 -44 -41 -9 -66 -50 -52 -83 15 -31 56 -48 83 -34 10 6 25 23 33 38 8 15 33 45 55 66 l41 40 -27 28 c-15 15 -29 28 -32 28 -3 0 -21 -18 -39 -39z m13 -63 c-25 -28 -45 -54 -45 -59 0 -5 -11 -9 -25 -9 -20 0 -25 5 -25 25 0 18 5 25 19 25 10 0 38 18 62 39 60 54 69 40 14 -21z" />
        <path d="M2397 1442 l-27 -28 34 -33 c22 -22 36 -45 38 -65 2 -23 11 -35 32 -45 25 -12 31 -11 55 6 46 35 40 58 -36 129 -37 35 -68 64 -69 64 0 0 -12 -13 -27 -28z m123 -119 c0 -18 -6 -23 -25 -23 -19 0 -25 5 -25 23 0 12 -13 39 -30 58 -42 52 -25 55 31 6 30 -26 48 -50 49 -64z" />
        <path d="M1850 1178 c0 -32 -5 -67 -11 -77 -30 -47 25 -106 76 -83 29 13 43 57 26 83 -6 10 -11 45 -11 77 l0 57 -40 0 -40 0 0 -57z m53 -25 c3 -27 7 -59 9 -73 2 -21 -2 -26 -26 -28 -27 -3 -28 -2 -22 30 4 18 10 52 13 76 7 56 20 53 26 -5z" />
        <motion.path
          initial={{}}
          animate={{ x: [0, -100, 100, 0] }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
          d="M970 1088 c-27 -8 -405 -182 -418 -193 -2 -2 42 -111 97 -244 56 -132 101 -245 101 -251 0 -14 -1 -14 85 27 121 57 141 56 410 -21 126 -36 244 -66 261 -66 63 0 189 42 599 200 379 145 424 165 457 199 72 76 69 175 -6 206 -68 29 -130 19 -408 -61 -145 -42 -271 -79 -280 -81 -11 -3 -18 -15 -18 -32 -1 -91 -107 -191 -204 -191 -18 0 -327 80 -469 121 -67 20 -68 21 -65 52 2 24 8 33 24 35 12 2 132 -27 267 -63 166 -45 254 -64 271 -59 38 9 84 59 92 98 8 44 -19 102 -56 121 -14 7 -105 35 -201 60 -129 35 -191 57 -239 86 -36 21 -85 45 -109 54 -48 17 -138 18 -191 3z m212 -47 c25 -11 64 -31 87 -45 22 -15 60 -33 83 -40 45 -16 263 -74 301 -82 46 -8 72 -38 75 -87 4 -56 -23 -87 -77 -87 -30 0 -279 61 -427 105 -69 20 -94 19 -121 -7 -30 -28 -30 -68 1 -97 28 -26 475 -150 544 -151 56 0 108 23 153 66 33 32 46 55 76 133 8 21 36 32 223 87 263 78 351 97 407 89 57 -8 73 -27 73 -81 0 -36 -6 -51 -33 -79 -28 -30 -83 -54 -412 -181 -444 -171 -525 -199 -591 -208 -50 -7 -92 1 -239 43 -38 11 -117 34 -175 51 -133 39 -186 38 -285 -6 l-74 -32 -30 72 c-16 39 -57 136 -90 216 -34 80 -61 148 -61 151 0 9 317 160 380 181 66 22 145 18 212 -11z"
        />
        <path d="M275 956 c-74 -31 -101 -47 -102 -62 -3 -27 309 -765 329 -777 12 -8 39 -1 110 27 150 62 153 13 -29 452 -87 208 -162 385 -166 392 -12 19 -34 14 -142 -32z m276 -356 c82 -195 149 -362 149 -371 0 -17 -179 -94 -191 -81 -4 4 -58 129 -119 277 -177 426 -190 458 -185 464 14 13 162 70 179 68 16 -2 50 -75 167 -357z" />
      </g>
    </motion.svg>
  );
};

export {EnhanceConnectSvg}
