import React, { useRef, useState } from 'react'
import {Row, Col, Collapse, Typography,Space, Flex, Card} from 'antd';
import { PlusOutlined,  } from '@ant-design/icons'
import { motion, useTransform, useScroll } from 'framer-motion';
import './index.css'
const { Title, Text } = Typography;
const { Panel } = Collapse;
    function Faqs() {
        const [currentPanel,setCurrentPanel]=useState(['0'])
        const ref = useRef(null);
        const { scrollYProgress } = useScroll({ container: ref });
        const stickyY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
        const data = [
            {
                heading: 'Template styling and support',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            },
            {
                heading: 'Troubleshooting animation issues',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            },
            {
                heading: 'How to choose the right license for Project?',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            },
            {
                heading: 'Is my personal information secure?',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            },
            {
                heading: 'What does the free plan include?',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            },
            {
                heading: 'How does Mozeff enhance the security of financial assets?',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            },
            {
                heading: 'Can Mozeff help streamline financial operations?',
                desc:'lorem lorem lorem lorem lorem lorem lorem loremlorem lorem. lorem lorem lorem lorem lorem lorem lorem loremlorem lorem.'
            }
        ]

        const messages = [
            ''
        ]
        return (
            <div className='padding'>
                <Card className='faqs' ref={ref}>
                    <Row gutter={[24,32]} justify={'center'}>
                        <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                            <motion.div
                                style={{
                                position: 'sticky',
                                top: 100,
                                transform: stickyY,
                                }}>
                                <Flex vertical gap={20}>
                                    {/* <Flex>
                                        <Text className='text-gray pill-text'>
                                            FAQ's
                                        </Text>
                                    </Flex> */}
                                    <motion.div
                                        initial={{ y: 100, opacity: 0 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                        }}
                                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                                        viewport={{once: true}}
                                    >
                                        <Title level={1} className='m-0 text-white'>
                                            Your <span className="bg-text">Questions</span> Answered
                                        </Title>
                                    </motion.div>
                                    <motion.div
                                        initial={{
                                            opacity: 0,
                                            y: '-10%'
                                        }}
                                        whileInView={{
                                            opacity: 1,
                                            y:0,
                                        }}
                                        transition={{
                                            delay: .4,
                                            duration: .3, type:'spring', stiffness: 300
                                        }}
                                        viewport={{
                                            once:true
                                        }}
                                    >
                                        <Text className='text-gray fs-16'>
                                            Find answers to common questions about Mozeff and our services.
                                        </Text>
                                    </motion.div>
                                </Flex>
                            </motion.div>
                        </Col>
                        <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                            <Space direction='vertical' style={{width:'100%'}} scrollYProgress={scrollYProgress} size={25}>
                                <Collapse
                                    defaultActiveKey={['0']}
                                    onChange={(keys)=>{setCurrentPanel(keys)}}
                                    ghost
                                >
                                    {
                                        data?.map((faq,f)=>
                                            <Panel className={currentPanel.includes(String(f)) ? 'panel-active panel' : 'panel'}  showArrow={false} header={<Title level={4} className='text-white m-0'>{faq?.heading}</Title>} key={f} 
                                                extra={((currentPanel?.findIndex(x=>x==f))>-1) ?
                                                <PlusOutlined className='text-white' 
                                                    style={{transform: "rotate(45deg)", transition: 'transform 0.2s ease-in-out', fontSize: 20}} />
                                                :
                                                <PlusOutlined className='text-white' 
                                                    style={{transform: "rotate(0deg)", transition: 'transform 0.2s ease-in-out', fontSize: 20}}/>}
                                            >
                                                <div>
                                                    <Text className='text-white fs-16'>
                                                        {faq?.desc}
                                                    </Text>
                                                </div>
                                            </Panel>
                                        )
                                    }
                                </Collapse>
                            </Space>
                        </Col>
                    </Row>
                    <div className='parent-shap'>
                        <div className='bg-shape'></div>
                    </div>
                </Card>
            </div>
        )
    }

export {Faqs}