import React from "react";
import { motion } from "framer-motion";

const MachinelearningSvg = () => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 300.000000 300.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
          fill="#fff"
          stroke="none"
        >
          <motion.path
            initial={{
              rotate: -10,
            }}
            animate={{
              rotate: 0,
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            d="M835 2664 c-144 -21 -287 -69 -389 -132 -76 -46 -186 -150 -242 -228
-62 -86 -129 -233 -159 -346 -28 -105 -47 -292 -33 -320 8 -16 30 -18 236 -18
158 0 247 -5 295 -15 174 -37 328 -171 388 -340 17 -49 23 -94 29 -245 6 -163
10 -195 34 -265 35 -102 92 -191 172 -264 132 -121 254 -161 485 -161 121 0
150 3 163 16 13 12 16 38 16 119 0 94 2 104 20 110 10 3 31 20 45 37 27 32 28
47 10 105 -6 18 -2 25 17 32 39 15 51 37 45 81 -5 39 -4 40 25 40 44 0 97 28
113 61 27 52 18 87 -79 297 l-94 203 19 60 c44 141 41 381 -7 545 -89 309
-306 516 -629 600 -86 22 -125 26 -275 29 -96 2 -188 1 -205 -1z m265 -45 c0
-6 -11 -24 -25 -41 -14 -16 -25 -25 -25 -20 0 6 -12 24 -26 41 l-26 31 51 0
c31 0 51 -4 51 -11z m-229 -27 c-16 -22 -21 -48 -23 -113 l-3 -84 -30 -3 -30
-4 28 29 c22 24 27 38 27 82 0 45 -4 57 -29 81 -32 32 -27 37 42 39 l38 1 -20
-28z m139 -367 c0 -340 1 -355 19 -365 27 -14 37 -13 55 6 14 13 16 60 16 364
l0 349 38 -5 c165 -23 296 -60 287 -81 -3 -8 -5 -108 -5 -221 l0 -207 -57 -30
c-31 -16 -60 -31 -65 -33 -4 -2 -8 37 -8 86 0 99 -9 116 -57 110 l-28 -3 -3
-280 -2 -280 -29 -17 c-62 -35 -86 -116 -54 -178 23 -45 76 -80 121 -80 44 0
104 34 125 70 36 60 11 158 -48 190 l-26 14 3 129 3 130 104 57 c56 31 107 64
113 74 6 10 8 101 6 227 -1 115 -1 209 2 209 11 -1 89 -65 143 -118 106 -104
179 -244 213 -412 20 -102 15 -326 -10 -405 l-15 -50 -75 3 c-89 4 -116 -7
-116 -48 0 -41 26 -52 117 -49 l76 2 88 -193 c49 -106 89 -199 89 -206 0 -13
-20 -19 -105 -30 -27 -4 -58 -11 -67 -16 -22 -11 -23 -44 -3 -78 8 -14 14 -28
15 -31 0 -11 -50 -29 -80 -29 -28 0 -70 -29 -70 -48 0 -20 29 -44 60 -51 45
-9 48 -31 8 -56 -50 -31 -60 -59 -54 -147 l4 -78 -97 0 c-128 0 -200 14 -288
56 -130 62 -221 166 -270 306 -22 63 -26 97 -33 258 -6 153 -11 196 -29 250
-29 83 -77 162 -136 224 l-47 48 26 57 25 56 63 5 c35 3 69 9 76 13 19 13 15
65 -7 77 -25 13 -156 13 -180 1 -10 -6 -30 -40 -46 -78 -16 -37 -29 -69 -31
-71 -1 -2 -25 9 -54 23 -103 52 -183 65 -410 65 l-207 0 13 88 c38 239 160
481 297 585 l37 28 0 -94 c0 -71 4 -98 15 -107 8 -7 64 -25 126 -40 61 -16
112 -30 114 -32 1 -1 28 -54 59 -115 48 -97 60 -113 80 -113 25 0 56 23 56 41
0 5 -29 68 -65 139 -73 145 -48 125 -215 168 l-75 19 -3 96 -3 96 50 25 c46
24 180 66 208 66 9 0 13 -18 13 -60 l0 -60 -51 0 c-66 0 -89 -13 -89 -51 0
-38 27 -49 118 -49 105 0 112 8 112 136 l0 104 60 0 60 0 0 -355z m492 299
l52 -26 -31 -13 c-17 -7 -36 -21 -41 -31 -9 -17 -11 -15 -17 11 -3 17 -12 42
-20 58 -18 34 -8 34 57 1z m-986 -25 c-24 -28 -26 -39 -26 -116 0 -69 4 -92
21 -119 15 -26 17 -34 7 -34 -28 0 -38 24 -38 95 0 62 -3 74 -23 94 -16 14
-30 19 -43 15 -37 -14 -104 -77 -155 -145 -28 -38 -53 -69 -55 -69 -15 0 55
90 125 161 103 103 256 200 187 118z m794 -534 c19 12 10 -2 -30 -44 l-40 -42
0 70 0 69 30 -29 c16 -17 34 -28 40 -24z m-1229 -270 c27 -25 24 -35 -10 -35
-45 0 -54 19 -33 76 l17 49 3 -36 c2 -21 12 -45 23 -54z m789 10 c-27 -29 -37
-27 -20 4 6 12 18 21 27 21 13 0 12 -5 -7 -25z m429 -129 c34 -30 24 -41 -11
-11 -36 30 -68 28 -105 -7 -38 -36 -26 -9 20 41 l42 48 16 -26 c9 -14 26 -35
38 -45z m-488 18 c-6 -21 -4 -38 5 -55 23 -42 16 -41 -41 3 l-55 43 31 6 c17
4 38 14 47 23 21 20 23 16 13 -20z m463 -60 c21 -21 20 -47 -3 -68 -41 -37
-97 14 -66 60 18 28 46 31 69 8z m641 -169 c7 -20 7 -20 -6 0 -7 11 -23 28
-36 38 -29 23 -29 34 0 57 l22 18 6 -46 c4 -26 10 -56 14 -67z m31 -477 c-9
-12 -16 -35 -16 -50 0 -17 -13 -40 -35 -63 -32 -34 -34 -41 -28 -82 6 -43 4
-46 -37 -84 -47 -43 -68 -46 -26 -4 15 14 34 37 43 51 15 24 15 26 -17 59
l-33 34 34 14 c54 23 73 50 65 95 -7 46 -2 52 36 52 28 0 29 -1 14 -22z m-156
-476 c0 -23 -19 -42 -42 -42 -24 0 -23 9 4 37 26 28 38 29 38 5z"
          />
          <path
            d="M474 2079 c-18 -5 -46 -25 -63 -44 -26 -30 -31 -44 -31 -89 0 -85 54
-136 144 -136 84 0 148 87 126 170 -11 41 -51 84 -93 99 -33 13 -40 13 -83 0z
m74 -101 c28 -28 7 -78 -33 -78 -27 0 -45 18 -45 45 0 27 18 45 45 45 12 0 26
-5 33 -12z"
          />
          <motion.path
            fill="#fff"
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            d="M2800 2562 c-19 -9 -44 -32 -55 -49 l-20 -33 -393 1 c-290 0 -397 -3
-408 -12 -20 -17 -17 -57 5 -69 13 -6 159 -10 408 -10 l388 0 20 -30 c64 -96
219 -69 245 43 16 73 -14 137 -77 163 -43 18 -71 18 -113 -4z m1 -66 c-27 -29
-30 -79 -6 -106 32 -36 11 -29 -33 10 l-43 38 43 41 c46 43 76 57 39 17z m140
-8 c16 -18 29 -39 29 -46 0 -20 -38 -70 -63 -83 -19 -10 -18 -7 6 18 34 37 36
87 5 120 -12 13 -18 23 -14 23 4 0 20 -15 37 -32z m-51 -18 c24 -24 25 -38 3
-62 -20 -22 -41 -23 -65 -1 -24 22 -23 49 4 67 29 20 34 20 58 -4z"
          />
          <motion.path
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.4,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            fill="#fff"
            d="M2473 2286 c-18 -8 -42 -31 -55 -50 l-23 -36 -156 0 c-163 0 -179 -4
-179 -45 0 -41 16 -45 181 -45 l158 0 17 -29 c49 -83 183 -82 234 2 38 62 17
152 -44 192 -35 23 -95 28 -133 11z m-2 -75 c-27 -38 -27 -79 1 -108 35 -37 7
-26 -39 16 l-42 38 46 42 c54 49 62 51 34 12z m99 -26 c17 -20 5 -62 -20 -70
-47 -15 -82 23 -60 65 13 24 61 27 80 5z"
          />
          <motion.path
            fill="#fff"
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.3,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            d="M2825 2013 c-30 -7 -83 -50 -90 -73 -6 -19 -15 -20 -299 -20 -307 0
-316 -1 -316 -45 0 -44 9 -45 315 -45 l291 0 17 -30 c48 -80 178 -80 233 -1 9
13 16 44 16 75 1 67 -25 107 -82 130 -41 16 -50 17 -85 9z m-23 -76 c-27 -28
-29 -83 -5 -117 13 -18 5 -14 -31 15 l-49 39 48 43 c50 46 72 58 37 20z m169
-74 c-5 -10 -24 -31 -42 -48 l-32 -30 21 32 c28 39 28 82 1 119 -12 17 -4 11
20 -13 33 -34 40 -47 32 -60z m-71 38 c14 -27 13 -37 -6 -55 -40 -40 -101 3
-75 53 15 28 66 29 81 2z"
          />
          <motion.path
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.2,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            fill="#fff"
            d="M2505 1730 c-42 -9 -80 -36 -93 -65 -11 -25 -13 -25 -140 -25 -134 0
-152 -5 -152 -45 0 -40 18 -45 152 -45 l127 0 16 -31 c21 -40 93 -73 138 -64
70 15 117 71 117 140 0 87 -81 153 -165 135z m-25 -69 c-5 -11 -15 -23 -20
-26 -18 -11 -11 -71 13 -101 17 -22 9 -18 -31 15 l-52 44 47 44 c54 49 58 51
43 24z m94 -53 c12 -34 -6 -58 -45 -58 -43 0 -63 45 -33 75 25 24 67 15 78
-17z"
          />
          <path
            d="M290 1478 c-155 -54 -280 -206 -280 -340 0 -52 12 -43 46 38 14 32
23 48 19 34 -20 -86 -25 -131 -20 -175 l6 -50 -26 40 -26 40 4 -55 c8 -122
105 -252 234 -312 120 -56 275 -49 390 19 64 37 148 134 174 200 19 47 23 77
24 163 0 98 -2 110 -32 172 -44 93 -110 160 -199 204 -63 32 -85 37 -161 41
-73 3 -99 0 -153 -19z m279 -110 c197 -100 241 -349 91 -512 -199 -216 -563
-74 -563 219 0 253 248 406 472 293z"
          />
          <path
            d="M352 1279 c-19 -6 -52 -29 -75 -52 -82 -82 -88 -200 -14 -284 82 -93
221 -98 307 -11 42 42 60 85 60 145 -1 146 -135 243 -278 202z m135 -105 c59
-38 67 -127 17 -178 -73 -72 -199 -19 -199 84 0 94 102 147 182 94z"
          />
          <motion.path
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.4,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            fill="#fff"
            d="M2785 1432 c-16 -10 -36 -31 -44 -45 l-14 -27 -277 0 c-290 0 -300
-2 -300 -45 0 -43 10 -45 299 -45 l276 0 17 -29 c24 -43 63 -65 117 -65 51 0
101 27 122 66 16 29 16 98 0 135 -27 67 -130 95 -196 55z m9 -68 c-23 -34 -20
-81 7 -110 39 -41 7 -26 -38 18 l-43 42 42 38 c51 45 53 45 32 12z m156 -14
l32 -40 -27 -33 c-35 -41 -68 -61 -37 -22 26 33 28 76 6 110 -25 39 -9 29 26
-15z m-56 -6 c19 -19 20 -35 4 -56 -22 -26 -47 -29 -68 -8 -11 11 -20 26 -20
34 0 20 28 46 50 46 10 0 26 -7 34 -16z"
          />
          <motion.path
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.3,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            fill="#fff"
            d="M2502 1151 c-18 -12 -38 -32 -44 -46 -11 -24 -16 -25 -89 -25 -81 0
-109 -12 -109 -49 0 -33 22 -41 106 -41 73 0 83 -2 89 -20 9 -29 61 -68 100
-76 100 -18 188 81 156 179 -19 56 -62 90 -123 94 -41 3 -60 -1 -86 -16z m12
-68 c-29 -42 -26 -73 9 -112 33 -36 5 -21 -43 22 l-42 39 48 44 c27 23 50 43
51 44 1 0 -9 -17 -23 -37z m101 -27 c30 -45 -16 -91 -61 -61 -28 18 -31 46 -8
69 22 23 51 20 69 -8z"
          />
          <motion.path
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.5,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            fill="#fff"
            d="M2803 880 c-11 -5 -34 -25 -50 -44 l-28 -36 -268 0 c-166 0 -276 -4
-288 -10 -26 -15 -26 -66 1 -80 14 -7 110 -10 289 -8 l269 3 15 -27 c36 -63
123 -86 184 -48 47 28 65 62 65 120 0 58 -18 92 -65 120 -33 21 -87 25 -124
10z m-8 -80 c-26 -28 -23 -75 6 -106 34 -38 9 -26 -40 18 l-41 38 43 39 c43
40 65 48 32 11z m149 -1 c14 -17 26 -37 26 -45 0 -21 -29 -63 -53 -76 -20 -10
-20 -9 1 17 28 36 28 82 0 112 -12 13 -17 23 -11 23 6 0 23 -14 37 -31z m-50
-18 c22 -25 13 -58 -20 -73 -19 -9 -28 -8 -44 7 -24 22 -25 42 -3 67 21 23 45
23 67 -1z"
          />
          <motion.path
            initial={{
              x: [300, -60],
            }}
            animate={{
              x: 0,
            }}
            transition={{
              delay: 0.4,
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            fill="#fff"
            d="M2462 590 c-18 -11 -40 -33 -49 -48 l-15 -27 -163 3 c-140 3 -165 1
-179 -13 -20 -21 -21 -49 0 -69 13 -13 44 -16 178 -16 l162 0 22 -30 c54 -72
147 -80 211 -16 33 34 36 41 36 95 0 50 -4 64 -25 86 -54 58 -120 70 -178 35z
m9 -66 c-27 -34 -27 -85 -1 -109 11 -10 18 -20 15 -23 -2 -3 -24 13 -48 36
l-44 40 41 41 c46 46 68 55 37 15z m91 -21 c22 -20 23 -41 1 -65 -22 -24 -49
-23 -67 4 -20 29 -20 34 4 58 24 24 38 25 62 3z"
          />
        </g>
      </svg>
    </>
  );
};

export { MachinelearningSvg };
