import React from 'react'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../Breadcrumb'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { servicesData, servicesMainData } from '../../../data'
import { InvestorServices } from './InvestorServices'
import { PricingCards } from '../../PricingCards'

const {Title, Text} = Typography
const SingleViewServices = ()=> {
  const { service_id } = useParams()
  const singleServicedata =  servicesData.find(item => item.key == service_id)
  const serviceCardData =  servicesMainData.find(item => item.id == service_id)

  return (
    <div>
      <Breadcrumb title={singleServicedata?.title} pageName={singleServicedata?.title} breadcrumb={[{to:'/',name:'Home'}]}/>
      <div className='container'>
        <div className='padding'>
          <Row gutter={[24,64]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <Space size={10} direction='vertical'>
                <Title className='gradient-text'>{singleServicedata.title}</Title>
                <Text className='text-gray text-justify'>{singleServicedata.desc}</Text>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <Flex justify='end'>
                <Image src='/assets/images/img_3.jpg' height={350} width={'100%'} className='rounded' preview={false} />
              </Flex>
            </Col>
            <Col span={24}>
              <Space className='w-100 py-3' direction='vertical'>
                <Title className='gradient-text'>Detail Overview</Title>
                <Text className='text-gray text-justify'>{singleServicedata.detail_overview}</Text>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <div className='parent-div'>
                <div className='bg-shap'></div>
                <img src='/assets/images/img_3.jpg' className='upper-img'/>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
              <Space size={10} direction="vertical">
                <Title className='gradient-text'>{singleServicedata.title_1}</Title>
                {singleServicedata.desc_1.map((item)=> (
                  <Space size={12} align='start' className='mb-2'>
                    <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                    <Text className='text-gray fs-13'>{item}</Text>
                  </Space>
                ))}
              </Space>
            </Col>
            
            {/* <Col span={24}>
              <Space align='center w-100' size={20} direction='vertical'>
                <Title className='gradient-text'>Mozeff's Investors</Title>
              </Space>
              <InvestorServices />
            </Col> */}
            <Col span={24}>
                    <PricingCards />   
                </Col>
          </Row>
        </div>
        </div>
    </div>
  )
}

export {SingleViewServices}