import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'

const { Text, Title } = Typography
const MarketingKey = () => {

    const data = [
        'Experience fast and seamless transactions with minimal',
        'Access decentralized finance to borrow, earn interest.',
        'Enjoy low transaction fees with no hidden costs.',
        'Trade 150+ cryptocurrencies with 20+ fiat worldwide.',
        'Protect your assets with bank-grade encryption.'
    ]

  return (
    <Row gutter={[60,60]} className='padding'>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Flex justify='center' align='center'>
                <Image src='/assets/images/marketing-1.jpg' height={350} className='rounded' preview={false} />
            </Flex>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Space size={10} direction='vertical' align='start'>
                <Title className='m-0 gradient-text'>Crypto Journey with Blockchain Innovation</Title>
                <Text className='text-gray text-justify'>
                    Unlock fast, secure, and transparent crypto transactions with our user-friendly blockchain platform, designed for everyone.
                </Text>
                {data.map((item)=> (
                    <Space size={12} align='start' className='mb-1'>
                        <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                        <Title level={5} className='gradient-text m-0'>{item}</Title>
                    </Space>
                    ))}
            </Space>
        </Col>
    </Row>
  )
}

export {MarketingKey}