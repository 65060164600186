import React, { useState } from 'react'
import { Button, Col,  Dropdown,  Flex, Image, Row, Space, Typography } from 'antd'
import { Breadcrumb,  Partners, PricingCards, RoboticSection, SerivcesPieChart, ServicesProvide, SlideCard, Typewriter } from '../../components'
import { motion } from 'framer-motion'
import { ServicesHelp } from '../../components/ServicesComponents/ServicesHelp'
const { Text, Title } = Typography


const Servicespage = () => {

    const [ order, setOrder ] = useState(0)

    const items = [
        { label: <Button className='fs-13 border0 bg-transparent'>Web3</Button>, key: 0 },
        { label: <Button className='fs-13 border0 bg-transparent'>Web2</Button>, key: 1 },
    ];

    const onClick = ({ key }) => {
        setOrder(key);
    }; 

    const messages = [
        'The Evolution of the Web'
    ]
    const datapie = [
        'Decentralized Data Management',
        'Smart Contract Automation',
        'Personalized User Experience',
    ]

  return (
    <div >
        <Breadcrumb title='Services' pageName='Services' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'}>
                <Col span={24}>
                    <Flex className='mt-5 mb-3' justify='end'>
                        <Dropdown
                            menu={{ items, onClick }}
                            trigger={['click',]}
                        >
                            <Button className='btn-filter'>
                                <Text className='text-white'>
                                    {
                                        order == 0 ? 'Web3' : 'Web2'
                                    }
                                </Text>
                                <img src="/assets/icons/filter.png" width={20} alt="" />
                            </Button>
                        </Dropdown>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Flex vertical gap={10} justify='center' align='center' className='text-center mb-3'>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once:true}}
                        >
                            <Text className='text-gray fs-16'>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic est magni autem assumenda sequi, harum quibusdam? Minus, quidem ipsam repellat nam aut magni dolorum. Velit asperiores minus eum architecto tenetur?
                            </Text>
                        </motion.div>
                    </Flex>                   
                </Col>
                <Col span={24}>
                    <SlideCard />
                </Col>
                <Col span={24}>
                    <ServicesHelp />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Space direction="vertical" className='h-100 space-mbl' >
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Title level={2} className='gradient-text m-0'>
                                The Future of the Internet: Embracing Web 3.0 Services
                            </Title>
                        </motion.div>
                        <Text className='text-gray text-justify py-3'>Own, trade, and showcase your digital assets with NFTs, the future of creative expression. NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work.Own, trade, and showcase your digital assets with NFTs, the future of creative expression."</Text>
                        {datapie.map((item)=> (
                            <Space size={12} align='start' className='mb-1'>
                                <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                                <Title level={5} className='gradient-text m-0'>{item}</Title>
                            </Space>
                        ))}
                    </Space>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <SerivcesPieChart />
                </Col>
                <Col span={24}>
                    <ServicesProvide />
                </Col>
                <Col span={24}>
                    <RoboticSection />
                </Col>
                <Col span={24}>
                    <PricingCards />   
                </Col>
                <Col span={24}>
                    <Partners />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {Servicespage}