import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import "./index.css";
import { Typewriter } from "../../PageComponents";
import { Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;
export const ServicesProvide = () => {
  const messages = ["Services We Provide"];
  return (
    <section className="services-provide">
      <div >
        <Flex vertical gap={10} justify='center' align='center' className='text-center mt-3 mb-3'>
          <Title level={1} className='gradient-text'>
              <Typewriter messages={messages} speed={150} />
          </Title>
      </Flex>
      <Link
          heading="Blockchain Solutions"
          subheading="Custom blockchain development and seamless integration to build secure, scalable, and decentralized applications for your business."
          imgSrc="/assets/images/img_1.jpg"
          id='1'
        />
        <Link
          heading="Smart Contract Security"
          subheading="Develop and audit smart contracts to ensure they are secure, efficient, and ready for deployment in decentralized environments."
          imgSrc="/assets/images/img_2.jpg"
          id='2'
        />
        <Link
          heading="DeFi Applications"
          subheading="Consulting and development of decentralized finance (DeFi) platforms, including lending protocols, DEXs, and liquidity solutions."
          imgSrc="/assets/images/img_3.jpg"
          id='3'
        />
        <Link
          heading="NFT Development"
          subheading="End-to-end NFT solutions, from token creation and smart contracts to marketplace integration and community engagement."
          imgSrc="/assets/images/img_4.jpg"
          id='4'
        />
        <Link
          heading="Tokenomics Strategy"
          subheading="Design sustainable tokenomics models and governance structures to drive growth and build decentralized communities."
          imgSrc="/assets/images/img_5.jpg"
          id='5'
        />
      </div>
    </section>
  );
};

const Link = ({ heading, imgSrc, subheading, href ,id,  }) => {
  const ref = useRef(null);
  const navigate = useNavigate()
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const top = useTransform(mouseYSpring, [0.5, -0.5], ["60%", "80%"]);
  const left = useTransform(mouseXSpring, [0.5, -0.5], ["70%", "80%"]);

  const handleMouseMove = (e) => {
    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };
  const handleNavigation = () => {
    navigate(`/services/details/${id}`);
  };

  return (
    <motion.span
      href={href}
      ref={ref}
      onClick={handleNavigation}
      onMouseMove={handleMouseMove}
      initial="initial"
      whileHover="whileHover"
      className="container-s"
    >
      <div>
        <motion.span
          variants={{
            initial: { x: 0 },
            whileHover: { x: -16 },
          }}
          transition={{
            type: "spring",
            staggerChildren: 0.075,
            delayChildren: 0.25,
          }}
          className="inner-contain"
        >
          {heading.split(" ").map((l, i) => (
            <motion.span
              style={{ padding: "0 4px 0 0", display: 'inline-block'  }}
              variants={{
                initial: { x: 0 },
                whileHover: { x: 16 },
              }}
              transition={{ type: "spring" }}
              key={i}
            >
              {l}
            </motion.span>
          ))}
        </motion.span>
        <span className="sub-contain">
          {subheading}
        </span>
      </div>

      <motion.img
        style={{
          top,
          left,
          translateX: "-30%",
          translateY: "-60%",
        }}
        variants={{
          initial: { scale: 0, rotate: "-9deg" },
          whileHover: { scale: 1, rotate: "9deg" },
        }}
        transition={{ type: "spring" }}
        src={imgSrc}
        className="image-container"
        alt={`Image representing a link for ${heading}`}
      />
    </motion.span>
  );
};