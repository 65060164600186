import React, { useRef } from 'react'
import {Row, Col, Typography, Flex, Card, Rate, Space, Image} from 'antd';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { UnderlineDesign } from '../../UnderlineDesign';

const { Title, Text, Paragraph } = Typography;
    function Testimonial() {

        // const targetRef = useRef(null)
        // const { scrollYProgress } = useScroll({
        //     target: targetRef,
        //     offset:["end end", "end start"]
        // })
        // const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
        // const scale = useTransform( scrollYProgress, [0, .5], [1, .8] )

        const testimonialData = [
            {
                img:'av-1.png',
                title:'Zahir Ziyad',
                desc:'I accidentally stumbled upon Mozeff while looking for another tool but I am very glad I did!.'
            },
            {
                img:'av-1.png',
                title:'Arim Shah',
                desc:'Such a clean, beautiful template and so easy to customise. Would highly recommend.'
            },
            {
                img:'av-1.png',
                title:'Aryan Khan',
                desc:'I never realized how much money i was wasting on unnecessary expenses until I started using Brixso.'
            },
            {
                img:'av-1.png',
                title:'Sam Sozan',
                desc:'I accidentally stumbled upon Mozeff while looking for another tool but I am very glad I did!. I accidentally stumbled upon Mozeff while looking for another tool but I am very glad I did!.'
            },
        ]
        
        var testimonialSlider = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 0
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 0
                }
              }
            ]
          }

        return (
            <motion.div className='padding'
                // ref={targetRef}
                // style={{
                //     opacity,
                //     scale,
                // }}
            >
                <Row gutter={[24,24]} justify={'center'}>
                    <Col span={24}>
                        <Flex vertical justify='center' gap={30} align='center'>
                            <motion.div
                                initial={{ y: '-100%', opacity: 0 }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                }}
                                transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                                viewport={{once: true}}
                            >
                                <Flex justify="center" gap={8} align="center" vertical>
                                    <Text className="text-gray">Testimonials</Text>
                                    <UnderlineDesign />
                                </Flex>
                            </motion.div>
                            <Flex vertical align='center' gap={5}>
                                <motion.div
                                    initial={{ opacity: 0}}
                                    whileInView={{
                                        opacity: 1
                                    }}
                                    transition={{ delay: .3, duration: .3, ease: 'easeInOut' }}
                                    viewport={{once: true}}
                                >
                                    <Title level={1} className='text-center m-0 text-white'>
                                        Read what our clients <span className='bg-text'>saying</span>
                                    </Title>
                                </motion.div>
                                <motion.div
                                    initial={{ y: 100, opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once: true}}
                                >
                                    <Text className='text-gray fs-16'>
                                        Don't take our word for it. Listen to our partners.
                                    </Text>
                                </motion.div>
                            </Flex>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Slider {...testimonialSlider}>
                            {
                                testimonialData?.map((data,index)=>{
                                    return(
                                        <div className='px-2 h-250' key={index}>
                                            <Card className='border-gray feedbk' >
                                                <div className='bg-img'>
                                                    <Image src='/assets/images/testimonial-two-shape.png' alt="Mozeff" preview={false}/>
                                                </div>
                                                <Space direction='vertical' size={10} style={{justifyContent:'center', alignItems:'center'}}>
                                                    <img src={'/assets/images/'+data?.img} width={35} height={35} style={{borderRadius: 50}} />
                                                    <Text className='text-white'>
                                                        {
                                                            data?.title
                                                        }
                                                    </Text>                                                    
                                                    <Rate defaultValue={5} />
                                                    <Paragraph
                                                        ellipsis={{
                                                        rows: 2,
                                                        expandable: true,
                                                        }}
                                                        title={data?.desc}
                                                        className='text-white overflow-on m-0'
                                                    >
                                                        {data?.desc}
                                                    </Paragraph>
                                                </Space>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </Col>
                </Row>
            </motion.div>
        )
    }

export {Testimonial}