import React from 'react';
import { motion } from 'framer-motion';

const SmartContractSvg = () => {
    return (
        <>
            <motion.svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="100%" height="100%" viewBox="0 0 300.000000 300.000000"
                preserveAspectRatio="xMidYMid meet">
                <metadata>
                    Created by potrace 1.10, written by Peter Selinger 2001-2011
                </metadata>
                <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <motion.path fill='#fff'
                    initial={{
                        rotate: -5
                    }}
                    animate={{
                        rotate: 0
                    }}
                    transition={{
                        duration: 1,
                        delay: 1,
                        repeat: Infinity,
                        repeatType: 'reverse'
                    }}
                     d="M1450 2967 c-36 -12 -87 -35 -115 -51 -68 -39 -970 -937 -1030 -1026
-54 -78 -82 -154 -96 -258 -6 -48 -9 -298 -7 -662 3 -561 4 -587 24 -646 58
-170 210 -299 367 -311 l62 -5 -25 21 -25 21 85 0 c91 0 117 -8 107 -34 -7
-18 6 -21 34 -6 21 12 26 54 7 67 -7 4 -71 11 -143 14 -120 5 -135 8 -190 36
-74 38 -147 115 -183 191 l-27 57 -3 564 c-2 489 -1 570 13 617 20 68 73 129
140 160 48 23 59 24 300 24 281 0 353 8 442 50 81 37 125 72 178 143 84 111
88 129 94 482 7 349 8 354 89 427 76 68 82 69 507 66 l380 -3 60 -31 c74 -39
148 -116 183 -192 24 -52 27 -70 30 -204 l4 -148 -129 0 -128 0 -98 98 c-90
90 -97 100 -97 137 -1 118 -122 220 -238 199 -89 -16 -164 -92 -178 -179 -17
-116 83 -235 198 -235 42 0 47 -4 168 -126 69 -69 135 -130 148 -135 13 -5 97
-9 188 -9 l164 0 0 -30 0 -30 -118 0 c-121 0 -142 -6 -142 -41 0 -40 26 -49
146 -49 l114 0 0 -40 0 -40 -280 0 -279 0 -32 -40 c-18 -22 -35 -40 -38 -40
-3 0 -19 12 -35 26 l-29 25 54 55 53 54 106 0 c111 0 130 6 130 45 0 12 -5 26
-12 33 -16 16 -221 16 -251 1 -12 -6 -54 -43 -93 -81 -66 -64 -75 -70 -118
-73 -71 -4 -135 -47 -168 -114 -16 -30 -28 -68 -28 -85 0 -83 48 -159 123
-198 59 -29 150 -22 206 16 47 33 91 112 91 166 0 29 8 44 48 83 l48 47 252 0
252 0 0 -30 c0 -30 0 -30 -56 -30 -31 0 -71 -7 -88 -15 -18 -9 -86 -70 -151
-135 -105 -106 -122 -120 -152 -120 -44 0 -101 -24 -136 -58 -92 -87 -89 -225
7 -309 127 -113 330 -27 341 144 3 43 9 51 101 144 54 55 107 99 116 99 17 0
18 -29 18 -517 0 -541 -2 -570 -45 -652 -31 -61 -112 -137 -178 -167 l-62 -29
-730 -5 c-401 -3 -736 -9 -742 -13 -20 -13 -15 -55 6 -67 28 -15 41 -12 34 6
-3 9 0 20 8 25 8 5 322 9 704 9 l690 0 -25 -21 -25 -21 62 5 c157 12 309 141
367 311 21 61 21 69 21 1176 0 1107 0 1115 -21 1176 -58 169 -209 297 -367
312 -57 5 -61 4 -48 -11 11 -14 11 -18 0 -22 -8 -2 -174 -6 -369 -7 -394 -3
-375 0 -467 -75 -37 -29 -43 -32 -43 -15 0 10 -9 27 -20 37 -11 10 -17 22 -13
26 5 4 40 17 80 28 40 12 75 26 78 31 11 18 -89 10 -155 -13z m-18 -129 c-53
-79 -55 -92 -61 -418 l-6 -306 -33 -66 c-40 -83 -119 -158 -200 -189 -54 -22
-75 -24 -362 -29 -330 -6 -340 -7 -417 -66 -18 -14 -33 -22 -33 -18 0 3 16 32
35 63 23 37 193 215 512 533 417 416 550 538 585 538 5 0 -4 -19 -20 -42z
m677 -172 c60 -32 81 -98 51 -161 -17 -35 -30 -47 -66 -61 -39 -15 -48 -15
-81 -3 -54 21 -86 72 -80 127 10 88 99 137 176 98z m128 -163 c7 -10 18 -26
24 -36 9 -17 8 -17 -11 -7 -16 8 -27 8 -49 -3 l-28 -14 21 39 c12 21 23 38 25
38 2 0 10 -8 18 -17z m327 -254 l146 2 0 -41 0 -40 -162 0 -163 0 -106 106
-106 106 26 27 26 27 96 -94 96 -94 147 1z m-415 160 c-6 -12 -9 -30 -6 -40 6
-24 -1 -24 -33 1 -27 21 -27 21 15 43 38 21 38 21 24 -4z m599 -139 c-3 -20
-4 -20 -26 -4 l-23 16 23 25 c22 24 23 24 26 4 2 -12 2 -30 0 -41z m2 -152 l0
-41 -25 23 c-23 22 -24 25 -8 42 25 28 33 22 33 -24z m-4 -176 c-3 -3 -15 3
-26 13 l-20 18 22 25 23 24 3 -37 c2 -20 1 -40 -2 -43z m-752 -107 c-4 -15 -1
-32 6 -40 20 -25 7 -26 -29 -3 l-34 22 29 23 c36 28 36 28 28 -2z m756 -27 l0
-41 -25 23 -26 24 23 18 c13 10 24 18 26 18 1 0 2 -19 2 -42z m-2404 -88 c8 0
-1 -19 -26 -52 -22 -29 -45 -68 -51 -86 -25 -76 -32 16 -8 118 15 65 27 75 51
45 11 -14 26 -25 34 -25z m1614 35 c62 -32 80 -129 35 -184 -71 -84 -198 -48
-212 59 -14 101 87 171 177 125z m134 -17 c17 4 17 2 -4 -28 -12 -17 -23 -30
-24 -28 -1 2 -12 20 -24 41 l-21 37 27 -13 c15 -7 36 -12 46 -9z m656 -80 c0
-40 0 -41 -20 -23 -23 21 -25 36 -8 53 21 21 28 13 28 -30z m-40 -78 c0 -40 0
-40 -37 -40 -34 0 -47 -9 -134 -96 l-96 -96 -27 27 c-14 14 -26 27 -26 29 0 2
48 51 108 109 l107 107 53 0 52 0 0 -40z m36 -118 c-3 -3 -15 3 -26 13 l-20
18 22 25 23 24 3 -37 c2 -20 1 -40 -2 -43z m-372 -101 c5 -7 -10 -2 -33 11
l-41 23 27 22 28 23 6 -33 c3 -18 9 -39 13 -46z m-46 -25 c34 -18 62 -66 62
-106 0 -40 -28 -88 -62 -106 -40 -23 -103 -17 -137 12 -86 72 -38 215 72 214
23 -1 52 -6 65 -14z m128 -13 c25 4 23 -4 -9 -47 -14 -19 -16 -18 -35 22 l-20
42 20 -11 c11 -6 31 -9 44 -6z"/>
                    <motion.path fill='#fff'
                        initial={{
                            x: 200
                        }}
                        animate={{
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            repeat: Infinity,
                            repeatType: 'reverse'
                        }}
                        d="M556 1434 c-9 -8 -16 -22 -16 -29 0 -7 7 -21 16 -29 14 -14 69 -16
459 -16 390 0 445 2 459 16 22 21 20 51 -3 64 -13 6 -175 10 -460 10 -386 0
-441 -2 -455 -16z"/>
                    <motion.path fill='#fff'
                        initial={{
                            x: 180
                        }}
                        animate={{
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            repeat: Infinity,
                            repeatType: 'reverse'
                        }}
                        d="M556 1204 c-9 -8 -16 -22 -16 -29 0 -7 7 -21 16 -29 14 -14 86 -16
648 -16 478 0 635 3 644 12 15 15 15 51 0 66 -9 9 -166 12 -644 12 -562 0
-634 -2 -648 -16z"/>
                    <motion.path fill='#fff'
                        initial={{
                            x: 160
                        }}
                        animate={{
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            repeat: Infinity,
                            repeatType: 'reverse'
                        }}
                        d="M556 974 c-9 -8 -16 -22 -16 -29 0 -7 7 -21 16 -29 14 -14 86 -16
648 -16 478 0 635 3 644 12 15 15 15 51 0 66 -9 9 -166 12 -644 12 -562 0
-634 -2 -648 -16z"/>
                    <motion.path fill='#fff'
                        initial={{
                            x: 140
                        }}
                        animate={{
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            repeat: Infinity,
                            repeatType: 'reverse'
                        }}
                        d="M556 744 c-9 -8 -16 -22 -16 -29 0 -7 7 -21 16 -29 14 -14 86 -16
648 -16 478 0 635 3 644 12 15 15 15 51 0 66 -9 9 -166 12 -644 12 -562 0
-634 -2 -648 -16z"/>
                    <path fill='#fff' d="M1878 449 c-21 -12 -24 -52 -4 -68 9 -8 87 -11 261 -9 247 3 248 3
256 26 6 17 4 27 -11 42 -19 19 -34 20 -252 20 -140 0 -240 -5 -250 -11z"/>
                </g>
            </motion.svg>
        </>
    );
}

export {SmartContractSvg};
