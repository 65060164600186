import { Button, Image, Space, Typography } from 'antd'
import React from 'react'
import { Typewriter } from '../../PageComponents'
import { motion } from 'framer-motion'

const {Title} = Typography
const TrendingTopic = ()=> {
  const messages =[
    'Trending Topics'
  ]
  return (
    <div className='padding center'>
      <Space direction='vertical' size={30} align='center' wrap>
        <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
                opacity: 1,
                y: 0,
            }}
            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
            viewport={{once: true}}
        >
            
            <Title level={2} className='m-0 gradient-text'>
                <Typewriter messages={messages} speed={150} />
            </Title>
        </motion.div>
        <Space size={15} className='px-4 center' wrap>
          <Button className='trend-btn'>
            <img src='/assets/icons/seamless.png' width={20} height={20} />
            Web 3.0
          </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/nft.png' width={20} height={20} />
            NFT
          </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/blockchain.png' width={20} height={20} />
            Blockchain
            </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/ai.png' width={20} height={20} />
            Artificial Intelligence
            </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/token.png' width={20} height={20} />
            Token
          </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/inno.png' width={20} height={20} />
            Decentralization
          </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/defi.png' width={20} height={20} />
            Defi Application
          </Button>
          <Button className='trend-btn'>
            <img src='/assets/icons/contract.png' width={20} height={20} />
            Smart Contract
          </Button>
        </Space>
      </Space>  
    </div>
  )
}

export {TrendingTopic}