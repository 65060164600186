import React from 'react'
import { Card, Col, Flex, Image, Row, Space, Typography } from 'antd'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Text, Title } = Typography
const AiEmpower = () => {

    const data = [
        {
            img:'ai-1.webp',
            title:'AI-Powered Automation',
            list:[
                'Automates repetitive tasks efficiently',
                'Improve in accurancy & reduces errors in workflows',
                'Cuts labor expenses and resource waste',
            ],
        },
        {
            img:'ai-2.webp',
            title:'Predictive Analytics',
            list:[
                'Data-driven decision making efficiently',
                'AI uses historical data to forecast trends',
                'Enabling businesses to make proactive and informed decisions',
            ],
        },
        {
            img:'ai-3.webp',
            title:'Natural Language Processing',
            list:[
                'Enables machines to process and interpret human language',
                'Powers real-time translation tools like Google Translate',
                'Drives conversational AI systems like Siri and Alexa',
            ],
        },
    ]
    

  return (
    <Row gutter={[24,64]} className='padding'>
        <Col span={24}>
            <Flex vertical align='center' gap={10}>
                <Title className='gradient-text text-center m-0'>
                    AI empowers innovation.
                </Title>
                <UnderlineDesign />
                <Text className='text-gray'>
                    Your Personalised Incubator
                </Text>
            </Flex>
        </Col>
        {
            data?.map((data)=>
                <Col lg={{span:8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                    <Card className='bg-transparent border0 rounded shadow card-design h-100' >
                        <Flex vertical gap={15}>
                            <Image src={'/assets/images/'+data?.img} preview={false} className='rounded' />
                            <Flex vertical gap={15}>
                                <Title level={4} className='gradient-text m-0'>
                                    {
                                        data?.title
                                    }
                                </Title>
                                <div>
                                    {data?.list.map((item)=> (
                                        <Space size={12} align='center' className='mb-1'>
                                            <Image src="/assets/icons/cir.png" alt="check" width={12} preview={false} />
                                            <Text className='text-gray'>{item}</Text>
                                        </Space>
                                    ))}
                                </div>
                            </Flex>
                        </Flex>
                    </Card>
                </Col>
            )
        }
    </Row>
  )
}

export {AiEmpower}