import React from 'react'
import { CounterComponent, EfficientSmart, Faqs, FutureSolution, MainBanner, MarqueeBrands, Partners, RealityDream, Recentworks, Servicescomponent, Testimonial, UnleashFinance, WhySecurity, WhyUsHome } from '../../components'

const HomePage = () => {
  return (
    <div className=' m-top-130'>
        {/* <MainBanner /> */}
        <div className='container'>
          <WhyUsHome />
          <FutureSolution />
          <EfficientSmart />
          {/* <Recentworks /> */}
          <UnleashFinance />
          <CounterComponent />
          <WhySecurity />
          <Servicescomponent />
          <Faqs />
          <Testimonial />
          <RealityDream />
          <Partners />
        </div>
    </div>
  )
}

export {HomePage}