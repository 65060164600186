import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Text, Title } = Typography
const WhyEffect = () => {

    const data = [
        {
            title:'Security',
            dsc:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis illo quod, repudiandae cum explicabo sed ducimus voluptas perspiciatis autem assumenda sit officia tempore ipsum nulla corporis nesciunt numquam unde hic?'
        },
        {
            title:'Flexibility',
            dsc:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis illo quod, repudiandae cum explicabo sed ducimus voluptas perspiciatis autem assumenda sit officia tempore ipsum nulla corporis nesciunt numquam unde hic?'
        },
    ]

  return (
    <Row gutter={[24,64]} className='padding' align={'middle'}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Space size={10} direction='vertical'>
                <Title className='gradient-text'>How will the service effect the business</Title>
                <Row gutter={[24,12]}>
                    {
                        data?.map((data)=>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <Flex vertical>
                                    <Flex vertical gap={5} className='mb-2'>
                                        <Title level={4} className='gradient-text m-0'>
                                            {
                                                data?.title
                                            }
                                        </Title>
                                        <UnderlineDesign />
                                    </Flex>
                                    <Text className='text-gray text-justify'>
                                        {
                                            data?.dsc
                                        }
                                    </Text>
                                </Flex>
                            </Col>
                        )
                    }
                </Row>
                <Link to={'/contact'} className='btn w-auto mt-3'>
                    <div className='content vertical '>
                        <div className="text">Let's Join</div>
                        <div className="hover-text">Let's Join</div>
                    </div>
                </Link>
            </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Flex justify='end'>
                <Image src='/assets/images/blk-1.png' width={'80%'} preview={false} />
            </Flex>
        </Col>
    </Row>
  )
}

export {WhyEffect}