import React from 'react'
import { Col, Flex, Image, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Title, Text } = Typography
const BlockchainIntro = () => {
  return (
    <Row gutter={[24,64]} align={'middle'} className='padding'>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Flex justify='start'>
                <Image src='/assets/images/blk-5.png' width={'80%'} preview={false} />
            </Flex>
        </Col>
        <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
            <Flex vertical align='flex-start' gap={20} className='p-left'>
                <Title className='gradient-text m-0'>
                    Benefits and Challenges of Blockchain
                </Title>
                <Text className='text-gray text-justify'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam enim corporis quaerat amet, velit eligendi rem non laborum odit eius voluptas iure, cum iste eaque adipisci facilis dolores delectus aspernatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam enim corporis quaerat amet, velit eligendi rem non laborum odit eius voluptas iure, cum iste eaque adipisci facilis dolores delectus aspernatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam enim corporis quaerat amet, velit eligendi rem non laborum odit eius voluptas iure, cum iste eaque adipisci facilis dolores delectus aspernatur.
                </Text>
                <Link to={'/contact'} className='btn w-auto'>
                    <div className='content vertical '>
                        <div className="text">Let's Join</div>
                        <div className="hover-text">Let's Join</div>
                    </div>
                </Link>
            </Flex>
        </Col>
    </Row>
  )
}

export {BlockchainIntro}