import React from "react";
import {motion, spring} from 'framer-motion'
const Future = () => (
  <svg

  widths={'100%'}
  height={'100%'}

    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 115.000000 115.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,115.000000) scale(0.100000,-0.100000)"
      fill="#000"
      stroke="none"
    >
      <motion.path
      initial={{ x:-70,}}
      animate={{ x:[0,60, 0 ], opacity:1}}
      transition={{
        // delay:0,
        duration:1,
      yoyo:Infinity,
      repeat:Infinity
      }}
      d="M75 1126 c-50 -22 -60 -54 -60 -195 0 -187 20 -206 210 -206 87 0 117 4 143 18 38 21 62 68 62 122 l0 35 79 0 c59 0 82 4 92 16 9 11 10 20 2 32 -8 14 -27 17 -92 17 l-81 0 -1 35 c-1 62 -21 103 -59 122 -44 22 -247 25 -295 4z m244 -52 c40 -8 46 -29 45 -158 -1 -125 -2 -126 -133 -126 -110 0 -137 6 -146 28 -8 21 -8 214 0 235 4 8 16 18 28 20 36 7 172 7 206 1z" />
     
      <motion.path
         initial={{y:70, }}
         animate={{y:[0,60, 0 ], opacity:1}}
         transition={{
          // delay:0.5,
          duration:1,
          yoyo:Infinity,
          repeat:Infinity
        }}
      d="M783 1124 c-17 -9 -37 -30 -45 -47 -18 -43 -18 -241 0 -284 16 -37 68 -65 124 -67 l38 -1 0 -81 c0 -57 4 -85 14 -93 15 -13 46 -5 47 13 0 6 1 45 2 85 l2 75 43 3 c105 7 132 50 132 208 0 184 -22 205 -212 205 -82 0 -122 -5 -145 -16z m279 -62 c9 -9 13 -48 13 -128 0 -149 2 -146 -153 -142 -87 2 -115 6 -122 18 -6 8 -9 67 -8 130 3 111 4 115 28 128 33 17 224 12 242 -6z" />
     
      <motion.path
         initial={{y:-70, }}
         animate={{y:[0,60, 0 ], opacity:1}}
         transition={{
          // delay:1.5,
          duration:1,
        yoyo:Infinity,
      repeat:Infinity}}
      d="M197 604 c-4 -4 -7 -44 -7 -89 l0 -82 -54 -6 c-105 -11 -121 -39 -121 -214 0 -107 2 -125 20 -149 32 -42 85 -56 200 -52 174 6 198 33 193 223 -5 158 -20 181 -124 192 l-53 6 -3 86 c-3 78 -5 86 -23 89 -12 2 -24 0 -28 -4z m153 -260 c22 -22 22 -227 -1 -249 -25 -25 -238 -24 -256 1 -18 24 -18 237 0 252 30 26 231 23 257 -4z" />
     
      <motion.path
         initial={{x:70, }}
         animate={{x:[0,60, 0 ], opacity:1}}
         transition={{
          // delay:1,
          duration:1,
          yoyo:Infinity,
          repeat:Infinity
        }}
      d="M804 424 c-37 -9 -65 -49 -77 -113 l-12 -61 -81 0 c-66 0 -83 -3 -88 -16 -12 -32 13 -44 94 -44 l78 0 6 -54 c12 -96 63 -126 210 -126 94 0 147 14 178 47 20 22 23 36 26 136 7 211 -10 231 -193 234 -66 1 -130 -1 -141 -3z m250 -70 c31 -12 34 -230 5 -259 -14 -13 -79 -19 -186 -16 -77 2 -83 12 -83 140 0 121 7 138 60 144 41 6 183 -1 204 -9z" />
    </g>
  </svg>
);
export {Future};
