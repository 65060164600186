import React from 'react'
import { Card, Col, Flex, Row, Typography } from 'antd'
import { motion } from 'framer-motion'
import { Future } from '../../../SvgsFile'
import { Typewriter } from '../../PageComponents'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography

const FutureSolution = () => {

    // const targetRef = useRef(null)
    // const { scrollYProgress } = useScroll({
    //     target: targetRef,
    //     offset:["end end", "end start"]
    // })
    // const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
    // const scale = useTransform( scrollYProgress, [0, .5], [1, 1.5] )

    const messages = [
        'Future-Forward Solutions'
    ]

  return (
    <motion.div className='padding'
        // ref={targetRef}
        // style={{
        //     opacity,
        //     scale,
        // }}
    >
        <Row gutter={[24,24]} align={'middle'}>
            <Col lg={{span: 14}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} >
                <Flex vertical gap={30}>
                    <Flex gap={10} align='flex-start'>
                        {/* <motion.div className='sm-icon'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                            style={{width: 40, height:40,}}
                        >
                            <Future />
                        </motion.div>
                        <motion.div className='grad-line'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        ></motion.div> */}
                        <motion.div className='sm-icon'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                            style={{width: 40, height:40,}}
                        >
                            <Future />
                        </motion.div>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                    </Flex>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Text className='text-gray fs-16'
                        >
                            Our Web3 fintech simplifies complex finance for everyone. <br /> With innovative technology, we make navigating the financial future easier.
                        </Text>
                    </motion.div>
                    <motion.Flex
                        initial={{ y: '100%', opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .6, duration: .3, }}
                        viewport={{once: true}}
                    >
                        <Link to={'/learnmore'} className='btn w-auto'>
                            <div className='content vertical '>
                                <div className="text">Learn More</div>
                                <div className="hover-text">Learn More</div>
                            </div>
                        </Link>
                    </motion.Flex>
                </Flex>
            </Col>
            <Col lg={{span: 10}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                <Card className='card-grad card-extra'>
                    <motion.div
                        initial={{ x: '-30vw' }}
                        animate={{ x: 500 }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: "loop",
                        }}
                        className='top-small-bar'
                    ></motion.div>
                    <Flex justify='center'>
                        {/* <Image src={'/assets/images/shap-1.png'} width={242} height={243} preview={false}/> */}
                        <div style={{width: 220}}>
                            <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 242 242"
                            >
                                <motion.path
                                    d="M 121 0 L 0 121 L 121 242 L 242 121 Z"
                                    stroke="rgb(255, 255, 255)"
                                    fill="none"
                                    strokeWidth="2"
                                    strokeDasharray="1200"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }}  
                                />
                                
                                <motion.path
                                    d="M 90.607 30.597 C 107.286 13.919 134.327 13.919 151.005 30.597 C 167.683 47.276 167.683 74.317 151.005 90.995 C 134.327 107.673 107.286 107.673 90.607 90.995 C 73.929 74.317 73.929 47.276 90.607 30.597 Z"
                                    fill="transparent"
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeWidth="2"
                                    strokeDasharray="1200"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 30.209 90.995 C 46.888 74.317 73.929 74.317 90.607 90.995 C 107.286 107.673 107.286 134.714 90.607 151.393 C 73.929 168.071 46.888 168.071 30.209 151.393 C 13.531 134.714 13.531 107.673 30.209 90.995 Z"
                                    fill="transparent"
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeWidth="2"
                                    strokeDasharray="1200"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 151.005 90.995 C 167.684 74.317 194.725 74.317 211.403 90.995 C 228.081 107.673 228.081 134.714 211.403 151.393 C 194.725 168.071 167.684 168.071 151.005 151.393 C 134.327 134.714 134.327 107.673 151.005 90.995 Z"
                                    fill="transparent"
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeWidth="2"
                                    strokeDasharray="1200"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 90.607 151.392 C 107.286 134.714 134.327 134.714 151.005 151.392 C 167.683 168.071 167.683 195.112 151.005 211.79 C 134.327 228.468 107.286 228.468 90.607 211.79 C 73.929 195.112 73.929 168.071 90.607 151.392 Z"
                                    fill="transparent"
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeWidth="2"
                                    strokeDasharray="1200"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 150.023 90.174 L 120.726 60.877"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 89.625 150.572 L 31.03 91.977"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 210.421 150.572 L 151.826 91.977"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 120.726 181.672 L 91.428 152.374"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 90.846 90.498 L 150.509 30.836"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 60.12 121.225 L 90.111 91.234"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 151.243 150.896 L 210.906 91.234"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <motion.path
                                    d="M 90.846 211.294 L 150.509 151.631"
                                    fill="transparent"
                                    strokeWidth={0.5}
                                    stroke="rgb(255,255,255)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
                                />
                                <path
                                    d="M 179.42 119.41 C 180.405 118.424 182.003 118.424 182.988 119.41 C 183.974 120.395 183.974 121.993 182.988 122.978 C 182.003 123.964 180.405 123.964 179.42 122.978 C 178.434 121.993 178.434 120.395 179.42 119.41 Z"
                                    fill="rgb(255,255,255)"
                                />
                                <path
                                    d="M 119.022 179.807 C 120.007 178.821 121.605 178.821 122.59 179.807 C 123.576 180.792 123.576 182.39 122.59 183.375 C 121.605 184.361 120.007 184.361 119.022 183.375 C 118.036 182.39 118.036 180.792 119.022 179.807 Z"
                                    fill="rgb(255,255,255)"
                                />
                                <path
                                    d="M 58.624 119.41 C 59.609 118.424 61.207 118.424 62.193 119.41 C 63.178 120.395 63.178 121.993 62.193 122.978 C 61.207 123.964 59.609 123.964 58.624 122.978 C 57.638 121.993 57.638 120.395 58.624 119.41 Z"
                                    fill="rgb(255,255,255)"
                                />
                                <path
                                    d="M 119.022 59.012 C 120.007 58.026 121.605 58.026 122.59 59.012 C 123.576 59.997 123.576 61.595 122.59 62.58 C 121.605 63.566 120.007 63.566 119.022 62.58 C 118.036 61.595 118.036 59.997 119.022 59.012 Z"
                                    fill="rgb(255, 255, 255)"
                                />
                                <motion.path
                                    d="M 60.556 181.959 L 120.438 122.077 L 180.32 181.959 L 120.438 241.841 Z"
                                    fill="transparent"
                                    stroke="rgb(217,217,217)"
                                    strokeMiterlimit={10}
                                    strokeDasharray="6,3"
                                    strokeDashoffset="1200"
                                    animate={{ strokeDashoffset: 0, }}
                                    transition={{
                                        duration: 5,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }} 
        
                                />
                            </svg>
                        </div>
                    </Flex>
                    <Title level={5} className='m-0 text-white'>AI is the future</Title>
                    <div className='parent-shap'>
                        <div className='bg-shape'></div>
                    </div>
                </Card>                        
            </Col>
        </Row>
    </motion.div>
  )
}

export {FutureSolution}