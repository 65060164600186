import React from 'react'
import { Button, Col,  Flex, Form, Image, Row, Typography, Upload, message } from 'antd'
import { Breadcrumb, MyInput, Typewriter } from '../../components'
import { motion } from 'framer-motion'

const { Text, Title } = Typography
const { Dragger } = Upload
const JobAppForm = () => {

    const [form] = Form.useForm();
    const messages = [
        'Application Form'
    ]
    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <div >
        <Breadcrumb title='Job Form' pageName='Job Form' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'}>
                <Col span={24}>
                    <Flex vertical gap={10} justify='center' align='center' className='text-center mt-5'>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once:true}}
                        >
                            <Text className='text-gray'>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </Text>
                        </motion.div>
                    </Flex>  
                </Col>
                <Col span={24}>
                    <Form 
                        form={form}
                        layout='vertical'
                        requiredMark={false}
                    >
                        <Row gutter={[16, 16]} justify={'center'}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                <MyInput 
                                    label='First Name'
                                    name='firstName'
                                    required
                                    message='Please enter first name'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                <MyInput 
                                    label='Middle Name'
                                    name='middlename'
                                    required
                                    message='Please enter last name'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                <MyInput 
                                    label='Last Name'
                                    name='lastname'
                                    required
                                    message='Please enter last name'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <MyInput 
                                    label='Email'
                                    name='email'
                                    required
                                    message='Please enter email'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput 
                                    label='Contact number'
                                    name='contacno'
                                    type='number'
                                    required
                                    message='Please enter contact number'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput 
                                    label='Address'
                                    name='address'
                                    required
                                    message='Please enter address'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput 
                                    label='City'
                                    name='city'
                                    required
                                    message='Please enter city'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput 
                                    label='Country'
                                    name='country'
                                    required
                                    message='Please enter country'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput 
                                    label='Experience'
                                    name='experience'
                                    required
                                    message='Please enter experience'
                                    placeholder=''
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                <MyInput 
                                    label='Website link'
                                    name='websiteLink'
                                    required
                                    message='Please enter website link'
                                    placeholder=''
                                />
                            </Col>
                            <Col span={24}>
                                <Form.Item name='image' className='m-0' label={<Typography.Text className='text-white'>Upload Image</Typography.Text>}>
                                    <div className='width-drag'>
                                        <Dragger {...props} className="dragcs">
                                            <p className="ant-upload-drag-icon mb-0">
                                                <Image src='/assets/icons/upload-file.png' width={'41.6px'} preview={false} />
                                            </p>
                                            <Typography.Text className="fs-14 text-white">Upload Cv</Typography.Text>
                                        </Dragger>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <MyInput 
                                    textArea
                                    label='Message'
                                    name='message'
                                    required
                                    message='Please enter message'
                                    autoSize={{ minRows: 8, maxRows: 10 }}
                                    placeholder='Write your message here'
                                />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                                <Flex>
                                    <Button className="btn rounded" block>Submit</Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {JobAppForm}