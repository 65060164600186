import React from "react";
import { motion } from "framer-motion";

const SeamlessSvg = () => {
  return (
    <>
      <motion.svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 256 256"
        // enable-background="new 0 0 256 256"
        // xml:space="preserve"
        animate={{
          rotate: [0, 360, 0],
          scale: 1.1,
        }}
        transition={{
          duration: 10,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        <motion.path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M141.000000,257.000000
	C94.000000,257.000000 47.500004,257.000000 1.000006,257.000000
	C1.000004,171.666672 1.000004,86.333351 1.000002,1.000018
	C86.333321,1.000012 171.666641,1.000012 257.000000,1.000006
	C257.000000,86.333313 257.000000,171.666626 257.000000,256.999969
	C218.500000,257.000000 180.000000,257.000000 141.000000,257.000000
M220.002457,131.405792
	C218.001816,122.622406 216.364182,113.733170 213.846802,105.100464
	C212.595612,100.809761 213.692535,99.034515 217.250610,96.819084
	C226.112015,91.301491 230.574341,79.546051 227.660370,70.190926
	C224.465942,59.935455 217.349533,53.530899 206.765228,52.156414
	C197.122650,50.904228 189.144470,54.813553 183.418076,62.545822
	C175.093903,58.655369 166.872055,54.632710 158.492462,50.971073
	C154.196198,49.093742 152.243073,46.553455 152.922226,41.651550
	C154.111359,33.069195 146.775406,25.447744 139.908813,23.077286
	C125.147667,17.981501 110.853630,28.865816 112.033630,43.191807
	C112.325615,46.736691 111.192726,48.601959 107.719231,50.170372
	C96.601448,55.190472 85.729034,60.753963 74.893379,66.043533
	C70.802025,60.697830 65.224579,58.161007 59.147995,59.202282
	C49.486000,60.857941 45.533775,65.015213 44.209675,72.105774
	C42.573509,80.867477 45.166603,88.555740 53.893482,92.495422
	C51.083904,104.860588 48.353119,116.878975 45.659241,128.734940
	C42.583427,128.991516 39.634861,128.702118 37.053417,129.541626
	C22.717196,134.203873 17.673368,146.965897 20.611950,159.020706
	C24.391090,174.523712 39.229195,179.883606 51.602119,175.832153
	C54.876865,174.759872 56.497570,175.560333 58.252735,177.756683
	C64.851425,186.014053 71.426949,194.289993 78.038834,202.536774
	C79.303429,204.114075 79.575470,205.116806 78.381355,207.352707
	C74.579155,214.472168 77.646225,225.306290 84.195114,229.673080
	C89.651611,233.311447 101.750587,233.654968 106.594009,226.885422
	C108.364288,224.411133 109.997391,221.838684 111.842125,219.088837
	C124.787254,219.088837 138.097931,219.088837 151.524460,219.088837
	C153.952011,228.371780 159.615173,234.558426 169.222153,235.899918
	C178.385590,237.179489 186.279968,233.557571 190.544937,225.552460
	C194.788361,217.587814 194.355759,208.996780 188.312759,201.669922
	C195.599899,192.260101 202.802979,182.840744 210.158417,173.541885
	C212.053177,171.146500 213.723328,168.304535 218.012054,169.832001
	C220.317978,170.653290 223.467819,170.405838 225.831924,169.537781
	C236.027466,165.794113 238.819550,160.060211 237.962341,148.993332
	C237.445923,142.326080 229.744293,136.300171 221.502411,135.772430
	C221.014114,134.604965 220.525818,133.437500 220.002457,131.405792
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M220.019989,131.837906
	C220.525818,133.437500 221.014114,134.604965 221.501373,135.772888
	C229.744293,136.300171 237.445923,142.326080 237.962341,148.993332
	C238.819550,160.060211 236.027466,165.794113 225.831924,169.537781
	C223.467819,170.405838 220.317978,170.653290 218.012054,169.832001
	C213.723328,168.304535 212.053177,171.146500 210.158417,173.541885
	C202.802979,182.840744 195.599899,192.260101 188.312759,201.669922
	C194.355759,208.996780 194.788361,217.587814 190.544937,225.552460
	C186.279968,233.557571 178.385590,237.179489 169.222153,235.899918
	C159.615173,234.558426 153.952011,228.371780 151.524460,219.088837
	C138.097931,219.088837 124.787254,219.088837 111.842125,219.088837
	C109.997391,221.838684 108.364288,224.411133 106.594009,226.885422
	C101.750587,233.654968 89.651611,233.311447 84.195114,229.673080
	C77.646225,225.306290 74.579155,214.472168 78.381355,207.352707
	C79.575470,205.116806 79.303429,204.114075 78.038834,202.536774
	C71.426949,194.289993 64.851425,186.014053 58.252735,177.756683
	C56.497570,175.560333 54.876865,174.759872 51.602119,175.832153
	C39.229195,179.883606 24.391090,174.523712 20.611950,159.020706
	C17.673368,146.965897 22.717196,134.203873 37.053417,129.541626
	C39.634861,128.702118 42.583427,128.991516 45.659241,128.734940
	C48.353119,116.878975 51.083904,104.860588 53.893482,92.495422
	C45.166603,88.555740 42.573509,80.867477 44.209675,72.105774
	C45.533775,65.015213 49.486000,60.857941 59.147995,59.202282
	C65.224579,58.161007 70.802025,60.697830 74.893379,66.043533
	C85.729034,60.753963 96.601448,55.190472 107.719231,50.170372
	C111.192726,48.601959 112.325615,46.736691 112.033630,43.191807
	C110.853630,28.865816 125.147667,17.981501 139.908813,23.077286
	C146.775406,25.447744 154.111359,33.069195 152.922226,41.651550
	C152.243073,46.553455 154.196198,49.093742 158.492462,50.971073
	C166.872055,54.632710 175.093903,58.655369 183.418076,62.545822
	C189.144470,54.813553 197.122650,50.904228 206.765228,52.156414
	C217.349533,53.530899 224.465942,59.935455 227.660370,70.190926
	C230.574341,79.546051 226.112015,91.301491 217.250610,96.819084
	C213.692535,99.034515 212.595612,100.809761 213.846802,105.100464
	C216.364182,113.733170 218.001816,122.622406 220.019989,131.837906
M167.030823,141.760895
	C166.879944,139.870255 167.425446,136.861038 166.468430,136.265213
	C158.528458,131.321930 158.235977,120.042213 161.499985,114.604439
	C164.053070,110.351051 163.745468,107.786018 161.109055,104.424446
	C160.045227,103.068016 159.300568,101.461266 158.528076,100.165405
	C151.269699,102.170334 145.307663,100.430870 140.587326,95.253143
	C137.709488,96.632263 134.880722,97.555092 132.560013,99.196320
	C128.580231,102.010895 121.119194,102.820328 124.939339,110.850853
	C125.073044,111.131920 124.456787,111.694016 124.410866,112.147476
	C123.576340,120.387840 119.091698,126.076408 112.026070,129.599854
	C113.069473,134.840790 113.903496,139.752625 115.076065,144.582230
	C115.581070,146.662247 115.787468,148.604568 119.196701,149.315720
	C125.474777,150.625305 130.501251,154.257370 132.123764,161.313721
	C132.857117,164.503098 145.542633,166.344330 147.676346,163.777710
	C147.866348,163.549164 147.876541,163.179520 148.008087,162.892792
	C150.664322,157.103027 155.512741,154.341263 161.462006,152.951752
	C162.715164,152.659058 164.464249,151.930847 164.845886,150.956985
	C165.889450,148.293976 166.325531,145.392914 167.030823,141.760895
M80.013519,155.518280
	C83.046806,156.012772 86.288307,155.988007 89.072296,157.104263
	C95.966492,159.868546 101.389793,158.914658 105.284279,152.225052
	C105.521622,151.817368 106.080093,151.601196 106.335281,151.198410
	C106.935455,150.251144 108.035896,149.144043 107.889000,148.279053
	C107.055435,143.370956 106.012344,138.490463 104.799057,133.661255
	C104.544098,132.646454 103.426414,131.355164 102.457764,131.086197
	C85.246193,126.306793 79.781151,116.912720 84.901733,100.284790
	C82.729355,98.786613 80.104027,97.588470 78.327927,95.622955
	C74.694542,91.602020 71.272087,89.723373 65.836533,92.816132
	C63.465652,94.165131 61.517578,94.932602 60.952393,97.854446
	C60.191628,101.787346 59.016975,105.638153 58.150509,109.553085
	C56.594856,116.581963 55.136425,123.632362 53.665192,130.543060
	C61.534840,133.635284 68.459534,142.808731 68.102295,150.519257
	C68.050468,151.637970 69.171249,153.602585 70.083588,153.828964
	C73.061790,154.567902 76.194641,154.683594 80.013519,155.518280
M146.993912,174.327087
	C144.389664,173.551437 141.824585,172.341324 139.171982,172.102295
	C135.222809,171.746414 131.521378,171.192978 129.263046,176.705078
	C128.057571,179.647385 122.793625,182.739899 119.480263,182.614822
	C113.262352,182.380096 111.248299,185.702286 109.385849,190.156860
	C106.878151,196.154724 102.623352,202.228668 109.968330,207.934387
	C111.173279,208.870407 112.233879,210.846542 113.385704,210.865662
	C126.176369,211.078094 138.971863,210.999496 151.646210,210.999496
	C153.253143,204.829071 156.548248,200.356613 161.633255,196.932373
	C162.956528,196.041275 164.053726,193.410309 163.750900,191.875137
	C163.397003,190.081100 161.598740,187.465561 160.076263,187.197250
	C152.957825,185.942749 148.958054,181.785217 146.993912,174.327087
M197.619125,141.966827
	C194.511658,139.727753 191.404190,137.488678 188.855881,135.652496
	C186.436600,136.868134 184.366470,138.701324 182.185165,138.844849
	C177.118134,139.178238 174.920578,142.055176 174.293655,146.426956
	C173.722122,150.412399 170.352692,154.373215 175.623184,158.363647
	C184.301254,164.934021 181.924240,178.830307 174.084045,184.071320
	C170.118637,186.722122 171.514893,189.103104 171.933609,192.205948
	C172.435120,195.922348 175.109161,194.649261 177.196838,195.047867
	C178.703079,195.335434 180.127945,196.049393 181.505875,196.543335
	C189.113480,187.091507 196.747910,177.631882 204.348160,168.144867
	C206.137344,165.911484 208.481628,163.986603 205.433823,160.635117
	C204.140244,159.212646 203.832794,156.270203 204.116440,154.158127
	C204.850266,148.693604 203.779694,144.417068 197.619125,141.966827
M74.800507,162.968552
	C72.362381,162.325989 69.924255,161.683411 67.678261,161.091476
	C65.793724,164.095917 64.039680,166.892334 62.196789,169.830368
	C69.099373,178.351822 76.364281,186.714813 82.861252,195.637009
	C85.489868,199.246857 87.444969,198.878052 91.315666,198.358948
	C95.579147,197.787125 98.234329,196.965637 99.701584,192.810822
	C101.178146,188.629654 103.055717,184.590118 104.241264,181.732834
	C102.231400,177.063156 100.727783,173.248932 98.925827,169.581299
	C98.394997,168.500885 97.135742,167.314285 96.012939,167.064880
	C89.263390,165.565552 82.456352,164.324997 74.800507,162.968552
M191.120438,97.649284
	C188.882690,95.742035 185.363861,94.224632 184.642990,91.855782
	C182.310089,84.189743 177.640915,82.840240 170.837662,85.047050
	C170.688614,85.095413 170.561356,85.210968 171.268997,84.777382
	C169.584290,88.559608 168.225693,91.568375 166.909912,94.595749
	C166.619614,95.263649 166.096405,96.187538 166.334930,96.669830
	C167.832367,99.697418 169.197769,102.898201 171.322937,105.443916
	C171.990173,106.243202 174.860748,105.136093 176.730148,105.026428
	C177.702438,104.969398 178.724548,105.053391 179.666626,105.296532
	C191.165527,108.264160 196.084259,116.757408 193.246918,128.792480
	C197.343689,131.579163 201.733658,134.070953 205.503387,137.289276
	C208.405563,139.766953 210.511841,139.993713 213.067673,137.015427
	C210.331635,125.093918 207.572083,113.069977 204.999542,101.860840
	C200.104980,100.420341 195.966980,99.202499 191.120438,97.649284
M104.387047,60.964279
	C95.593246,65.135735 86.799446,69.307182 79.099007,72.959984
	C73.267616,85.494698 83.633896,88.514931 88.828362,93.071587
	C95.640999,91.321724 101.777992,89.745399 108.806488,87.940086
	C112.367943,79.645538 116.507523,70.004555 120.595146,60.484589
	C117.728989,55.108311 113.790848,55.318989 109.274460,58.267765
	C107.936012,59.141644 106.431236,59.760780 104.387047,60.964279
M219.983185,79.960876
	C221.038162,68.304985 215.445679,62.933514 206.819321,60.331886
	C198.021118,57.678440 185.671219,68.322456 187.036301,77.294304
	C188.290863,85.539589 195.214188,92.311501 203.092117,92.998947
	C209.712631,93.576668 216.978683,88.246071 219.983185,79.960876
M59.999218,156.013245
	C60.643284,147.858795 58.528717,141.127808 50.261578,137.789001
	C43.894947,135.217728 38.178894,136.605789 33.039230,140.990387
	C28.887827,144.531906 26.553442,152.919724 28.398546,157.900406
	C30.749063,164.245407 36.982700,168.773972 43.069275,169.042770
	C52.018097,169.438004 56.751511,164.262039 59.999218,156.013245
M121.503609,78.997887
	C119.629372,83.396812 117.755135,87.795738 115.936890,92.063271
	C118.311569,96.093208 121.059807,96.785019 124.842743,94.082245
	C126.899956,92.612427 129.399338,91.736366 131.750137,90.708908
	C134.742584,89.401016 136.352310,87.532837 136.062271,83.934235
	C135.325165,74.788490 141.713165,69.365845 147.631699,67.208275
	C155.469559,64.351036 164.055420,67.545784 167.654663,74.261650
	C169.265747,77.267776 171.594193,77.230637 174.157333,76.912338
	C176.040665,76.678444 177.869644,76.006607 179.741013,75.525261
	C181.078232,71.234291 179.016937,69.185837 175.165314,67.505707
	C166.506088,63.728443 158.039307,59.509968 149.136017,55.301788
	C144.058197,61.957943 136.937393,64.666023 128.220627,63.088688
	C126.082413,68.306740 124.024017,73.330017 121.503609,78.997887
M111.374916,99.917908
	C110.416412,99.337715 109.519539,98.595680 108.489479,98.203377
	C101.325684,95.474983 94.514442,98.662788 91.983437,105.860741
	C89.250298,113.633530 92.212036,119.437447 100.242241,122.044945
	C105.730087,123.826904 111.760345,121.540955 114.850838,116.442230
	C117.837273,111.515221 117.189751,104.846542 111.374916,99.917908
M174.142807,227.998840
	C175.918884,227.280472 177.845505,226.800873 179.440445,225.795212
	C183.035324,223.528549 186.582642,217.055862 184.320602,211.686859
	C182.222000,206.705780 176.438431,201.685532 172.090195,202.020767
	C165.257919,202.547485 159.080124,208.876877 159.006149,215.425873
	C158.937317,221.520111 166.095825,227.814606 174.142807,227.998840
M121.235077,36.237469
	C117.595329,47.421276 122.325966,52.964108 129.600937,55.034420
	C133.846802,56.242718 140.372375,54.245171 142.749176,50.629391
	C146.196457,45.385105 145.482819,37.797703 142.429764,34.761127
	C136.763794,29.125734 128.906418,27.346907 121.235077,36.237469
M154.959839,168.377182
	C153.235321,173.720413 158.850647,180.762878 164.203110,179.969666
	C168.245880,179.370514 172.903946,174.452728 172.999405,170.682907
	C173.099731,166.720825 169.108002,161.922104 164.961609,161.020142
	C161.256699,160.214218 156.872330,163.099976 154.959839,168.377182
M168.000000,122.507332
	C167.835052,127.942726 171.479828,131.491470 177.012039,131.924194
	C181.398682,132.267303 185.753738,127.835983 185.999802,123.405647
	C186.295425,118.083031 182.974060,113.864357 177.820755,113.016937
	C173.253311,112.265869 169.110229,115.919662 168.000000,122.507332
M157.841629,91.691170
	C159.547195,89.184792 162.724808,86.612434 162.616119,84.187386
	C162.449753,80.475983 160.799225,76.644104 156.208252,74.843826
	C151.838913,73.130455 148.715317,74.827385 146.314209,77.536728
	C143.100876,81.162537 143.070038,85.827621 146.298187,89.443130
	C149.010101,92.480476 152.744156,94.434799 157.841629,91.691170
M53.315533,81.846542
	C53.671272,82.194519 53.968781,82.727226 54.392147,82.860680
	C57.273808,83.769058 60.346176,85.626724 63.042412,85.198280
	C66.913193,84.583214 69.894859,81.924080 70.059563,77.165939
	C70.208130,72.873978 68.439171,70.192711 64.712952,68.212784
	C60.988853,66.233986 57.828541,67.070473 54.999161,69.556557
	C51.336033,72.775230 51.085693,76.804420 53.315533,81.846542
M85.015701,213.037231
	C85.010849,214.365845 84.894005,215.705353 85.022110,217.021011
	C85.407852,220.982788 90.181580,225.096359 94.131020,224.959076
	C98.424904,224.809860 102.652603,220.901657 102.999298,216.761032
	C103.392853,212.060669 100.767365,207.655609 96.732559,206.246567
	C92.499359,204.768234 88.443443,206.882812 85.015701,213.037231
M111.086929,158.387558
	C109.437447,161.095642 106.476959,163.771591 106.409607,166.518494
	C106.266449,172.356400 111.918892,176.101395 117.600304,175.001648
	C122.616058,174.030762 126.016563,168.736023 124.610489,164.086441
	C122.717094,157.825424 118.057739,155.616440 111.086929,158.387558
M214.633896,146.042938
	C214.332260,146.592728 214.070755,147.169449 213.722641,147.688049
	C210.774750,152.079773 211.489731,156.744675 215.643372,160.109329
	C219.368378,163.126755 223.889908,162.744064 227.400375,159.114212
	C230.800644,155.598297 231.042801,150.428818 227.975525,146.836319
	C224.971420,143.317825 219.382278,142.924301 214.633896,146.042938
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M167.016495,142.173508
	C166.325531,145.392914 165.889450,148.293976 164.845886,150.956985
	C164.464249,151.930847 162.715164,152.659058 161.462006,152.951752
	C155.512741,154.341263 150.664322,157.103027 148.008087,162.892792
	C147.876541,163.179520 147.866348,163.549164 147.676346,163.777710
	C145.542633,166.344330 132.857117,164.503098 132.123764,161.313721
	C130.501251,154.257370 125.474777,150.625305 119.196701,149.315720
	C115.787468,148.604568 115.581070,146.662247 115.076065,144.582230
	C113.903496,139.752625 113.069473,134.840790 112.026070,129.599854
	C119.091698,126.076408 123.576340,120.387840 124.410866,112.147476
	C124.456787,111.694016 125.073044,111.131920 124.939339,110.850853
	C121.119194,102.820328 128.580231,102.010895 132.560013,99.196320
	C134.880722,97.555092 137.709488,96.632263 140.587326,95.253143
	C145.307663,100.430870 151.269699,102.170334 158.528076,100.165405
	C159.300568,101.461266 160.045227,103.068016 161.109055,104.424446
	C163.745468,107.786018 164.053070,110.351051 161.499985,114.604439
	C158.235977,120.042213 158.528458,131.321930 166.468430,136.265213
	C167.425446,136.861038 166.879944,139.870255 167.016495,142.173508
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M79.640762,155.278656
	C76.194641,154.683594 73.061790,154.567902 70.083588,153.828964
	C69.171249,153.602585 68.050468,151.637970 68.102295,150.519257
	C68.459534,142.808731 61.534840,133.635284 53.665192,130.543060
	C55.136425,123.632362 56.594856,116.581963 58.150509,109.553085
	C59.016975,105.638153 60.191628,101.787346 60.952393,97.854446
	C61.517578,94.932602 63.465652,94.165131 65.836533,92.816132
	C71.272087,89.723373 74.694542,91.602020 78.327927,95.622955
	C80.104027,97.588470 82.729355,98.786613 84.901733,100.284790
	C79.781151,116.912720 85.246193,126.306793 102.457764,131.086197
	C103.426414,131.355164 104.544098,132.646454 104.799057,133.661255
	C106.012344,138.490463 107.055435,143.370956 107.889000,148.279053
	C108.035896,149.144043 106.935455,150.251144 106.335281,151.198410
	C106.080093,151.601196 105.521622,151.817368 105.284279,152.225052
	C101.389793,158.914658 95.966492,159.868546 89.072296,157.104263
	C86.288307,155.988007 83.046806,156.012772 79.640762,155.278656
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M146.997253,174.747452
	C148.958054,181.785217 152.957825,185.942749 160.076263,187.197250
	C161.598740,187.465561 163.397003,190.081100 163.750900,191.875137
	C164.053726,193.410309 162.956528,196.041275 161.633255,196.932373
	C156.548248,200.356613 153.253143,204.829071 151.646210,210.999496
	C138.971863,210.999496 126.176369,211.078094 113.385704,210.865662
	C112.233879,210.846542 111.173279,208.870407 109.968330,207.934387
	C102.623352,202.228668 106.878151,196.154724 109.385849,190.156860
	C111.248299,185.702286 113.262352,182.380096 119.480263,182.614822
	C122.793625,182.739899 128.057571,179.647385 129.263046,176.705078
	C131.521378,171.192978 135.222809,171.746414 139.171982,172.102295
	C141.824585,172.341324 144.389664,173.551437 146.997253,174.747452
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M197.987213,141.998993
	C203.779694,144.417068 204.850266,148.693604 204.116440,154.158127
	C203.832794,156.270203 204.140244,159.212646 205.433823,160.635117
	C208.481628,163.986603 206.137344,165.911484 204.348160,168.144867
	C196.747910,177.631882 189.113480,187.091507 181.505875,196.543335
	C180.127945,196.049393 178.703079,195.335434 177.196838,195.047867
	C175.109161,194.649261 172.435120,195.922348 171.933609,192.205948
	C171.514893,189.103104 170.118637,186.722122 174.084045,184.071320
	C181.924240,178.830307 184.301254,164.934021 175.623184,158.363647
	C170.352692,154.373215 173.722122,150.412399 174.293655,146.426956
	C174.920578,142.055176 177.118134,139.178238 182.185165,138.844849
	C184.366470,138.701324 186.436600,136.868134 188.855881,135.652496
	C191.404190,137.488678 194.511658,139.727753 197.987213,141.998993
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M75.234543,162.983154
	C82.456352,164.324997 89.263390,165.565552 96.012939,167.064880
	C97.135742,167.314285 98.394997,168.500885 98.925827,169.581299
	C100.727783,173.248932 102.231400,177.063156 104.241264,181.732834
	C103.055717,184.590118 101.178146,188.629654 99.701584,192.810822
	C98.234329,196.965637 95.579147,197.787125 91.315666,198.358948
	C87.444969,198.878052 85.489868,199.246857 82.861252,195.637009
	C76.364281,186.714813 69.099373,178.351822 62.196789,169.830368
	C64.039680,166.892334 65.793724,164.095917 67.678261,161.091476
	C69.924255,161.683411 72.362381,162.325989 75.234543,162.983154
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M191.474701,97.816971
	C195.966980,99.202499 200.104980,100.420341 204.999542,101.860840
	C207.572083,113.069977 210.331635,125.093918 213.067673,137.015427
	C210.511841,139.993713 208.405563,139.766953 205.503387,137.289276
	C201.733658,134.070953 197.343689,131.579163 193.246918,128.792480
	C196.084259,116.757408 191.165527,108.264160 179.666626,105.296532
	C178.724548,105.053391 177.702438,104.969398 176.730148,105.026428
	C174.860748,105.136093 171.990173,106.243202 171.322937,105.443916
	C169.197769,102.898201 167.832367,99.697418 166.334930,96.669830
	C166.096405,96.187538 166.619614,95.263649 166.909912,94.595749
	C168.225693,91.568375 169.584290,88.559608 171.268997,84.777382
	C170.561356,85.210968 170.688614,85.095413 170.837662,85.047050
	C177.640915,82.840240 182.310089,84.189743 184.642990,91.855782
	C185.363861,94.224632 188.882690,95.742035 191.474701,97.816971
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M104.694862,60.730461
	C106.431236,59.760780 107.936012,59.141644 109.274460,58.267765
	C113.790848,55.318989 117.728989,55.108311 120.595146,60.484589
	C116.507523,70.004555 112.367943,79.645538 108.806488,87.940086
	C101.777992,89.745399 95.640999,91.321724 88.828362,93.071587
	C83.633896,88.514931 73.267616,85.494698 79.099007,72.959984
	C86.799446,69.307182 95.593246,65.135735 104.694862,60.730461
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M219.865295,80.370499
	C216.978683,88.246071 209.712631,93.576668 203.092117,92.998947
	C195.214188,92.311501 188.290863,85.539589 187.036301,77.294304
	C185.671219,68.322456 198.021118,57.678440 206.819321,60.331886
	C215.445679,62.933514 221.038162,68.304985 219.865295,80.370499
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M59.993641,156.440674
	C56.751511,164.262039 52.018097,169.438004 43.069275,169.042770
	C36.982700,168.773972 30.749063,164.245407 28.398546,157.900406
	C26.553442,152.919724 28.887827,144.531906 33.039230,140.990387
	C38.178894,136.605789 43.894947,135.217728 50.261578,137.789001
	C58.528717,141.127808 60.643284,147.858795 59.993641,156.440674
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M121.734612,78.675591
	C124.024017,73.330017 126.082413,68.306740 128.220627,63.088688
	C136.937393,64.666023 144.058197,61.957943 149.136017,55.301788
	C158.039307,59.509968 166.506088,63.728443 175.165314,67.505707
	C179.016937,69.185837 181.078232,71.234291 179.741013,75.525261
	C177.869644,76.006607 176.040665,76.678444 174.157333,76.912338
	C171.594193,77.230637 169.265747,77.267776 167.654663,74.261650
	C164.055420,67.545784 155.469559,64.351036 147.631699,67.208275
	C141.713165,69.365845 135.325165,74.788490 136.062271,83.934235
	C136.352310,87.532837 134.742584,89.401016 131.750137,90.708908
	C129.399338,91.736366 126.899956,92.612427 124.842743,94.082245
	C121.059807,96.785019 118.311569,96.093208 115.936890,92.063271
	C117.755135,87.795738 119.629372,83.396812 121.734612,78.675591
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M111.662964,100.171158
	C117.189751,104.846542 117.837273,111.515221 114.850838,116.442230
	C111.760345,121.540955 105.730087,123.826904 100.242241,122.044945
	C92.212036,119.437447 89.250298,113.633530 91.983437,105.860741
	C94.514442,98.662788 101.325684,95.474983 108.489479,98.203377
	C109.519539,98.595680 110.416412,99.337715 111.662964,100.171158
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M173.724518,227.999390
	C166.095825,227.814606 158.937317,221.520111 159.006149,215.425873
	C159.080124,208.876877 165.257919,202.547485 172.090195,202.020767
	C176.438431,201.685532 182.222000,206.705780 184.320602,211.686859
	C186.582642,217.055862 183.035324,223.528549 179.440445,225.795212
	C177.845505,226.800873 175.918884,227.280472 173.724518,227.999390
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M121.538574,35.978882
	C128.906418,27.346907 136.763794,29.125734 142.429764,34.761127
	C145.482819,37.797703 146.196457,45.385105 142.749176,50.629391
	C140.372375,54.245171 133.846802,56.242718 129.600937,55.034420
	C122.325966,52.964108 117.595329,47.421276 121.538574,35.978882
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M154.979523,167.977234
	C156.872330,163.099976 161.256699,160.214218 164.961609,161.020142
	C169.108002,161.922104 173.099731,166.720825 172.999405,170.682907
	C172.903946,174.452728 168.245880,179.370514 164.203110,179.969666
	C158.850647,180.762878 153.235321,173.720413 154.979523,167.977234
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M168.000031,122.092575
	C169.110229,115.919662 173.253311,112.265869 177.820755,113.016937
	C182.974060,113.864357 186.295425,118.083031 185.999802,123.405647
	C185.753738,127.835983 181.398682,132.267303 177.012039,131.924194
	C171.479828,131.491470 167.835052,127.942726 168.000031,122.092575
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M157.479614,91.839973
	C152.744156,94.434799 149.010101,92.480476 146.298187,89.443130
	C143.070038,85.827621 143.100876,81.162537 146.314209,77.536728
	C148.715317,74.827385 151.838913,73.130455 156.208252,74.843826
	C160.799225,76.644104 162.449753,80.475983 162.616119,84.187386
	C162.724808,86.612434 159.547195,89.184792 157.479614,91.839973
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M53.162445,81.473297
	C51.085693,76.804420 51.336033,72.775230 54.999161,69.556557
	C57.828541,67.070473 60.988853,66.233986 64.712952,68.212784
	C68.439171,70.192711 70.208130,72.873978 70.059563,77.165939
	C69.894859,81.924080 66.913193,84.583214 63.042412,85.198280
	C60.346176,85.626724 57.273808,83.769058 54.392147,82.860680
	C53.968781,82.727226 53.671272,82.194519 53.162445,81.473297
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M85.120102,212.641815
	C88.443443,206.882812 92.499359,204.768234 96.732559,206.246567
	C100.767365,207.655609 103.392853,212.060669 102.999298,216.761032
	C102.652603,220.901657 98.424904,224.809860 94.131020,224.959076
	C90.181580,225.096359 85.407852,220.982788 85.022110,217.021011
	C84.894005,215.705353 85.010849,214.365845 85.120102,212.641815
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M111.449417,158.203125
	C118.057739,155.616440 122.717094,157.825424 124.610489,164.086441
	C126.016563,168.736023 122.616058,174.030762 117.600304,175.001648
	C111.918892,176.101395 106.266449,172.356400 106.409607,166.518494
	C106.476959,163.771591 109.437447,161.095642 111.449417,158.203125
z"
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
M215.043442,145.998779
	C219.382278,142.924301 224.971420,143.317825 227.975525,146.836319
	C231.042801,150.428818 230.800644,155.598297 227.400375,159.114212
	C223.889908,162.744064 219.368378,163.126755 215.643372,160.109329
	C211.489731,156.744675 210.774750,152.079773 213.722641,147.688049
	C214.070755,147.169449 214.332260,146.592728 215.043442,145.998779
z"
        />
      </motion.svg>
    </>
  );
};

export { SeamlessSvg };
