import React from 'react'
import { Card, Flex, Image, Typography } from 'antd'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'


const { Text, Title } = Typography
const Empowercomponent = ({images}) => {
  return (
    <div>
        <Card className='template faqs'>
            <motion.div
                initial={{ y: "100%", opacity: 0 }}
                whileInView={{ y: "0%", opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 0.8,
                }}
            >
                <Flex vertical align='center' justify='center' gap={5}>
                <motion.div
                      initial={{
                        scale: 0.8,
                        opacity: 0.4,
                        rotateZ: 15,
                        x: -50,
                      }}
                      animate={{
                        scale: 1.1,
                        opacity: 1,
                        rotateZ: 0,
                        x: 0,
                        y: [0, -10, 0],
                      }}
                      transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "reverse",
                        repeatDelay: 0.5,
                      }}
                      whileHover={{
                        scale: 1.3,
                        rotateZ: 10,
                        transition: {
                          duration: 0.5,
                          ease: "easeOut",
                        },
                      }}
                      whileTap={{
                        scale: 0.95,
                        rotateZ: -5,
                      }}
                    >
                      <Image src={images} width={180} preview={false} />
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                        }}
                        transition={{ delay: .5, duration: .3 }}
                        viewport={{once: true}}
                    >
                        <Title className='text-white text-center'>Empower Your Financial <br /> Future with Mozeff</Title>
                    </motion.div>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Text className='text-gray fs-16'>Join Mozeff today and experience the future of secure and efficient Web3 financial solutions.</Text>
                    </motion.div>
                    <motion.Flex
                      initial={{ y: 10, opacity: 0 }}
                      whileInView={{
                          opacity: 1,
                          y: 0,
                      }}
                      transition={{  duration: .3, type:'spring', stiffness: 300 }}
                      viewport={{once: true}}
                    >
                      <Link to={'tel:0332020302'} className='btn w-auto mt-3'>
                          <div className='content vertical '>
                              <div className="text">Join us now</div>
                              <div className="hover-text">Join us now</div>
                          </div>
                      </Link>
                    </motion.Flex>
                </Flex>
            </motion.div>
            <div className='gradient-rad'></div>
            <motion.div
                style={{
                    backgroundImage: 'url(/assets/images/whitedots.png)',
                    opacity: 0.4,
                    inset: '-200%',
                    width: '400%',
                    height: '400%',
                    position: 'absolute',
                    backgroundRepeat:'repeat',
                    zIndex:-1,
                }}
                className='tv-animate'
                animate={{
                    x: ["0%", "-10%", "10%", "0%"],
                    y: ["0%", "-10%", "10%", "0%"],
                }}
                transition={{
                    duration: .3,
                    ease: "linear",
                    repeat: Infinity, 
                }}
            ></motion.div>
        </Card>
    </div>
  )
}

export {Empowercomponent}