import React from 'react'
import { Col,  Row } from 'antd'
import { Breadcrumb, HowEffect, PricingCards, VisaCardIntro, VisaCards } from '../../components'

const VisaCardPage = () => {
  return (
    <div >
        <Breadcrumb title='Visa Card' pageName='Visa Card' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <VisaCardIntro />
                </Col>
                <Col span={24}>
                    <HowEffect />
                </Col>
                <Col span={24}>
                    <VisaCards />
                </Col>
                <Col span={24}>
                    <PricingCards />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {VisaCardPage}