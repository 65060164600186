import React, { useState } from 'react'
import { Avatar, Button, Card, Col,  Flex, Row, Typography } from 'antd'
import { Breadcrumb, SearchFilter, Typewriter } from '../../components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography

const JobsPage = () => {

    const [ saveitem, setSaveItem ] = useState([''])

    const data = [
        {
            id:1,
            img:'av-4.jpg',
            companyname:'Amazon',
            duration:'5 days ago',
            jobtitle:'Senior Project Manager',
            salary:'124',
            location:'SanFranciso, CA',
            jobtype:[
                {
                    title:'Part time'
                },
                {
                    title:'Full time'
                },
            ]
        },
        {
            id: 2,
            img:'av-1.png',
            companyname:'Google',
            duration:'3 days ago',
            jobtitle:'Data analyst',
            salary:'150',
            location:'New York, NY',
            jobtype:[
                {
                    title:'Flexible schedule'
                },
                {
                    title:'Contract'
                },
            ]
        },
        {
            id: 3,
            img:'av-2.avif',
            companyname:'Facebook',
            duration:'3 days ago',
            jobtitle:'Data scientist',
            salary:'170',
            location:'New York, NY',
            jobtype:[
                {
                    title:'Remote'
                },
                {
                    title:'Contract'
                },
                {
                    title:'Flexible schedule'
                },
                {
                    title:'Part time'
                },
            ]
        },
    ]

    const toggleSaveItem = (id) => {
        setSaveItem((prev) =>
            prev.includes(id)
                ? prev.filter((itemId) => itemId !== id)
                : [...prev, id]
        )
    }

    const messages = [
        'Find job'
    ]
  return (
    <div >
        <Breadcrumb title='Jobs list' pageName='Jobs list' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,64]} justify={'center'}>
                <Col lg={{span: 20}} md={{span: 20}} sm={{span: 24}} xs={{span: 24}}>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        
                        <Title level={1} className='m-0 gradient-text text-center mt-3' >
                            <Typewriter messages={messages} speed={150} />
                        </Title>
                    </motion.div>
                    <SearchFilter placeholder={'Search jobs ...'} />
                </Col>
                {
                    data?.map((items,index)=>
                    <Col lg={{span: 8}} md={{span: 8}} sm={{span: 12}} xs={{span: 24}} key={index}>
                        <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}>
                            <Card className='bg-transparent border0 rounded shadow card-design h-100' 
                                actions={[
                                    <Flex justify='space-between' className='px-3' align='center' wrap>
                                        <Flex vertical align='flex-start'>
                                            <Text className='text-white' strong>
                                                ${items?.salary}/hr
                                            </Text>
                                            <Text className='text-gray fs-13'>
                                                {
                                                    items?.location
                                                }
                                            </Text>
                                        </Flex>
                                        <Flex>
                                            <Link to={'/jobform'} className='btn w-auto'>
                                                <div className='content vertical '>
                                                    <div className="text fs-13">Apply Now</div>
                                                    <div className="hover-text fs-13">Apply Now</div>
                                                </div>
                                            </Link>
                                        </Flex>
                                    </Flex>
                                ]}
                            >
                                <Flex justify='space-between' align='center'>
                                    <Avatar size={50} icon={<img src={'/assets/images/'+items?.img}/>} />
                                    <Button onClick={() => toggleSaveItem(items.id)} className="btn-filter card-pill">
                                        <Text className="text-white">{saveitem.includes(items.id) ? 'Saved' : 'Save'}</Text>
                                        <img
                                            src={
                                                saveitem.includes(items.id)
                                                    ? '/assets/icons/saved.png'
                                                    : '/assets/icons/unsaved.png'
                                            }
                                            width={16}
                                            alt="Save icon"
                                        />
                                    </Button>
                                </Flex>
                                <Flex vertical gap={10} className='py-3'>
                                    <Text className='text-white'>
                                        {items?.companyname} <span className='text-gray fs-13'>{items?.duration}</span>
                                    </Text>
                                    <Title level={3} className='m-0 text-white'>
                                        {
                                            items?.jobtitle
                                        }
                                    </Title>
                                    <Flex gap={4} className='over-flow-cs'>                                    {
                                            items?.jobtype?.map((jobstatus,i)=>
                                                <Button className='btn-filter card-pill fs-13 text-white' key={i}>
                                                    {jobstatus?.title}
                                                </Button>
                                            )
                                        }
                                    </Flex>
                                </Flex>
                            </Card>
                        </motion.div>         
                    </Col>
                    )
                } 
            </Row>
        </div>
    </div>
  )
}

export {JobsPage}