import React from 'react'
import { Breadcrumb, CryptoLineChart, CryptoPieChart, EarnCrypto, FlexCards, Partners, PricingCards,  ServicesBarChart,  UnderlineDesign } from '../../components'
import { Flex, Row, Space, Typography } from 'antd'
import { Col } from 'antd'
import { motion } from 'framer-motion'
const { Title, Text} = Typography 
const CryptoCurrencyPage = () => {
  
  return (
    <div>
        <Breadcrumb title='Crypto Currency' pageName='Crypto Currency' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container mt-4'>
          <Row gutter={[24, 24]} className='padding' align={'middle'}>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <Flex vertical justify='center' gap={30} >
                  <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{
                          opacity: 1,
                          y: 0,
                      }}
                      transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                      viewport={{once: true}}
                  >
                      
                      <Title level={1} className='m-0 gradient-text'>
                          Manage your transactions in one place
                      </Title>
                  </motion.div>
                  <Text className='text-gray text-justify fs-16'>You can buy and sell 150+ cryptocurrencies with 20+ fiat currencies using bank transfers or your credit or debit card. Enjoy secure transactions, competitive rates, and instant conversions, making it easy to trade and diversify your digital assets."</Text>
              </Flex>
            </Col>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <div className='w-100 center'>
                  <img src='/assets/images/crypto-1.png' height={300} className='rounded' preview={false} />
                </div>
            </Col>
            <Col span={24}>
              <FlexCards />
            </Col>
            <Col span={24}>
                <PricingCards />
            </Col>
            <Col span={24}>
              <Partners />
            </Col>
            <Col span={24}>
              <EarnCrypto />
            </Col>
            <Col span={24}>
              <CryptoPieChart />
            </Col>
            <Col span={24}>
              <CryptoLineChart />
            </Col>
            <Col span={24} >
              <Space size={15} direction='vertical' align='center' className='w-100 padding space-mbl' >
                  <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{
                          opacity: 1,
                          y: 0,
                      }}
                      transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                      viewport={{once: true}}
                  >
                      <Title level={2} className='gradient-text mb-1'>
                        Future of Payments with Cryptocurrency
                      </Title>
                      <Flex justify='center'>
                        <UnderlineDesign />
                      </Flex>
                  </motion.div>
              </Space>
              <ServicesBarChart />
            </Col>
          </Row>

        </div>
    </div>
  )
}

export {CryptoCurrencyPage}