import React, { useState, useEffect } from 'react';
import { Row, Col, Space, Typography } from 'antd';

const { Title } = Typography;

const MainBanner = () => {
  const [hoveredImage, setHoveredImage] = useState('/assets/images/r-1.png');
  const [dynamicText, setDynamicText] = useState('Welcome to Our Website!');
  const [showContent, setShowContent] = useState(false);

  const data = [
    {
      leftimag: [
        { url: '/assets/images/r-1.png', text: 'Image 1: Discover the future' },
        { url: '/assets/images/r-2.png', text: 'Image 2: Embrace the change' },
        { url: '/assets/images/r-3.png', text: 'Image 3: Explore new horizons' },
      ]
    },
    {
      rightimage: [
        { url: '/assets/images/r-4.png', text: 'Image 4: Innovation at its best' },
        { url: '/assets/images/r-5.png', text: 'Image 5: The journey begins' },
        { url: '/assets/images/r-6.png', text: 'Image 6: Your future awaits' },
      ]
    },
  ];

  const handleMouseOver = (imageUrl, text) => {
    setHoveredImage(imageUrl);
    setDynamicText(text);
    setShowContent(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 300);
    return () => clearTimeout(timer);
  }, [hoveredImage, dynamicText]);

  return (
    <div className='padding bg-banner'>
      <Row justify='center' align={'middle'}>
        <Col span={8}>
          <Space direction="vertical" size={10} className='left-images'>
            {data[0]?.leftimag?.map((image, index) => (
              <div key={`left-${index}`}>
                <img
                  onMouseOver={() => handleMouseOver(image.url, image.text)}
                  src={'/assets/images/arm-l-1.png'}
                  width={'auto'}
                  height={300}
                />
              </div>
            ))}
          </Space>
        </Col>
        <Col span={8}>
          <div className={`text-center slide-in-text ${showContent ? 'slide-in' : ''}`}>
            <img
              src={hoveredImage}
              width={400}
            />
            <Title
              level={3}
              className={`text-center m-0 text-white`}
            >
              {dynamicText}
            </Title>
          </div>
        </Col>
        <Col span={8}>
          <Space direction="vertical" size={10} className='right-images'>
            {data[1]?.rightimage.map((image, index) => (
              <img
                key={`right-${index}`}
                onMouseOver={() => handleMouseOver(image.url, image.text)}
                src={'/assets/images/arm-1.png'}
                width={'auto'}
                height={300}
              />
            ))}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export { MainBanner };
