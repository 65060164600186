import { PlusOutlined } from "@ant-design/icons"
import {Row, Col, Space, Button, Typography, Tooltip} from "antd"
export const ModuleTopHeading=({name, onClick, title, level})=>{
    return (
        <Row>
            <Col span={24}>
                <Space className="align-center">
                    <Typography.Title level={level} className="my-0 text-white">
                        {
                            name
                        }
                    </Typography.Title>
                    {
                        onClick ?
                        <Tooltip title={title}>
                            <Button 
                                type="primary" 
                                shape="circle" 
                                size='small' 
                                className="center bg-gradient border0"
                                icon={<PlusOutlined/>}
                                onClick={onClick}
                            />
                        </Tooltip>
                        :<></>
                    }
                </Space>
            </Col>
        </Row>
    )
}