import React from 'react'
import {  Card, Col,  Flex, Row, Typography } from 'antd'
import {  motion } from 'framer-motion';
import { Breadcrumb, Empowercomponent, Typewriter } from '../../components';
const { Text, Title } = Typography

const TermofUse = () => {
    const paragraph = "By using Mozeff, you agree to our terms that prioritize security, transparency, and user empowerment, ensuring a seamless experience on our platform.";
    const words = paragraph.split(" ");
    const data = [
        {
            title:'1. Acceptance of Terms',
            desc:`By accessing or using the services provided by Mozeff, you agree to be bound by these Terms of Use. These terms apply to all users, including visitors, registered users, and any other individuals or entities that interact with our platform. We reserve the right to modify these terms at any time, and any changes will be effective immediately upon posting. It is your responsibility to review the terms periodically to stay informed of any updates. Continued use of our services after any changes constitutes your acceptance of the revised terms. By accessing or using the services provided by Mozeff, you agree to be bound by these Terms of Use. These terms apply to all users, including visitors, registered users, and any other individuals or entities that interact with our platform. We reserve the right to modify these terms at any time, and any changes will be effective immediately upon posting. It is your responsibility to review the terms periodically to stay informed of any updates. Continued use of our services after any changes constitutes your acceptance of the revised terms. By accessing or using the services provided by Mozeff, you agree to be bound by these Terms of Use. These terms apply to all users, including visitors, registered users, and any other individuals or entities that interact with our platform. We reserve the right to modify these terms at any time, and any changes will be effective immediately upon posting. It is your responsibility to review the terms periodically to stay informed of any updates. Continued use of our services after any changes constitutes your acceptance of the revised terms.`,
        },
        {
            title:'2. User Responsibilities',
            desc:`As a user of Mozeff, you are expected to use our services responsibly and in compliance with all applicable laws and regulations. You agree not to engage in any activities that could harm our platform, other users, or any third parties. This includes, but is not limited to, unauthorized access, interference with the functionality of our services, and the transmission of malicious software. You are also responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. By accessing or using the services provided by Mozeff, you agree to be bound by these Terms of Use. These terms apply to all users, including visitors, registered users, and any other individuals or entities that interact with our platform. We reserve the right to modify these terms at any time, and any changes will be effective immediately upon posting. It is your responsibility to review the terms periodically to stay informed of any updates. Continued use of our services after any changes constitutes your acceptance of the revised terms.`,
        },
        {
            title:'3. Intellectual Property Rights',
            desc:`All content, features, and functionalities available on the Mozeff platform, including but not limited to text, graphics, logos, and software, are the property of Mozeff or its licensors and are protected by intellectual property laws.`,
        },
        {
            title:'4. Limitation of Liability',
            desc:`Mozeff is committed to providing reliable and secure services, but we cannot guarantee that our platform will always be available or free from errors. To the fullest extent permitted by law, Mozeff disclaims all warranties, express or implied, regarding the performance, reliability, and security of our services. We shall not be liable for any indirect, incidental, or consequential damages arising from your use of our platform or any interruptions in service. Your sole remedy for dissatisfaction with our services is to discontinue use of the platform`,
        },
        {
            title:'5. Governing Law and Dispute Resolution',
            desc:`These Terms of Use and any disputes arising out of or related to them shall be governed by the laws of the jurisdiction in which Mozeff operates, without regard to its conflict of law principles. Any legal action or proceeding arising under these terms shall be resolved through binding arbitration in accordance with the rules of the relevant arbitration body. By agreeing to these terms, you waive your right to a trial by jury or to participate in a class action lawsuit. These Terms of Use and any disputes arising out of or related to them shall be governed by the laws of the jurisdiction in which Mozeff operates, without regard to its conflict of law principles. Any legal action or proceeding arising under these terms shall be resolved through binding arbitration in accordance with the rules of the relevant arbitration body. By agreeing to these terms, you waive your right to a trial by jury or to participate in a class action lawsuit.`,
        },
    ]

    const messages = [
        'Terms of Use'
    ]
  return (
    <div className=''>
        <Breadcrumb title='Term of Use' pageName='Term of Use' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'}>
                <Col lg={{span: 18}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                        <Flex vertical justify='center' align='center' className='text-center my-5 mb-3'>
                        <motion.div
                            initial={{ opacity: 0}}
                            whileInView={{
                                opacity: 1
                            }}
                            transition={{ duration: .3, ease: 'easeInOut' }}
                            viewport={{once: true}}
                        >
                            <Title level={1} className='gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <div className='w-100 mb-2'>
                            {words.map((word, index) => (
                                <motion.span
                                    key={index}
                                    style={{ padding: "0 2px", fontSize: "20px", display: 'inline-block'  }}
                                    className='text-gray'
                                    initial={{ opacity: 0, y: 10 }} 
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                    delay: index * 0.09,
                                    duration: 0.3, 
                                    ease: "easeInOut", 
                                    }}
                                    viewport={{once: true}}
                                >
                                    {word}
                                </motion.span>
                            ))}
                        </div>
                    </Flex>              
                </Col>
                <Col span={24}>
                        {data.map((content, index)=>(
                            <div key={index} className='mb-4'>
                                <Title level={2} className='text-white'>
                                    {content?.title}
                                </Title>
                                <Text className='fs-14 text-white text-justify'>{content?.desc}</Text>
                            </div>
                        ))}
                </Col>
            </Row>
            <Empowercomponent images={'/assets/images/r-4.png'} />
        </div>
    </div>
  )
}

export {TermofUse}