import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Text, Title } = Typography
const GatewayEffect = () => {


    const data = [
        'License',
        'White-Label',
        'Global',
    ]

  return (
    <Row gutter={[24,64]} className='padding' align={'middle'}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Space size={10} direction='vertical'>
                <Title className='gradient-text m-0 mb-1'>How will the service effect the business</Title>
                <Text className='text-gray text-justify'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo ea ipsam non, magni sunt voluptate reiciendis aliquid omnis aperiam consectetur praesentium. Fugit sint consequatur dolor earum odit voluptas itaque a?
                </Text>
                <Flex vertical className='mt-2'>
                    {data.map((item)=> (
                        <Space size={12} align='start' className='mb-1'>
                            <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                            <Title level={5} className='gradient-text m-0'>{item}</Title>
                        </Space>
                    ))}
                </Flex>
                <Link to={'/contact'} className='btn w-auto mt-3'>
                    <div className='content vertical '>
                        <div className="text">Contact us</div>
                        <div className="hover-text">Contact us</div>
                    </div>
                </Link>
            </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Flex justify='end'>
                <Image src='/assets/images/gt-6.png' width={'90%'} className='rounded-1' preview={false} />
            </Flex>
        </Col>
    </Row>
  )
}

export {GatewayEffect}