import { Button, Image, Typography } from 'antd'
import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css'; 
import { blogData } from '../../../data';
const { Title, Text } = Typography;
const FeatureBlog = () => {

  const [slickSettings, setSlickSettings] = useState({
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => {
      if (next === 0 || next === blogData.length - 1) {
        setSlickSettings((prev) => ({
          ...prev,
          swipeToSlide: false, 
        }));
      } else {
        setSlickSettings((prev) => ({
          ...prev,
          swipeToSlide: true, 
        }));
      }
    },
    afterChange: (index) => {
      setSlickSettings((prev) => ({
        ...prev,
        swipeToSlide: index < blogData.length - 1,
      }));
    },
  });
  return (
    <div className='container-swiper'>
      <Slider {...slickSettings}>
      {blogData.map((item, index) => (
        <div className='feature-blog' key={index}>
          <Image className='img-slider' src={item.image} alt={item.title} preview={false}  height="270px" width="100%" />
          <Text className='featured-cat'>{item.category}</Text>
          <Title className='description-slider text-white' level={5}>{item.title}</Title>
          <Text className='text-white gradient-txt btn-slider'>Read More</Text>
        </div>
      ))}
      </Slider>
    </div>
  )
}

export {FeatureBlog}