export const careerData = [
  {
    job_id: 1,
    type: "Developer and Web 3.0",
    availjob: 1,
    title: "Blockchain and Smart Contract Developers",
    description: "Responsible for designing, developing, and deploying blockchain-based solutions. Tasks include writing smart contracts using Solidity or Rust, implementing token standards (ERC-20, ERC-721), and ensuring secure and efficient decentralized applications (dApps) on platforms like Ethereum or Solana. Expertise in blockchain frameworks, security audits, and scalable architecture is essential.",
    location: "Ukrine",
    time: "Full time",
  },
  {
    job_id: 2,
    type: "Customer Supprt Department",
    availjob: 1,
    title: "Customer Support Executive",
    description: "CometChat is an all-in-one communication platform that enables businesses to easily integrate customized in-app messaging functionalities across text, voice, and video. This eliminates the need for businesses to start building a messaging module from scratch, and enables their end-users to communicate with each other, resulting in a unified customer experience, increased engagement and retention, and revenue growth.",
    location: "Remote",
    time: "Part time",
  },
]
export const singlejob = [
  {
    job_id: 1,
    about: "At Mozedff, we are at the forefront of the Web 3.0 revolution, building innovative blockchain solutions that drive the future of decentralization. We are looking for passionate Blockchain and Smart Contract Developers to join our team and create impactful decentralized applications (dApps) that transform industries.",
    res: [
      'Develop secure and efficient smart contracts using Solidity or Rust.',
      'Build and optimize dApps on platforms like Ethereum, Solana, or Polygon.',
      'Implement token standards (ERC-20, ERC-721, etc.) and tokenomics.',
      'Conduct security audits and resolve vulnerabilities in smart contracts.',
      'Collaborate with teams to integrate blockchain features.'
    ],
    req: [
      'Proficiency in Solidity, Rust, or similar languages.',
      'Experience with blockchain platforms (Ethereum, Solana, etc.).',
      'Familiarity with tools like Hardhat, Truffle, Web3.js, or Ethers.js.',
      'Strong problem-solving skills and attention to detail.',
    ],
    offer: [
      'Competitive salary and bonuses.',
      'Flexible, remote-friendly work environment.',
      'Opportunities for growth in the Web 3.0 domain.',
    ],
    join: 'Be a part of a team shaping the future of decentralization. Apply today and let’s innovate together!'
  },
  {
    job_id: 2,
    about: "At Mozedff, we are at the forefront of the Web 3.0 revolution, building innovative blockchain solutions that drive the future of decentralization. We are looking for passionate Blockchain and Smart Contract Developers to join our team and create impactful decentralized applications (dApps) that transform industries.",
    res: [
      'Develop secure and efficient smart contracts using Solidity or Rust.',
      'Build and optimize dApps on platforms like Ethereum, Solana, or Polygon.',
      'Implement token standards (ERC-20, ERC-721, etc.) and tokenomics.',
      'Conduct security audits and resolve vulnerabilities in smart contracts.',
      'Collaborate with teams to integrate blockchain features.'
    ],
    req: [
      'Proficiency in Solidity, Rust, or similar languages.',
      'Experience with blockchain platforms (Ethereum, Solana, etc.).',
      'Familiarity with tools like Hardhat, Truffle, Web3.js, or Ethers.js.',
      'Strong problem-solving skills and attention to detail.',
    ],
    offer: [
      'Competitive salary and bonuses.',
      'Flexible, remote-friendly work environment.',
      'Opportunities for growth in the Web 3.0 domain.',
    ],
    join: "Join us in delivering exceptional customer experiences and supporting the future of Web 3.0!"
  }
]