import React from "react";
import { motion } from "framer-motion";

const InnovativeSvg = () => {
  return (
    <>
      <motion.svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 256 256"
        // enable-background="new 0 0 256 256"
        // xml:space="preserve"
      >
        <motion.path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M257.000000,137.000000
	C257.000000,176.924438 257.000000,216.848877 257.000000,257.000000
	C219.979416,257.000000 182.958359,257.000000 145.165009,256.690613
	C147.208374,249.395584 150.274460,242.493713 152.659210,235.363937
	C153.236862,233.636902 152.195541,230.109299 150.786469,229.264267
	C148.526566,227.908981 146.269791,229.434265 145.227417,232.305664
	C143.066223,238.259094 140.674744,244.128906 138.454636,249.835938
	C131.808090,249.835938 125.501427,249.835938 119.494415,249.835938
	C117.775848,245.412262 115.689980,241.340210 114.636490,237.016617
	C113.184891,231.059204 109.643669,228.443527 103.830132,227.275375
	C92.851128,225.069275 82.994141,220.030853 73.507637,214.173264
	C72.080826,213.292252 69.641380,212.962280 68.106529,213.538742
	C62.286781,215.724503 56.646358,218.387726 51.022724,220.835892
	C46.398945,216.229813 41.735237,211.583969 37.024624,206.891388
	C39.248734,202.176682 41.490425,197.398865 43.755260,192.632019
	C45.494217,188.971985 44.748806,185.918289 42.888210,182.218384
	C38.450165,173.393005 34.749100,164.183060 31.038960,155.011703
	C29.279646,150.662735 29.093872,145.604324 23.270594,143.958374
	C18.095501,142.495621 13.099187,140.400375 8.129545,138.622101
	C8.129545,131.980453 8.129545,125.672127 8.129545,119.493835
	C12.459764,117.834709 16.534651,115.857498 20.832283,114.727196
	C26.823481,113.151459 29.731533,109.506523 30.866404,103.559258
	C32.941010,92.687332 38.072281,83.061874 43.698006,73.645264
	C44.611713,72.115845 44.859798,69.538681 44.252750,67.868423
	C42.133789,62.038212 39.504784,56.393375 37.195168,50.972794
	C41.800098,46.259350 46.314941,41.638115 50.847424,36.998829
	C55.513443,39.120415 60.213135,41.145161 64.809448,43.382305
	C68.557724,45.206680 71.841782,45.410072 75.591751,42.894524
	C84.041748,37.226120 93.243095,33.173016 103.252426,30.855701
	C107.379921,29.900124 112.159912,29.482658 113.724472,23.868965
	C115.215981,18.517414 117.492828,13.384742 119.445511,8.098742
	C125.844444,8.098742 132.152817,8.098742 138.320084,8.098742
	C140.910522,14.429654 143.269775,20.710995 146.131531,26.754374
	C146.703506,27.962267 149.181137,28.725542 150.802948,28.765512
	C151.505356,28.782824 152.940079,26.398678 152.855728,25.188009
	C152.693359,22.857679 151.776535,20.555649 151.011780,18.296310
	C149.054916,12.514947 147.009827,6.763441 145.000000,0.999999
	C182.262802,1.000000 219.525620,1.000000 257.000000,1.000000
	C257.000000,40.687309 257.000000,80.375000 256.707642,120.818405
	C255.926804,120.967667 255.437332,120.361961 254.949936,119.754616
	C250.765686,114.540909 245.867340,111.860413 238.679123,112.818314
	C230.934433,113.850365 227.236008,118.678009 224.664383,124.642166
	C193.877121,124.642166 163.473190,124.642166 133.362457,124.642166
	C133.362457,119.534760 133.362457,114.790939 133.362457,110.000000
	C139.555511,110.000000 145.486267,109.311813 151.196701,110.140953
	C163.703705,111.956963 173.491913,108.238022 180.691406,97.820396
	C183.137650,94.280678 186.476196,93.172348 190.595947,94.233536
	C195.637024,104.384239 202.283966,108.898628 210.102158,106.882744
	C218.047806,104.833984 223.468430,98.766304 223.944855,90.694412
	C224.387207,83.199959 218.776199,76.049637 211.471893,74.169083
	C202.729584,71.918304 195.535233,75.921822 190.553589,85.809700
	C181.170700,86.332664 181.173019,86.334984 174.386322,93.112793
	C172.030579,95.465477 169.717682,97.866158 167.266327,100.115349
	C166.371368,100.936501 165.138321,101.902763 164.047745,101.917252
	C153.780182,102.053665 143.510086,101.998314 133.273682,101.998314
	C133.273682,97.048904 133.273682,92.642433 133.273682,88.000000
	C141.518967,88.000000 149.512314,88.317825 157.456940,87.834732
	C160.402100,87.655647 163.765182,86.212082 166.009247,84.271545
	C171.288956,79.705933 175.669128,74.081429 181.041306,69.646034
	C187.992264,63.907192 191.653702,57.258965 190.033829,48.150688
	C189.985382,47.878338 190.217361,47.556114 190.322601,47.258495
	C201.823929,42.598145 203.144684,33.888885 201.753418,26.679867
	C200.405411,19.694984 192.090546,13.774405 185.341965,14.012136
	C177.460297,14.289780 170.037552,20.707184 169.056763,28.491362
	C168.186646,35.397293 171.222351,44.365967 181.674881,47.519535
	C183.255981,53.674706 181.115601,58.195805 176.386978,62.290829
	C170.860626,67.076683 165.984589,72.608925 160.741241,77.730156
	C159.752808,78.695564 158.380219,79.867729 157.159256,79.897636
	C149.217957,80.092133 141.269562,79.997490 132.999954,79.997490
	C132.999954,71.746735 132.731247,63.937450 133.112366,56.160011
	C133.305374,52.221275 130.673447,51.295078 128.346542,51.403446
	C114.709496,52.038521 101.449615,54.505428 89.715263,62.046093
	C63.839649,78.674133 50.434948,101.830215 51.872593,133.197205
	C52.474506,146.329926 55.873955,158.297318 62.788986,169.332718
	C71.464806,183.178070 82.974976,193.664749 98.221558,200.135178
	C107.520782,204.081604 116.944054,206.091171 127.024330,206.202698
	C131.338989,206.250443 133.230667,205.591553 133.070206,200.919724
	C132.809738,193.336639 133.000000,185.738083 133.000000,178.102249
	C141.946426,178.102249 150.964691,176.108505 158.361664,178.715317
	C164.595261,180.912140 168.945358,188.453598 174.129089,193.629379
	C174.836624,194.335861 175.459549,195.161819 176.264359,195.730499
	C181.417221,199.371429 183.268417,204.235077 181.668915,210.423218
	C170.978394,213.910538 168.345184,221.740326 169.089340,229.417435
	C169.864731,237.416809 177.200104,243.510605 185.735580,243.950470
	C191.961594,244.271332 199.861252,238.144806 201.854492,231.449600
	C204.303696,223.222794 200.005859,215.529633 190.000000,210.444458
	C190.000000,208.869293 189.799118,207.179184 190.036194,205.552933
	C190.879318,199.769531 188.622177,195.574036 184.355179,191.698029
	C177.949051,185.878952 172.222260,179.311539 165.809357,173.500717
	C163.738495,171.624298 160.461868,170.307495 157.659637,170.155289
	C149.569656,169.715866 141.440369,170.000000 133.231476,170.000000
	C133.231476,165.135773 133.231476,160.730713 133.231476,156.000000
	C142.255295,156.000000 151.064972,156.288788 159.843002,155.886765
	C164.751740,155.661942 168.353165,156.988602 171.395233,160.825592
	C175.712189,166.270676 180.586563,170.791992 188.527817,169.124908
	C189.399094,168.942001 190.862854,170.290451 191.647583,171.256851
	C193.245529,173.224701 194.317474,175.655563 196.051498,177.470062
	C200.686127,182.319809 207.568237,183.372803 213.980453,180.541458
	C219.590927,178.064148 224.538391,171.029800 223.907700,165.273972
	C223.124207,158.123474 220.271652,152.554337 213.244995,150.019501
	C205.429565,147.200104 195.549744,148.968323 190.640701,160.551102
	C184.776459,162.554016 180.480209,160.149063 177.261642,155.688263
	C172.515106,149.109802 166.349075,147.173737 158.385056,147.842728
	C150.020554,148.545395 141.551193,148.000000 133.327530,148.000000
	C133.327530,142.608871 133.327530,137.868637 133.327530,133.090866
	C163.967346,133.090866 194.279556,133.090866 224.565811,133.090866
	C228.483047,146.844543 247.761200,150.939331 255.882294,136.815994
	C255.948364,136.701080 256.614227,136.931061 257.000000,137.000000
z"
        />
        <motion.path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          initial={{
            width: 0,
            opacity: 0,
          }}
          animate={{
            width: "100%",
            opacity: 1,
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
          d="
M257.000000,136.533142
	C256.614227,136.931061 255.948364,136.701080 255.882294,136.815994
	C247.761200,150.939331 228.483047,146.844543 224.565811,133.090866
	C194.279556,133.090866 163.967346,133.090866 133.327530,133.090866
	C133.327530,137.868637 133.327530,142.608871 133.327530,148.000000
	C141.551193,148.000000 150.020554,148.545395 158.385056,147.842728
	C166.349075,147.173737 172.515106,149.109802 177.261642,155.688263
	C180.480209,160.149063 184.776459,162.554016 190.640701,160.551102
	C195.549744,148.968323 205.429565,147.200104 213.244995,150.019501
	C220.271652,152.554337 223.124207,158.123474 223.907700,165.273972
	C224.538391,171.029800 219.590927,178.064148 213.980453,180.541458
	C207.568237,183.372803 200.686127,182.319809 196.051498,177.470062
	C194.317474,175.655563 193.245529,173.224701 191.647583,171.256851
	C190.862854,170.290451 189.399094,168.942001 188.527817,169.124908
	C180.586563,170.791992 175.712189,166.270676 171.395233,160.825592
	C168.353165,156.988602 164.751740,155.661942 159.843002,155.886765
	C151.064972,156.288788 142.255295,156.000000 133.231476,156.000000
	C133.231476,160.730713 133.231476,165.135773 133.231476,170.000000
	C141.440369,170.000000 149.569656,169.715866 157.659637,170.155289
	C160.461868,170.307495 163.738495,171.624298 165.809357,173.500717
	C172.222260,179.311539 177.949051,185.878952 184.355179,191.698029
	C188.622177,195.574036 190.879318,199.769531 190.036194,205.552933
	C189.799118,207.179184 190.000000,208.869293 190.000000,210.444458
	C200.005859,215.529633 204.303696,223.222794 201.854492,231.449600
	C199.861252,238.144806 191.961594,244.271332 185.735580,243.950470
	C177.200104,243.510605 169.864731,237.416809 169.089340,229.417435
	C168.345184,221.740326 170.978394,213.910538 181.668915,210.423218
	C183.268417,204.235077 181.417221,199.371429 176.264359,195.730499
	C175.459549,195.161819 174.836624,194.335861 174.129089,193.629379
	C168.945358,188.453598 164.595261,180.912140 158.361664,178.715317
	C150.964691,176.108505 141.946426,178.102249 133.000000,178.102249
	C133.000000,185.738083 132.809738,193.336639 133.070206,200.919724
	C133.230667,205.591553 131.338989,206.250443 127.024330,206.202698
	C116.944054,206.091171 107.520782,204.081604 98.221558,200.135178
	C82.974976,193.664749 71.464806,183.178070 62.788986,169.332718
	C55.873955,158.297318 52.474506,146.329926 51.872593,133.197205
	C50.434948,101.830215 63.839649,78.674133 89.715263,62.046093
	C101.449615,54.505428 114.709496,52.038521 128.346542,51.403446
	C130.673447,51.295078 133.305374,52.221275 133.112366,56.160011
	C132.731247,63.937450 132.999954,71.746735 132.999954,79.997490
	C141.269562,79.997490 149.217957,80.092133 157.159256,79.897636
	C158.380219,79.867729 159.752808,78.695564 160.741241,77.730156
	C165.984589,72.608925 170.860626,67.076683 176.386978,62.290829
	C181.115601,58.195805 183.255981,53.674706 181.674881,47.519535
	C171.222351,44.365967 168.186646,35.397293 169.056763,28.491362
	C170.037552,20.707184 177.460297,14.289780 185.341965,14.012136
	C192.090546,13.774405 200.405411,19.694984 201.753418,26.679867
	C203.144684,33.888885 201.823929,42.598145 190.322601,47.258495
	C190.217361,47.556114 189.985382,47.878338 190.033829,48.150688
	C191.653702,57.258965 187.992264,63.907192 181.041306,69.646034
	C175.669128,74.081429 171.288956,79.705933 166.009247,84.271545
	C163.765182,86.212082 160.402100,87.655647 157.456940,87.834732
	C149.512314,88.317825 141.518967,88.000000 133.273682,88.000000
	C133.273682,92.642433 133.273682,97.048904 133.273682,101.998314
	C143.510086,101.998314 153.780182,102.053665 164.047745,101.917252
	C165.138321,101.902763 166.371368,100.936501 167.266327,100.115349
	C169.717682,97.866158 172.030579,95.465477 174.386322,93.112793
	C181.173019,86.334984 181.170700,86.332664 190.553589,85.809700
	C195.535233,75.921822 202.729584,71.918304 211.471893,74.169083
	C218.776199,76.049637 224.387207,83.199959 223.944855,90.694412
	C223.468430,98.766304 218.047806,104.833984 210.102158,106.882744
	C202.283966,108.898628 195.637024,104.384239 190.595947,94.233536
	C186.476196,93.172348 183.137650,94.280678 180.691406,97.820396
	C173.491913,108.238022 163.703705,111.956963 151.196701,110.140953
	C145.486267,109.311813 139.555511,110.000000 133.362457,110.000000
	C133.362457,114.790939 133.362457,119.534760 133.362457,124.642166
	C163.473190,124.642166 193.877121,124.642166 224.664383,124.642166
	C227.236008,118.678009 230.934433,113.850365 238.679123,112.818314
	C245.867340,111.860413 250.765686,114.540909 254.949936,119.754616
	C255.437332,120.361961 255.926804,120.967667 256.707642,121.287064
	C257.000000,126.022095 257.000000,131.044189 257.000000,136.533142
M102.507858,193.048401
	C109.630890,196.026154 116.835014,198.591415 124.779236,197.876587
	C124.779236,151.720657 124.779236,106.015945 124.779236,60.020607
	C122.436012,60.020607 120.223610,59.632721 118.196602,60.081741
	C101.409355,63.800468 86.666077,70.997688 75.638046,84.802612
	C60.590031,103.639778 55.745033,124.635941 62.089317,147.909332
	C65.329887,159.797028 71.462708,170.450714 80.533943,178.676331
	C86.780502,184.340607 94.601562,188.268539 102.507858,193.048401
M177.268799,28.197144
	C176.634171,35.818111 181.251190,41.130066 187.040009,39.500244
	C191.479980,38.250187 194.061707,35.491722 193.962875,30.790699
	C193.869217,26.335085 191.380768,23.497377 187.083557,22.609224
	C182.738449,21.711164 179.754684,23.809765 177.268799,28.197144
M192.072693,221.612411
	C191.487030,221.022568 190.997894,220.258453 190.299728,219.871719
	C186.353699,217.685928 183.386353,217.224014 179.515213,221.012436
	C176.274887,224.183502 176.087357,227.950348 178.317780,231.705750
	C180.209122,234.890213 185.549332,236.382690 188.908401,234.982437
	C193.588455,233.031540 195.092468,227.897034 192.072693,221.612411
M211.135269,172.986526
	C212.703827,170.154251 215.569382,167.315842 215.556030,164.491043
	C215.532257,159.465759 209.608093,155.909668 204.947906,157.026108
	C201.131241,157.940460 197.331863,163.277252 198.185272,166.525162
	C199.758987,172.514359 204.330566,175.001617 211.135269,172.986526
M237.039612,121.444473
	C232.248901,124.367783 231.054977,128.392563 233.578506,133.112076
	C235.756287,137.184998 240.558395,138.829193 244.467026,136.840210
	C248.031525,135.026352 250.442093,129.124512 248.972549,125.809303
	C247.181900,121.769699 241.796432,119.483856 237.039612,121.444473
M215.964249,89.676361
	C215.649231,89.114128 215.245850,88.583298 215.033142,87.984711
	C213.325516,83.179192 208.672028,80.692131 204.245255,82.254181
	C199.865555,83.799614 197.496964,88.656235 199.018753,92.970711
	C200.708923,97.762611 205.419571,100.289742 210.030533,97.807976
	C212.593826,96.428337 214.043625,92.979927 215.964249,89.676361
z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,113.000000
	C1.000000,75.739716 1.000000,38.479435 1.000000,1.000000
	C38.020607,1.000000 75.041641,1.000000 112.835007,1.309381
	C111.731636,6.397012 109.883560,11.186350 107.971306,15.949924
	C106.499878,19.615337 104.712776,21.976530 100.095490,23.343964
	C90.793297,26.098848 82.082336,30.805511 73.004967,34.397381
	C70.893883,35.232727 68.050430,35.546822 65.965607,34.846149
	C61.424427,33.319935 57.249531,30.717291 52.725147,29.120731
	C51.026134,28.521183 48.105465,28.759071 46.885971,29.870565
	C40.987606,35.246574 35.330391,40.914627 29.952570,46.811890
	C28.833080,48.039509 28.528606,50.928570 29.111816,52.615101
	C30.732006,57.300396 33.203365,61.686047 34.944530,66.337540
	C35.601273,68.092026 35.939384,70.671455 35.120834,72.157898
	C29.836639,81.753723 24.869257,91.422913 22.870928,102.343925
	C22.645470,103.576073 22.236422,105.478012 21.423418,105.788429
	C14.680760,108.362907 7.822972,110.635864 1.000000,113.000000
z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M113.000000,257.000000
	C75.739716,257.000000 38.479424,257.000000 1.000000,257.000000
	C1.000000,219.979401 1.000000,182.958359 1.309392,145.165009
	C6.397047,146.268387 11.186470,148.116287 15.949954,150.028809
	C19.614986,151.500305 21.978222,153.284851 23.344231,157.904480
	C26.093281,167.201309 30.795107,175.907562 34.383995,184.979019
	C35.219368,187.090561 35.547081,189.931198 34.848068,192.013229
	C33.323307,196.554764 31.180319,200.926910 28.914671,205.167770
	C27.475056,207.862442 27.941998,209.586548 30.044621,211.604721
	C35.687740,217.021225 41.015453,222.774261 46.797703,228.031494
	C48.029850,229.151794 50.910992,229.470871 52.593853,228.889496
	C57.279472,227.270828 61.638092,224.703018 66.327072,223.097839
	C68.269478,222.432892 70.924744,222.741486 72.911736,223.503998
	C82.461411,227.168640 91.888748,231.151413 101.372307,234.989655
	C102.849426,235.587479 105.367622,235.635208 105.748405,236.594589
	C108.409615,243.299545 110.635048,250.177460 113.000000,257.000000
z"
        />
        <path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M113.468658,257.000000
	C110.635048,250.177460 108.409615,243.299545 105.748405,236.594589
	C105.367622,235.635208 102.849426,235.587479 101.372307,234.989655
	C91.888748,231.151413 82.461411,227.168640 72.911736,223.503998
	C70.924744,222.741486 68.269478,222.432892 66.327072,223.097839
	C61.638092,224.703018 57.279472,227.270828 52.593853,228.889496
	C50.910992,229.470871 48.029850,229.151794 46.797703,228.031494
	C41.015453,222.774261 35.687740,217.021225 30.044621,211.604721
	C27.941998,209.586548 27.475056,207.862442 28.914671,205.167770
	C31.180319,200.926910 33.323307,196.554764 34.848068,192.013229
	C35.547081,189.931198 35.219368,187.090561 34.383995,184.979019
	C30.795107,175.907562 26.093281,167.201309 23.344231,157.904480
	C21.978222,153.284851 19.614986,151.500305 15.949954,150.028809
	C11.186470,148.116287 6.397047,146.268387 1.309392,144.696350
	C1.000000,134.645767 1.000000,124.291542 1.000000,113.468658
	C7.822972,110.635864 14.680760,108.362907 21.423418,105.788429
	C22.236422,105.478012 22.645470,103.576073 22.870928,102.343925
	C24.869257,91.422913 29.836639,81.753723 35.120834,72.157898
	C35.939384,70.671455 35.601273,68.092026 34.944530,66.337540
	C33.203365,61.686047 30.732006,57.300396 29.111816,52.615101
	C28.528606,50.928570 28.833080,48.039509 29.952570,46.811890
	C35.330391,40.914627 40.987606,35.246574 46.885971,29.870565
	C48.105465,28.759071 51.026134,28.521183 52.725147,29.120731
	C57.249531,30.717291 61.424427,33.319935 65.965607,34.846149
	C68.050430,35.546822 70.893883,35.232727 73.004967,34.397381
	C82.082336,30.805511 90.793297,26.098848 100.095490,23.343964
	C104.712776,21.976530 106.499878,19.615337 107.971306,15.949924
	C109.883560,11.186350 111.731636,6.397012 113.303665,1.309381
	C123.354225,1.000000 133.708450,1.000000 144.531342,0.999999
	C147.009827,6.763441 149.054916,12.514947 151.011780,18.296310
	C151.776535,20.555649 152.693359,22.857679 152.855728,25.188009
	C152.940079,26.398678 151.505356,28.782824 150.802948,28.765512
	C149.181137,28.725542 146.703506,27.962267 146.131531,26.754374
	C143.269775,20.710995 140.910522,14.429654 138.320084,8.098742
	C132.152817,8.098742 125.844444,8.098742 119.445511,8.098742
	C117.492828,13.384742 115.215981,18.517414 113.724472,23.868965
	C112.159912,29.482658 107.379921,29.900124 103.252426,30.855701
	C93.243095,33.173016 84.041748,37.226120 75.591751,42.894524
	C71.841782,45.410072 68.557724,45.206680 64.809448,43.382305
	C60.213135,41.145161 55.513443,39.120415 50.847424,36.998829
	C46.314941,41.638115 41.800098,46.259350 37.195168,50.972794
	C39.504784,56.393375 42.133789,62.038212 44.252750,67.868423
	C44.859798,69.538681 44.611713,72.115845 43.698006,73.645264
	C38.072281,83.061874 32.941010,92.687332 30.866404,103.559258
	C29.731533,109.506523 26.823481,113.151459 20.832283,114.727196
	C16.534651,115.857498 12.459764,117.834709 8.129545,119.493835
	C8.129545,125.672127 8.129545,131.980453 8.129545,138.622101
	C13.099187,140.400375 18.095501,142.495621 23.270594,143.958374
	C29.093872,145.604324 29.279646,150.662735 31.038960,155.011703
	C34.749100,164.183060 38.450165,173.393005 42.888210,182.218384
	C44.748806,185.918289 45.494217,188.971985 43.755260,192.632019
	C41.490425,197.398865 39.248734,202.176682 37.024624,206.891388
	C41.735237,211.583969 46.398945,216.229813 51.022724,220.835892
	C56.646358,218.387726 62.286781,215.724503 68.106529,213.538742
	C69.641380,212.962280 72.080826,213.292252 73.507637,214.173264
	C82.994141,220.030853 92.851128,225.069275 103.830132,227.275375
	C109.643669,228.443527 113.184891,231.059204 114.636490,237.016617
	C115.689980,241.340210 117.775848,245.412262 119.494415,249.835938
	C125.501427,249.835938 131.808090,249.835938 138.454636,249.835938
	C140.674744,244.128906 143.066223,238.259094 145.227417,232.305664
	C146.269791,229.434265 148.526566,227.908981 150.786469,229.264267
	C152.195541,230.109299 153.236862,233.636902 152.659210,235.363937
	C150.274460,242.493713 147.208374,249.395584 144.696350,256.690613
	C134.645767,257.000000 124.291542,257.000000 113.468658,257.000000
z"
        />
        <path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M102.113388,193.010452
	C94.601562,188.268539 86.780502,184.340607 80.533943,178.676331
	C71.462708,170.450714 65.329887,159.797028 62.089317,147.909332
	C55.745033,124.635941 60.590031,103.639778 75.638046,84.802612
	C86.666077,70.997688 101.409355,63.800468 118.196602,60.081741
	C120.223610,59.632721 122.436012,60.020607 124.779236,60.020607
	C124.779236,106.015945 124.779236,151.720657 124.779236,197.876587
	C116.835014,198.591415 109.630890,196.026154 102.113388,193.010452
M92.959114,154.542465
	C86.774475,145.732346 83.873878,136.209000 84.876015,125.223587
	C86.200836,110.700851 93.333397,100.017265 104.797913,91.769150
	C107.242073,90.010696 109.582664,87.959259 106.843063,85.394592
	C105.574745,84.207275 101.848442,84.118256 100.200890,85.118996
	C91.536888,90.381638 84.966087,97.814339 81.080185,107.188210
	C75.109428,121.591331 75.023056,136.237335 81.059235,150.664688
	C85.041161,160.182053 91.533920,167.829590 100.535347,172.949203
	C102.143745,173.863968 105.768234,173.536942 107.006462,172.312057
	C109.455994,169.888901 107.052231,168.093185 104.932388,166.311386
	C100.794785,162.833588 96.975433,158.977188 92.959114,154.542465
z"
        />
        <motion.path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M177.564972,27.925369
	C179.754684,23.809765 182.738449,21.711164 187.083557,22.609224
	C191.380768,23.497377 193.869217,26.335085 193.962875,30.790699
	C194.061707,35.491722 191.479980,38.250187 187.040009,39.500244
	C181.251190,41.130066 176.634171,35.818111 177.564972,27.925369
z"
          animate={{
            translateX: [0, 40],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M192.371521,221.876053
	C195.092468,227.897034 193.588455,233.031540 188.908401,234.982437
	C185.549332,236.382690 180.209122,234.890213 178.317780,231.705750
	C176.087357,227.950348 176.274887,224.183502 179.515213,221.012436
	C183.386353,217.224014 186.353699,217.685928 190.299728,219.871719
	C190.997894,220.258453 191.487030,221.022568 192.371521,221.876053
z"
          animate={{
            translateX: [0, 40],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M210.739761,173.063507
	C204.330566,175.001617 199.758987,172.514359 198.185272,166.525162
	C197.331863,163.277252 201.131241,157.940460 204.947906,157.026108
	C209.608093,155.909668 215.532257,159.465759 215.556030,164.491043
	C215.569382,167.315842 212.703827,170.154251 210.739761,173.063507
z"
          animate={{
            translateX: [0, 40],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M237.375061,121.236816
	C241.796432,119.483856 247.181900,121.769699 248.972549,125.809303
	C250.442093,129.124512 248.031525,135.026352 244.467026,136.840210
	C240.558395,138.829193 235.756287,137.184998 233.578506,133.112076
	C231.054977,128.392563 232.248901,124.367783 237.375061,121.236816
z"
          animate={{
            translateX: [0, 40],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.path
          fill="#fff"
          opacity="1.000000"
          stroke="none"
          d="
M215.979767,90.070129
	C214.043625,92.979927 212.593826,96.428337 210.030533,97.807976
	C205.419571,100.289742 200.708923,97.762611 199.018753,92.970711
	C197.496964,88.656235 199.865555,83.799614 204.245255,82.254181
	C208.672028,80.692131 213.325516,83.179192 215.033142,87.984711
	C215.245850,88.583298 215.649231,89.114128 215.979767,90.070129
z"
          animate={{
            translateX: [0, 40],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <path
          fill="#1B347A"
          opacity="1.000000"
          stroke="none"
          d="
            M92.991707,154.910004
                C96.975433,158.977188 100.794785,162.833588 104.932388,166.311386
                C107.052231,168.093185 109.455994,169.888901 107.006462,172.312057
                C105.768234,173.536942 102.143745,173.863968 100.535347,172.949203
                C91.533920,167.829590 85.041161,160.182053 81.059235,150.664688
                C75.023056,136.237335 75.109428,121.591331 81.080185,107.188210
                C84.966087,97.814339 91.536888,90.381638 100.200890,85.118996
                C101.848442,84.118256 105.574745,84.207275 106.843063,85.394592
                C109.582664,87.959259 107.242073,90.010696 104.797913,91.769150
                C93.333397,100.017265 86.200836,110.700851 84.876015,125.223587
                C83.873878,136.209000 86.774475,145.732346 92.991707,154.910004
            z"
        />
      </motion.svg>
    </>
  );
};


export { InnovativeSvg }