import React from "react";
import { motion } from "framer-motion";

const BlockChainSvg1 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 800 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="100"
        y1="100"
        x2="300"
        y2="200"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="100"
        y1="100"
        x2="500"
        y2="100"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="100"
        y1="100"
        x2="700"
        y2="200"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="100"
        y1="100"
        x2="300"
        y2="400"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="100"
        y1="100"
        x2="100"
        y2="500"
        stroke="white"
        stroke-width="2"
      />

      <line
        x1="300"
        y1="200"
        x2="500"
        y2="100"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="300"
        y1="200"
        x2="700"
        y2="200"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="300"
        y1="200"
        x2="500"
        y2="300"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="300"
        y1="200"
        x2="300"
        y2="400"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="300"
        y1="200"
        x2="700"
        y2="400"
        stroke="white"
        stroke-width="2"
      />

      <line
        x1="500"
        y1="100"
        x2="700"
        y2="200"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="500"
        y1="100"
        x2="500"
        y2="300"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="500"
        y1="100"
        x2="300"
        y2="400"
        stroke="white"
        stroke-width="2"
      />

      <line
        x1="700"
        y1="200"
        x2="500"
        y2="300"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="700"
        y1="200"
        x2="300"
        y2="400"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="700"
        y1="200"
        x2="700"
        y2="400"
        stroke="white"
        stroke-width="2"
      />

      <line
        x1="500"
        y1="300"
        x2="300"
        y2="400"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="500"
        y1="300"
        x2="700"
        y2="400"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="500"
        y1="300"
        x2="100"
        y2="500"
        stroke="white"
        stroke-width="2"
      />

      <line
        x1="300"
        y1="400"
        x2="700"
        y2="400"
        stroke="white"
        stroke-width="2"
      />
      <line
        x1="300"
        y1="400"
        x2="100"
        y2="500"
        stroke="white"
        stroke-width="2"
      />

      <line
        x1="700"
        y1="400"
        x2="100"
        y2="500"
        stroke="white"
        stroke-width="2"
      />

      <motion.circle cx="100" cy="100" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="300" cy="200" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="500" cy="100" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="700" cy="200" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="500" cy="300" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="300" cy="400" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="700" cy="400" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
      <motion.circle cx="100" cy="500" r="8" fill="white" 
        animate={{
          scale:[.9, 1.7, .9],
          opacity: 1
        }}
        transition={{
          repeat: Infinity, 
          duration: 1, 
          ease: 'linear',
        }}
      />
    </svg>
  );
};

export { BlockChainSvg1 };
