import React from 'react'
import { Breadcrumb, PricingCards, SerivcesPieChart, ServicesBarChart, ServicesLineChart, Typewriter, UnderlineDesign } from '../../components'
import { Card, Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'

const {  Title, Text } = Typography
const WalletsPage = () =>{
    const data = [
        {
            img: "/assets/icons/wallet_1.png",
            title:'Metamask',
            des: "Start exploring blockchain applications in seconds.  Trusted by over 1 million users worldwide."
        },
        {
            img: "/assets/icons/wallet_2.png",
            title:'Bitski',
            des: "Bitski connects communities, creators and brands through unique, ownable digital content."
        },
        {
            img: "/assets/icons/wallet_3.png",
            title:'Fortmatic',
            des: "Let users access your Ethereum app from anywhere. No more browser extensions."
        },
        {
            img: "/assets/icons/wallet_4.png",
            title:'WalletConnect',
            des: "Open source protocol for connecting decentralised applications to mobile wallets."
        },
        {
            img: "/assets/icons/wallet_5.png",
            title:'Coinbase Wallet',
            des: "The easiest and most secure crypto coinbase wallet. ... No Coinbase account required."
        },
        {
            img: "/assets/icons/wallet_6.png",
            title:'Arkane',
            des: "Make it easy to create blockchain applications with secure wallets solutions."
        },
        {
            img: "/assets/icons/wallet_7.png",
            title:'Authereum',
            des: "Your wallet where you want it. Log into your favorite wallet dapps with Authereum."
        },
        {
            img: "/assets/icons/wallet_8.png",
            title:'Torus',
            des: "Open source protocol for connecting decentralised applications to mobile wallets."
        }
    ]
    const datapie = [
        'Decentralized Data Management',
        'Smart Contract Automation',
        'Personalized User Experience',
    ]
    const dataline = [
      {
        icon:"/assets/icons/blockchain.png",
        txt: "Smarter Digital Experiences"
      },
      {
        icon:"/assets/icons/contract.png",
        txt: "Automate with Smart Contracts"
      },
      {
        icon:"/assets/icons/token.png",
        txt: "DeFi for the Future"
      },
    ]
    const dataintro = [
        'Decentralized Data Management',
        'Smart Contract Automation',
        'Personalized User Experience',
    ]

  return (
    <div>
      <Breadcrumb title='Wallet' pageName='Wallet' breadcrumb={[{to:'/',name:'Home'}]}/>  
      <div className='container'>
        <Row gutter={[24, 24]} className='padding'>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Space direction='vertical' size={10} className='h-100 right' >
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        
                        <Title level={1} className=' gradient-text m-0'>
                            Crypto Management with Wallet Technology
                        </Title>
                    </motion.div>
                    <Text className='text-white'> 
                        Learn how crypto wallets streamline your digital finance journey with intuitive interfaces, robust encryption, and effortless transactions.
                    </Text>
                    <Flex vertical className='mt-2'>
                        {dataintro.map((item)=> (
                        <Space size={12} align='start' className='mb-1'>
                            <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                            <Title level={5} className='gradient-text m-0'>{item}</Title>
                        </Space>
                        ))}
                    </Flex>
                </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Flex align='center' className='center'>
                    <img src='/assets/images/wallet.jpg' height={300} className='rounded' />
                </Flex>
            </Col>
            <Col span={24}>
                <PricingCards />
            </Col>
            <Col span={24}>
                <Row gutter={[24,64]} className='padding'>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Space direction="vertical" className='h-100 space-mbl' >
                            <motion.div
                                initial={{ y: 100, opacity: 0 }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                }}
                                transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                                viewport={{once: true}}
                            >
                                <Title level={2} className='gradient-text mb-1'>
                                    Your Gateway to Digital Assets
                                </Title>
                                <UnderlineDesign />
                            </motion.div>
                            <Text className='text-gray text-justify'>Own, trade, and showcase your digital assets with NFTs, the future of creative expression. NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work.Own, trade, and showcase your digital assets with NFTs, the future of creative expression."</Text>
                            {datapie.map((item)=> (
                                <Space size={12} align='start' className='mb-1'>
                                    <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                                    <Title level={5} className='gradient-text m-0'>{item}</Title>
                                </Space>
                            ))}
                        </Space>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <SerivcesPieChart shades={['#3B2F2F', '#695E56', '#9A8F86', '#C8BBB2', '#F3EDE6']} />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[24,64]} className='padding'>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <ServicesLineChart />
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Space direction="vertical" className='h-100 space-mbl' >
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Title level={2} className='gradient-text mb-1'>
                                Hot vs. Cold Wallets
                            </Title>
                            <UnderlineDesign />
                        </motion.div>
                        <Text className='text-gray text-justify'>Revolutionize your gaming experience with NFT-based collectibles and in-game assets. NFTs (Non-Fungible Tokens) are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work. Revolutionize your gaming experience with NFT-based collectibles and in-game assets."</Text>
                        <Space size={5} className='mt-3'>
                          {dataline.map((item)=> (
                              <Space size={12} align='start' className='mb-1'>
                                  <Image src={item.icon} alt={item.txt} width={40} height={40} preview={false} />
                                  <Title level={5} className='gradient-text m-0'>{item.txt}</Title>
                              </Space>
                              ))}
                        </Space>
                    </Space>
                    </Col>
                </Row>
            </Col>
            <Col span={24} >
                <Space size={15} direction='vertical' align='center' className='w-100 padding space-mbl' >
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Title level={2} className='gradient-text mb-1'>
                            Multi-Asset Wallets
                        </Title>
                        <Flex justify='center'>
                            <UnderlineDesign />
                        </Flex>
                    </motion.div>
                </Space>
                <ServicesBarChart />
            </Col>
            <Col span={24}>
                <Space align='center' className='py-4 center text-center'>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        
                        <Title level={1} className='m-0 gradient-text mb-1'>
                            Available Wallets
                        </Title>
                        <Flex vertical align='center' className='mb-1'>
                            <UnderlineDesign />
                        </Flex>
                        <Text className='text-gray'>
                            Your Personalised Incubator
                        </Text>
                    </motion.div>
                </Space>
            </Col>
            {
                data.map((item,index)=>(
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} key={index}>
                        <Card className='bg-transparent  border-0'>
                            <Space direction='vertical' size={10}>
                                <Image src={item.img} preview={false} width={80} height={80} />
                                <Title level={3} className='m-0 text-white'>{item.title}</Title>
                                <Text className='text-gray'>{item.des}</Text>
                            </Space>
                        </Card>
                    </Col>
                 ))
            }
        </Row>
      </div>
    </div>
  )
}

export {WalletsPage}