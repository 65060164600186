import React from 'react'
import { Card, Col,  Flex, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { UnderlineDesign } from '../UnderlineDesign'
const { Text, Title } = Typography

const MeetTeam = () => {

    const data = {
        title:'Meet Our Team',
        subtitle:'Our diverse team at Inno is united by a passion for innovation in Web3 finance, dedicated to building a better financial future.',
        carddetail:[
            {
                img:'user-1.jpg',
                title:'Ava Brooks',
                subtitle:'Product Manager',
            },
            {
                img:'user-2.jpg',
                title:'Watson Jorge',
                subtitle:'Co-Founder & Ceo',
            },
            {
                img:'user-3.jpg',
                title:'Simrun Singh',
                subtitle:'CTO',
            },
        ]
    }

  return (
    <div className='padding'>
         <Row gutter={[24,24]} justify={'center'}>
            <Col span={24}>
                <Flex vertical justify='center' align='center' className='text-center' gap={15}>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .1, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once:true}}
                    >
                        <Title level={1} className='gradient-text mb-1'>
                            {
                                data?.title
                            }
                        </Title>
                        <Flex justify='center'>
                            <UnderlineDesign/>
                        </Flex>
                    </motion.div>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once:true}}
                    >
                        <Text className='text-gray fs-16'>
                            {
                                data?.subtitle
                            }
                        </Text>
                    </motion.div>
                </Flex>                   
            </Col>
            {
                data?.carddetail?.map((card,index)=>
                    <Col lg={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} key={index}>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness:200 }}
                            viewport={{once:true}}
                        >
                            <Card className='card-cs card-bg border0 rounded h-100' >
                                <img src={'/assets/images/'+card?.img} width={'100%'} className='rounded img-pro' />
                                <Space align='center' style={{justifyContent:'space-between'}} className='w-100 mt-2'>
                                    <Flex vertical>
                                        <Title level={5} className='m-0 text-white'>
                                            {
                                                card?.title
                                            }
                                        </Title>
                                        <Text className='text-gray'>
                                            {
                                                card?.subtitle
                                            }
                                        </Text>
                                    </Flex>
                                    <img src='/assets/icons/linkedin.png' width={30} />
                                </Space>
                            </Card>
                        </motion.div>
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {MeetTeam}