import React from "react";
import { Row, Col, Typography, Flex, Card, Image } from "antd";
import Slider from "react-slick";
import { motion } from "framer-motion";

const { Title, Text } = Typography;
function Partners() {

  const testimonialData = [
    {
      img: "web.png",
    },
    {
      img: "chainlink.png",
    },
    {
      img: "solana.png",
    },
    {
      img: "eth2.png",
    },
    {
      img: "web2.png",
    },
    {
      img: "filecoin.png",
    },
    {
      img: "uniswap2.png",
    },
  ];

  var testimonialSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <div className="padding">
        <Row gutter={[24, 24]} justify={"center"}>
            <Col span={24}>
                <Slider {...testimonialSlider}>
                    {testimonialData?.map((data, index) => {
                    return (
                        <div className="px-2" key={index}>
                            <Card className="bg-transparent border-gray" >
                                <Flex vertical gap={10}>
                                    <Image
                                        src={"/assets/images/partners/" + data?.img}
                                        width={"100%"}
                                        preview={false}
                                        className="size-img"
                                    />
                                </Flex>
                            </Card>
                        </div>
                    );
                    })}
                </Slider>
            </Col>
        </Row>
    </div>
  )
}

export { Partners };
