import React from 'react'
import { Breadcrumb, MarketingKey, MarketIntro, MarketReach, PricingCards } from '../../components'
import { Col, Row } from 'antd'

function MarketingPage() {
  return (
    <div>
        <Breadcrumb title='Marketing' pageName='Marketing' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>
            <Row gutter={[24,24]}>
              <Col span={24}>
                <MarketIntro />
              </Col>
              <Col span={24}>
                <MarketReach />
              </Col>
              <Col span={24}>
                <MarketingKey /> 
              </Col>
              <Col span={24}>
                <PricingCards />
              </Col>
            </Row>
        </div>
    </div>
  )
}

export {MarketingPage}