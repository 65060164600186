import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import { Typography, Flex, Button } from 'antd';

const { Text } = Typography;

const Navbar = ({ isFooterLinkClicked }) => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  const [activeLink, setActiveLink] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const showLargeNav = () => {
    const navbarSmall = document.getElementsByClassName("gen-navbar-small")[0];
    navbarSmall.setAttribute("class", "gen-navbar-small secondary-bg animation gen-navbar-small-move");
    const navbar = document.getElementsByClassName("gen-navbar")[0];
    navbar.setAttribute('class', "gen-navbar secondary-bg gen-navbar-trans");
    setIsNavShowing(!isNavShowing);
  };

  const hideLargeNav = () => {
    const navbarSmall = document.getElementsByClassName("gen-navbar-small")[0];
    navbarSmall.setAttribute("class", "gen-navbar-small animation");
    const navbar = document.getElementsByClassName("gen-navbar")[0];
    navbar.setAttribute('class', "gen-navbar");
    setIsNavShowing(!isNavShowing);
  };

  const menulink = [
    { tile: 'Home', path: '/' },
    { tile: 'About Us', path: '/about' },
    {
      tile: 'Services',
      path: '/services',
      submenu: [
        { title: 'Artificial Intelligence', path: '/services/ai' },
        { title: 'Blockchain', path: '/services/blockchain' },
        { title: 'NFT', path: '/services/nft' },
        { title: 'Wallets', path: '/services/wallets' },
        { title: 'Cyrpto Master Card', path: '/services/cryptocurrency' },
        { title: 'Tokonomics', path: '/services/tokenomic' },
        { title: 'Visa Card', path: '/services/visacard' },
        { title: 'Crypto Payment Gateway', path: '/services/cryptopaymentgateway' },
        { title: 'Marketing', path: '/services/marketing' },
      ]
    },
    { tile: 'Contact Us', path: '/contact' },
    { tile: 'Jobs', path: '/jobs' },
    { tile: 'Blog', path: '/blog' },
    { tile: 'Privacy Policy', path: '/privacypolicy' },
  ];

  useEffect(() => {
    const activeIndex = menulink.findIndex(menu => menu.path === location.pathname);
    setActiveLink(activeIndex !== -1 ? activeIndex : 0);
  }, [location]);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  return (
    <>
      <div className='gen-navbar-container' style={{ position: 'relative' }}>
        <div className='w-100'>
          <div className="gen-navbar-small">
            <div className="gen-navbar-inner">
              <div className='gen-navbar-left'><img src={'/assets/images/logo-r.png'} width={'100%'} alt="logo" /></div>
              <div className="gen-navbar-right">
                <div className="sp-cover" onClick={showLargeNav}>
                  <span className="sp sp-1 sp-1-click"></span>
                  <span className="sp sp-2 sp-2-click"></span>
                  <span className="sp sp-3 sp-3-click"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='gen-navbar'>
          <div className="gen-navbar-inner container">
            <div className='gen-navbar-left'>
              <img src='/assets/images/logo-r.png' width={'100%'} className='one px-2' alt="logo" />
              <img src='/assets/icons/close.png' className='two' onClick={hideLargeNav} alt="close" />
            </div>
            <ul className="gen-navbar-middle bg-blurr">
              {
                menulink.map((menu, index) =>
                  menu.submenu ? (
                    <li
                      key={index}
                      className="items py-2 dropdownC"
                      onMouseEnter={toggleDropdown}
                      onMouseLeave={toggleDropdown}
                    >
                      <NavLink
                        to={menu.path}
                        className={activeLink === index && isFooterLinkClicked ? "nav-item active" : "nav-item"}
                        onClick={() => { setActiveLink(index); hideLargeNav(); }}
                      >
                        <div className='align-center'>
                          <Text strong className='my-0 item-name'>Services</Text>
                        </div>
                      </NavLink>
                      <ul className="dropdownMenu">
                          {menu.submenu.map((submenu, subIndex) => (
                            <li key={subIndex} className="dropdown-item">
                              <NavLink
                                to={submenu.path}
                                className="nav-item"
                                onClick={hideLargeNav}
                              >
                                {submenu.title}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                    </li>
                  ) : (
                    <li key={index} className='items py-2'>
                      <NavLink
                        to={menu.path}
                        className={activeLink === index && isFooterLinkClicked ? "nav-item active" : "nav-item"}
                        onClick={() => { setActiveLink(index); hideLargeNav(); }}
                      >
                        <div className='align-center'>
                          <Text strong className='my-0 item-name'>{menu.tile}</Text>
                        </div>
                      </NavLink>
                    </li>
                  )
                )
              }
            </ul>
            <div className='end-div displaynone'>
              <Flex gap={15} align='center' justify='end'>
                <Link to={'/appointment'}>
                  <img src="/assets/icons/appointment.png" width={30} alt="mozeff" />
                </Link>
                <Button className='btn w-auto' onClick={() => navigate('/login')}>
                  <div className='content vertical '>
                    <div className="text">Login</div>
                    <div className="hover-text">Login</div>
                  </div>
                </Button>
              </Flex>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
