import React, { useState } from 'react';
import { Card, Form, Flex, Space, Typography, Button, Row, Col } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { color } from 'framer-motion';

const { Text, Title } = Typography;

const ServicesBarChart = () => {
  const [form] = Form.useForm();

  const chartData = {
    series: [
      {
        name: 'Active Wallets',
        data: [200, 220, 210, 230, 240, 250, 260, 270, 280],
      },
      {
        name: 'New Contracts',
        data: [30, 200, 35, 50, 185, 55, 60, 65, 270],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false 
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
        ],
        labels: {
          style: {
            colors: '#fff',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#fff',
          },
        },
      },
      colors: ['#2f58b7', '#90a2cc'],
      legend: {
        position: 'bottom',
        labels:{
          colors: '#fff',
        }
      },
    },
  };

  return (
    <div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={300}
        />
    </div>
  );
};

export { ServicesBarChart };
