

import React, { useState } from 'react';
import { Form,  Typography} from 'antd';
import ReactApexChart from 'react-apexcharts';

const { Text, Title } = Typography;

const SerivcesPieChart = () => {
  const [form] = Form.useForm();

  const chartData = {
    series: [35, 25, 20, 10, 10],
    options: {
      chart: {
        type: 'pie',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      labels: [
        'Audit & Testing',
        'Vulnerability Assessment',
        'Code Review',
        'Penetration Testing',
        'Monitoring & Alerts',
      ],
      colors:['#1A1B41', '#282A74', '#4A48A0', '#6C6BCE', '#9396FF'],
      legend: {
        show: true,
        position: 'left',
        verticalAlign: 'middle',  
        horizontalAlign: 'center',
        labels:{
          colors:"#fff" ,
        },
        itemMargin: {
          vertical: 8    
        }
      },
    },
  };
  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={'100%'}
        height={300}
      />
    </div>
  );
};

export { SerivcesPieChart };
