import React from 'react'
import { Col, Flex, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { Typewriter } from '../../PageComponents'

const { Text, Title } = Typography
const VisaCardIntro = () => {

    const messages = ['Visa Credit Cards']
    const data = [
        'Credit Card',
        'Debit Card',
        'Prepaid Card',
    ]

  return (
    <Row gutter={[24,64]} className='padding' justify={'space-between'} >
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
            <Space size={10} direction='vertical'>
                <Title className='gradient-text'>
                    <Typewriter messages={messages} speed={150} />
                </Title>
                <Text className='text-gray text-justify'>
                    No matter how carefully you plan, every now and then you may have to make an unexpected payment. With a Visa credit card, you are ready for anything — whether you need to get the car repaired, make a long trip to visit relatives or just want financial peace of mind. A Visa credit card can also help you to take advantage of bargains and budget for bigger buys, such as a family holiday or new furniture. You can buy what you need, when you need it and spread the cost over several months.
                </Text>
                {data.map((item)=> (
                    <Space size={12} align='start' className='mb-1'>
                        <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                        <Title level={5} className='gradient-text m-0'>{item}</Title>
                    </Space>
                    ))}
            </Space>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }}>
            <Flex justify='end'>
                <Image src='/assets/images/visa.jpg' height={350} width={'100%'} className='rounded' preview={false} />
            </Flex>
        </Col>
    </Row>
  )
}

export {VisaCardIntro}