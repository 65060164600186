import React from 'react'
import { Button, Card, Col, Flex, Row, Form, Typography, Image } from 'antd'
import { Breadcrumb, Empowercomponent, MyInput, Typewriter } from '../../components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
const { Text, Title } = Typography
const Contactus = () => {

    const [form] = Form.useForm();

    const messages = [
        'Transfer now web2 to web3'
    ]
    
  return (
    <div>
        <Breadcrumb title='Contact Us' pageName='Contact Us' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[64,24]} justify={'center'} className='mt-10'>
                <Col lg={{span: 13}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                    <Flex vertical justify='center'>
                        <motion.div
                            initial={{ opacity: 0}}
                            whileInView={{
                                opacity: 1
                            }}
                            transition={{ duration: .3, ease: 'easeInOut' }}
                            viewport={{once: true}}    
                        >
                            <Title level={1} className='gradient-text' style={{width: 'content-fit'}}>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <motion.div
                            initial={{ y: '-100%', opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            <Text className='text-gray text-justify'>
                                If you have any questions or are interested in collaboration, feel free to reach out. The Mozeff team is here to help.
                            </Text>
                        </motion.div>
                        <Flex className='mt-3' gap={20}>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/twitter.png' width={'23px'} preview={false} />
                                </Link>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/facebook.png' width={'23px'} preview={false} />
                                </Link>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/linkedin.png' width={'23px'} preview={false} />
                                </Link>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/insta.png' width={'23px'} preview={false} />
                                </Link>
                            </Flex>
                       <Flex justify='center'>
                         <img src='/assets/images/cont.png' width={'40%'} className='scale-animate' />
                       </Flex>
                    </Flex>                   
                </Col>
                <Col lg={{span: 11}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                    <motion.div
                        initial={{ y: '-100%', opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .2, duration: .3, }}
                        viewport={{once: true}}
                    >
                        <Form 
                            form={form}
                            layout='vertical'
                            requiredMark={false}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <MyInput 
                                        label='First Name'
                                        name='firstName'
                                        required
                                        message='Please enter first name'
                                        placeholder='Jane'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <MyInput 
                                        label='Last Name'
                                        name='lastname'
                                        required
                                        message='Please enter last name'
                                        placeholder='Smith'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <MyInput 
                                        label='Email'
                                        name='email'
                                        required
                                        message='Please enter email'
                                        placeholder='example@example.com'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <MyInput 
                                        textArea
                                        label='Message'
                                        name='message'
                                        required
                                        message='Please enter message'
                                        autoSize={{ minRows: 8, maxRows: 10 }}
                                        placeholder='Write your message here'
                                    />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Button className="btn rounded" block>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </motion.div>
                </Col>
                <Col span={24}>
                    <div className='iframe'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9582117.683645044!2d-15.01291801618464!3d54.102392749083535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2s!4v1733487787998!5m2!1sen!2s" width="100%" height="450" style={{border:0,borderRadius:12}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {Contactus}