import { Col, Divider, Image, Row, Space, Typography } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import {  careerData, singlejob } from '../../../data'
import { Breadcrumb } from '../../Breadcrumb'
import { Typewriter } from '../../PageComponents'
import { motion } from 'framer-motion'
const {Title, Text} =Typography

const SingleViewCareer = ()=> {
  const { career_id } = useParams()
  const jobData = singlejob.find(item => item.job_id == career_id)
  const career = careerData.find(item => item.job_id == career_id)
  const messages =[
    'Job Descriptions'
  ]
  return (
    <div>
      <Breadcrumb title='Career' pageName='Career' breadcrumb={[{to:'/',name:'Home'}]}/>
      <div className='container'>
        <div className='padding'>
          <Row gutter={[24,24]}>
            <Col span={24}>
              <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                  viewport={{once: true}}
                  className='center mb-3'
              >
                  <Title level={1} className='m-0 gradient-text'>
                      <Typewriter messages={messages} speed={150} />
                  </Title>
              </motion.div>
            </Col>
            <Col span={24}>
              <Space direction='vertical' size={10} className='px-4'>
                <Title className='text-white m-0' level={3}>{career.title}</Title>
                <Text className='text-gray'> {career.location} </Text>
              </Space>
            </Col>
            <Col span={24}>
                <Space direction="vertical" size={20} className='px-4'>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white '>About</Title>
                      <Text className='text-gray text-justify'>{jobData.about}</Text>
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>Responsibilities</Title>
                    {jobData.res.map((text)=>(
                      <Space size={12} align='start'>
                      <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                        <Text className='text-gray text-justify '>{text}</Text>
                      </Space>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>Requirements</Title>
                    {jobData.req.map((text)=>(
                      <Space size={12} align='start'>
                      <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                        <Text className='text-gray text-justify '>{text}</Text>
                        </Space>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>What We Offer</Title>
                    {jobData.offer.map((text)=>(
                      <Space size={12} align='start'>
                        <Image src="/assets/icons/check.png" alt="check" width={20} height={20} preview={false} />
                        <Text className='text-gray text-justify '>{text}</Text>
                      </Space>
                    ))}
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>How to Apply</Title>
                    <Text className='text-gray text-justify '>Send your resume and a brief cover letter to [Your Email Address], explaining why you’re the right fit for this role.</Text>
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Title level={3} className='text-white'>Join Us</Title>
                    <Text className='text-gray text-justify '>{jobData.join}</Text>
                  </Space>
                </Space>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  )
}

export {SingleViewCareer}