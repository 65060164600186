import React from 'react'
import { Col, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { ServicesLineChart } from '../../ChartComponents'
import { UnderlineDesign } from '../../UnderlineDesign'

const {Text, Title} = Typography
const CryptoLineChart = ()=> {
  const dataline = [
    {
      icon:"/assets/icons/blockchain.png",
      txt: "Smarter Digital Experiences"
    },
    {
      icon:"/assets/icons/contract.png",
      txt: "Automate with Smart Contracts"
    },
    {
      icon:"/assets/icons/token.png",
      txt: "DeFi for the Future"
    },
  ]
  return (
    <Row gutter={[24,64]} className='padding'>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <ServicesLineChart />
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Space direction="vertical" className='h-100 space-mbl' >
              <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                      opacity: 1,
                      y: 0,
                  }}
                  transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                  viewport={{once: true}}
              >
                  <Title level={2} className='gradient-text mb-1'>
                    Decentralized Finance
                  </Title>
                  <UnderlineDesign />
              </motion.div>
              <Text className='text-gray text-justify'>Decentralized finance offers freedom from traditional banking systems, enabling secure, peer-to-peer transactions. are unique digital assets stored on the blockchain, representing ownership of items like art, music, and collectibles, offering creators new ways to monetize their work. Revolutionize your gaming experience with NFT-based collectibles and in-game assets."</Text>
              <Space size={5} className='mt-3'>
                {dataline.map((item)=> (
                    <Space size={12} align='start' className='mb-1'>
                        <Image src={item.icon} alt={item.txt} width={40} height={40} preview={false} />
                        <Title level={5} className='gradient-text m-0'>{item.txt}</Title>
                    </Space>
                    ))}
              </Space>
          </Space>
      </Col>
    </Row>
  )
}

export {CryptoLineChart}