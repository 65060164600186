import {
    AiSvg,
    CryptoCurrencySvg,
    NftSvg,
    SmartContractSvg,
    DigitalCurrencySvg,
    DecentralizedFinanceSvg,
    DeeplearningSvg,
    SemanticsWebSvg,
    ThreeDgraphicSvg,
    BlocksChainSvg,
    MachinelearningSvg,
    PeertopeerSvg,
    EnhanceConnectSvg
  } from "../SvgsFile";

export const servicesMainData = [
    {
      id:1,
      img: <AiSvg />,
      title: "Artificial Integence",
    },
    {
      id:2,
      img: <NftSvg />,
      title: "NFTS",
    },
    {
      id:3,
      img: <CryptoCurrencySvg />,
      title: "Crypto Currency",
    },
    {
      id:4,
      img: <BlocksChainSvg />,
      title: "Blockchain",
    },
    {
      id:5,
      img: <MachinelearningSvg />,
      title: "Machine Learning",
    },
    {
      id:6,
      img: <DeeplearningSvg />,
      title: "Deep Learning",
    },
    {
      id:7,
      img: <SmartContractSvg />,
      title: "Smart Contract",
    },
    {
      id: 8,
      img: <DigitalCurrencySvg />,
      title: "Digital Currency",
    },
    {
      id: 9,
      img: <DecentralizedFinanceSvg />,
      title: "Decentralized Finance",
    },
    {
      id: 10,
      img: <SemanticsWebSvg />,
      title: "Semantic Web",
    },
    {
      id: 11,
      img: <ThreeDgraphicSvg />,
      title: "3D Graphic",
    },
    {
      id: 12,
      img: <PeertopeerSvg />,
      title: "Peer-to-peer Network",
    },
    {
      id: 13,
      img: <EnhanceConnectSvg />,
      title: "Enhance Connectivity",
    },
  ];