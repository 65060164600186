import React from 'react'
import { Card, Col, Flex, Image, Row, Space, Typography } from 'antd'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Text, Title } = Typography
const BlockChainCard = () => {

    const data = [
        {
            img:'blk-2.png',
            title:'Immutability in Blockchain',
            list:[
                'Transactions, once recorded, cannot be altered or deleted.',
                'Ensures trust and reliability in data records.',
                'Provides a tamper-proof system for various industries.',
            ],
        },
        {
            img:'blk-3.png',
            title:'Transparency in Blockchain',
            list:[
                'All transactions are visible to participants in the network.',
                'Enhances accountability and reduces fraud.',
                'Ideal for supply chain and public sector applications.',
            ],
        },
        {
            img:'blk-4.png',
            title:'Decentralization in Blockchain',
            list:[
                'Eliminates the need for intermediaries by distributing control.',
                'Reduces risks associated with single points of failure.',
                'Encourages peer-to-peer interactions and trustless systems.',
            ],
        },
    ]
    

  return (
    <Row gutter={[24,64]} className='padding'>
        <Col span={24}>
            <Flex vertical align='center' gap={10}>
                <Flex vertical gap={5} align='center'>
                    <Title className='gradient-text text-center m-0'>
                        Blockchain Technology 
                    </Title>
                    <UnderlineDesign />
                </Flex>
                <Text className='text-gray'>
                    Your Personalised Incubator
                </Text>
            </Flex>
        </Col>
        {
            data?.map((data)=>
                <Col lg={{span:8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                    <Card className='bg-transparent border0 rounded shadow card-design h-100' >
                        <Flex vertical gap={15} align='center'>
                            <Image src={'/assets/images/'+data?.img} preview={false} className='rounded' width={'80%'} height={270} />
                            <Flex vertical gap={15}>
                                <Flex vertical gap={5}>
                                    <Title level={4} className='gradient-text m-0'>
                                        {
                                            data?.title
                                        }
                                    </Title>
                                    <UnderlineDesign />
                                </Flex>
                                <div>
                                    {data?.list.map((item)=> (
                                        <Space size={12} align='start' className='mb-1'>
                                            <Image src="/assets/icons/cir.png" alt="check" width={12} preview={false} />
                                            <Text className='text-gray'>{item}</Text>
                                        </Space>
                                    ))}
                                </div>
                            </Flex>
                        </Flex>
                    </Card>
                </Col>
            )
        }
    </Row>
  )
}

export {BlockChainCard}