import React, { useRef } from 'react'
import { Anchor, Card, Col,  Flex, Row, Typography} from 'antd'
import { useScroll, motion, useTransform} from 'framer-motion';
import { Breadcrumb, Empowercomponent, Typewriter } from '../../components';
const { Text, Title } = Typography


const PrivacyPolicy = () => {

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ container: ref })
    const stickyY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);

    const paragraph = "Your privacy is our top priority. At Mozeff, we safeguard your data with advanced security protocols, ensuring transparency and full control over your personal information.";
    const words = paragraph.split(" ");
    
    const data = [
        {
            title:'I. Introduction',
            desc:`At Mozeff, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data you share with us. Whether you're exploring our services or actively engaging with our Web3 fintech solutions, your privacy is of paramount importance. We ensure that all your interactions with our platform are secure, transparent, and aligned with the highest standards of data protection.`,
        },
        {
            title:'II. Data Collection and Usage',
            desc:`We collect various types of information, including personal details, transaction data, and usage patterns, to enhance your experience with our services.`,
        },
        {
            title:'III. Data Sharing and Disclosure',
            desc:`Mozeff does not sell your personal information to third parties. However, we may share your data with trusted partners and service providers who assist us in operating our platform, conducting business, or serving you. These entities are bound by strict confidentiality agreements and are only permitted to use your information as necessary to perform their services. Additionally, we may disclose your information if required by law or to protect the rights, property, or safety of Mozeff, our users, or the public.`,
        },
        {
            title:'IV. Security Measures',
            desc:`We implement a variety of security measures to ensure the protection of your personal information. These include encryption, secure access protocols, and regular security audits. Our team is dedicated to maintaining the highest security standards, ensuring that your data is safe from unauthorized access, alteration, or disclosure. We continuously review and update our security practices to adapt to new threats and technologies, providing you with a secure environment for all your financial transactions and interactions on our platform.`,
        },
        {
            title:'V. Changes to This Policy',
            desc:`Mozeff may update this Privacy Policy periodically to reflect changes in our practices, legal requirements, or industry standards. We will notify you of any significant changes through our platform or via email. We encourage you to review this policy regularly to stay informed about how we protect your information and ensure your privacy.`,
        },
        {
            title:'VI. Contact Us',
            desc:`If you have any questions about this Privacy Policy, please contact our support team.`,
        },
    ]
    const messages = [
        'Privacy Policy'
    ]

  return (
    <div>
        <Breadcrumb title='Privacy Policy' pageName='Privacy Policy' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container'>    
            <Row gutter={[24,24]} justify={'center'} ref={ref}>
                <Col lg={{span: 16}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                    <Flex vertical gap={10} justify='center' align='center' className='text-center my-5 mb-3'>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .2, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                        <div className='w-100 mb-2'>
                            {words.map((word, index) => (
                                <motion.span
                                    key={index}
                                    style={{ padding: "0 2px", fontSize: "20px", display: 'inline-block'  }}
                                    className='text-gray'
                                    initial={{ opacity: 0, y: 10 }} 
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                    delay: index * 0.09,
                                    duration: 0.3, 
                                    ease: "easeInOut", 
                                    }}
                                    viewport={{once: true}}
                                >
                                    {word}
                                </motion.span>
                            ))}
                        </div>
                    </Flex>                  
                </Col>
                <Col span={24}>
                    <Card className='faqs' ref={ref}>
                        <Row gutter={[24,32]} justify={'center'}>
                            <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                            <motion.div
                                style={{
                                position: 'sticky',
                                top: 100,
                                transform: stickyY,
                                }}>
                                <Anchor
                                    affix={false}
                                    className='anchor-cs'
                                    items={[
                                        {
                                            key: 'part-1',
                                            href: '#part-1',
                                            title: <Title level={4} className='m-0' >Introduction</Title>,
                                        },
                                        {
                                            key: 'part-2',
                                            href: '#part-2',
                                            title: <Title level={4} className='m-0'>Data Collection and Usage</Title>,
                                        },
                                        {
                                            key: 'part-3',
                                            href: '#part-3',
                                            title: <Title level={4} className='m-0'>Data Sharing and Disclosure</Title>,
                                        },
                                        {
                                            key: 'part-4',
                                            href: '#part-4',
                                            title: <Title level={4} className='m-0'>Security Measures</Title>,
                                        },
                                        {
                                            key: 'part-5',
                                            href: '#part-5',
                                            title: <Title level={4} className='m-0'>Changes to This Policy</Title>,
                                        },
                                        {
                                            key: 'part-6',
                                            href: '#part-6',
                                            title: <Title level={4} className='m-0'>Contact Us</Title>,
                                        },
                                    ]}
                                />
                            </motion.div>
                            </Col>
                            <Col xl={16} lg={16} md={14} sm={24} xs={24} >
                                {
                                    data?.map((content, index)=>
                                        <div id={`part-${index + 1}`} key={index} className='mb-3'>
                                            <Title level={3} className='text-white'>
                                                {
                                                    content?.title
                                                }
                                            </Title>
                                            <Text className='fs-14 text-white text-justify'>
                                                {
                                                    content?.desc
                                                }
                                            </Text>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                        <div className='parent-shap'>
                            <div className='bg-shape'></div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Empowercomponent images={'/assets/images/r-3.png'} />
        </div>
    </div>
  )
}

export {PrivacyPolicy}