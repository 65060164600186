import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';

const NftSlider = () => {

  const data = [
    {
      img: '/assets/images/nftslide-1.jpg',
    },
    {
      img: '/assets/images/nftslide-2.jpg',
    },
    {
      img: '/assets/images/nftslide-3.jpg',
    },
    {
        img: '/assets/images/nftslide-4.jpg',
    },
    {
        img: '/assets/images/nftslide-5.jpg',
    },
    {
        img: '/assets/images/nftslide-1.jpg',   
    },
    {
        img: '/assets/images/nftslide-2.jpg',
    },
    {
        img: '/assets/images/nftslide-3.jpg',
    },
    {
        img: '/assets/images/nftslide-4.jpg',
    },
  ];

  return (
    <div className='padding'>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      >
        
          {
            data?.map((items, index)=>
              <SwiperSlide className='nft-slider'>
                <img src={items?.img} className='w-100 h-100'  style={{objectFit:'cover'}}/>
              </SwiperSlide>
            )
          }
        
      </Swiper>
    </div>
  );
}

export {NftSlider};