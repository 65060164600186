import React from 'react'
import { Card, Col, Flex, Row, Typography } from 'antd'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Text, Title } = Typography
const GatewayWorks = () => {

    const data = [
        {
            id:1,
            img:'gt-2.png',
            title:'Invoice',
            desc:''
        },
        {
            id:2,
            img:'gt-3.png',
            title:'Payment',
            desc:''
        },
        {
            id:3,
            img:'gt-4.png',
            title:'Conversion',
            desc:''
        },
        {
            id:4,
            img:'gt-5.png',
            title:'Settlement',
            desc:''
        },
    ]
    

  return (
    <Row gutter={[24,24]} className='padding'>
        <Col span={24}>
            <Flex vertical align='center' gap={10} className='mb-3'>
                <Title className='gradient-text text-center m-0'>
                    How Mozeff Work?
                </Title>
                <UnderlineDesign />
                <Text className='text-gray'>
                    Your Personalised Incubator
                </Text>
            </Flex>
        </Col>
        {
            data?.map((data)=>
                <Col lg={{span:6}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                    <Card className='bg-transparent border0 rounded shadow card-design h-100' >
                        <Flex vertical gap={15}>
                            <Flex vertical gap={15} align='center'>
                                <div className='icon-div1'>
                                    <img src={"/assets/icons/"+data?.img} width={data?.id===4? 43 : 35} alt="" />
                                </div>
                                <Title level={3} className='gradient-text m-0'>
                                    {
                                        data?.title
                                    }
                                </Title>
                            </Flex>
                        </Flex>
                    </Card>
                </Col>
            )
        }
    </Row>
  )
}

export {GatewayWorks}