import React from 'react'
import { Row, Col, Image, Space, Typography, Divider, Flex } from 'antd'
import { Link } from 'react-router-dom'

const { Title, Text } = Typography
const Footer = () => {

    const data = [
        {
            heading:'COMPANY',
            listdetail: [
                {
                    to:'/',
                    text:'Home'
                },
                {
                    to:'/about',
                    text:'About Us'
                },
                {
                    to:'/services',
                    text:"Services"
                },
                {
                    to:'/contact',
                    text:"Contact Us"
                },
                {
                    to:'/career',
                    text:"Career"
                },
                {
                    to:'/termsuse',
                    text:"Term of Use"
                }
            ],
        },
        {
            heading:'SERVICES',
            listdetail: [
                {
                    to:'/services/ai',
                    text:'Artificial Intelligence'
                },
                {
                    to:'/services/blockchain',
                    text:'Blockchain'
                },
                {
                    to:'/services/nft',
                    text:"NFT"
                },
                {
                    to:'/services/wallets',
                    text:"Wallets"
                },
                {
                    to:'/services/cryptocurrency',
                    text:"Crypto master card"
                },
                {
                    to:'/services/tokenomic',
                    text:"Tokenomics"
                },
            ],
        },
        {
            heading:'CONTACTS',
            listdetail: [
                {
                    to:'/',
                    text:'+38 096 272 2100',
                    desc: 'Ukraine, Odesso, 65058'
                },
            ],
            contacts:[
                {
                    title:'INQUIRES',
                    desc:'mail@halo-lab.com'
                },
                {
                    title:'CAREERS',
                    desc:'dream@halo-lab.com'
                },
            ]
        },
    ]

    const brands = [
        {
            logo: "upwork.png",
            desc: "Upwork awarded agency"
        },
        // {
        //     logo: "dribble.png",
        //     desc: "#1 Agency Worldwide"
        // },
        // {
        //     logo: "clutch.png",
        //     desc: "4.9 proven client's score"
        // },
    ]

  return (
    <div className='padding footer'>
        <div className='container'>
            <Row gutter={[24,24]}>
                <Col lg={{span: 6}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={10} className='width-100'>
                        <div className='mb-1'>
                            <Link to={'/'}>
                                <img src='/assets/images/logo-r.png' width={100} />
                            </Link>
                        </div>
                        {
                            brands?.map((brand,b)=>
                                <Flex vertical gap={5} key={b} className='mb-2'>
                                    <Image src={'/assets/images/'+brand?.logo} width={80} preview={false} />
                                    <Text className='text-gray fs-12' italic>
                                        {
                                            brand?.desc
                                        }
                                    </Text>
                                </Flex>
                            )
                        }
                    </Space>
                </Col>
                
                {
                    data?.map((items, index)=>
                    <Col lg={{span: items.heading === 'SERVICES' ? 8 : 5}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}} key={index}>
                        <Space direction='vertical' size={15} className='list-cs'>
                            <Title className='text-white m-0' level={5}>
                                {
                                    items?.heading
                                }
                            </Title>
                            <ul className={`ul-f m-0 ${items.id === 2 && 'count-2'}`}>
                                {
                                    items?.listdetail?.map((list,i)=>
                                        <li key={'menu'+ i}>
                                            <Flex vertical>
                                                <Link 
                                                    to={list?.to} 
                                                    className='hovera text-gray'
                                                    >
                                                    {list?.text}
                                                </Link>
                                                {
                                                    list.desc &&
                                                    <Text className='text-gray hovera'>
                                                        {
                                                            list?.desc
                                                        }
                                                    </Text>
                                                }
                                            </Flex>
                                        </li>
                                    )
                                }
                            </ul>
                            {
                                items?.contacts?.map((con,c)=>
                                <Flex vertical gap={5} className='' key={c}>
                                    <Title className='text-white m-0' level={5}>
                                        {con?.title}
                                    </Title>
                                    <Text className='text-gray hovera'>
                                        {con?.desc}
                                    </Text>
                                </Flex>
                                )
                            }
                        </Space>
                        {
                            items?.heading === 'CONTACTS' &&
                            <Flex className='pl-40' gap={10}>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/twitter.png' width={'23px'} preview={false} />
                                </Link>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/facebook.png' width={'23px'} preview={false} />
                                </Link>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/linkedin.png' width={'23px'} preview={false} />
                                </Link>
                                <Link to={'/'}>
                                    <Image src='/assets/icons/insta.png' width={'23px'} preview={false} />
                                </Link>
                            </Flex>
                        }
                    </Col>
                    )
                }
                <Col span={24}>
                    <Divider className='my-2 ' style={{borderColor:'#fff'}} />
                    <Flex justify='center' wrap  className='width-100 pt-2' gap={20}>
                        <Typography.Text className='fs-13 text-white'>
                            Mozeff Web 3.0 Consulting All right reserved. © 2024
                        </Typography.Text>
                    </Flex>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {Footer}