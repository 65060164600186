import { Col, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import React from 'react'
import { UnderlineDesign } from '../../UnderlineDesign'

const {Title, Text} = Typography
const ServicesHelp = ()=> {
  return (
    <Row gutter={[24,64]} className='padding'>
      <Col span={24} >
        <Space direction='vertical' size={10} className='w-100 space-mbl'>
            <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
                opacity: 1,
                y: 0,
            }}
            transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
            viewport={{once: true}}
            >
                <Title level={1} className='m-0 mb-1 gradient-text'>
                    How Mozeff's Help
                </Title>
                <UnderlineDesign />
            </motion.div>
            <Space className=' center w-100'>
                <Image src="/assets/images/infographic.png" className='w-100' preview={false} />
            </Space>
        </Space>
      </Col>
      

    </Row>
  )
}

export {ServicesHelp}