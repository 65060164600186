import { Flex } from 'antd'
import React from 'react'

const UnderlineDesign = () => {
  return (
    <Flex align='center' gap={8}>
        <div class="line"></div>
        <div class="smdots"></div>
        <div class="smdots"></div>
    </Flex>
  )
}

export {UnderlineDesign}