import React, { useRef, useState } from 'react'
import { Card, Col, Divider, Flex, Image, Row, Typography } from 'antd'
import { motion, useScroll, useTransform } from 'framer-motion'
const { Text, Title } = Typography
const Recentworks = () => {

    const [ activetab, setActiveTab ] = useState(0)
    const targetRef = useRef(null)
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset:["end end", "end start"]
    })
    const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
    const scale = useTransform( scrollYProgress, [0, .5], [1, 1.5] )

    const data = {
        head:[
            {
                title:'Analytics Al',
                desc: "That's Why We Leverage AI to Create Impactful. Lasting Experiences That Engage, and Transform Every Interaction.",
            },
            {
                title:'XooX Chain',
                desc: "34% increase in online sales.",
            },
            {
                title:'Signum',
                desc: "47% increase in new customers.",
            },
        ],
        imgAr:[
            {
                img:'im-1.jpg',
            },
            {
                img:'im-2.jpg',
            },
            {
                img:'im-3.jpg',
            },
        ]
    }
  return (
    <motion.div className='padding'
        ref={targetRef}
        style={{
            opacity,
            scale,
        }}
    >
        <Row gutter={[24,24]} align={'middle'}>
            <Col span={24}>
                <Flex vertical justify='center' gap={30} align='center'>
                    <div
                    >
                        <Text className='text-gray pill-text'
                        >
                            Work That Make Us Proud
                        </Text>
                    </div>
                    <div
                        // initial={{ y: -50, opacity: 0 }}
                        // whileInView={{
                        //     opacity: 1,
                        //     y: 0,
                        // }}
                        // transition={{ delay: .5, duration: .5, type:'spring', stiffness: 300 }}
                        // viewport={{once: false}}
                    >
                        <Title level={1} className='text-center m-0 gradient-text'
                        >
                            Recent Works, Notable Impact
                        </Title>
                    </div>
                    <div
                        // initial={{ y: 50, opacity: 0 }}
                        // whileInView={{
                        //     opacity: 1,
                        //     y: 0,
                        // }}
                        // transition={{ delay: .3, duration: .5, type:'spring', stiffness: 300 }}
                        // viewport={{once: false}}
                    >
                        <Text className='text-gray fs-16'
                        >
                            That drives impactful gain powerful results
                        </Text>
                    </div>
                </Flex>
            </Col>
            
            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} >
                <div>
                    {
                        data?.head?.map((heading,index)=>
                            <div
                            >
                            <Card className={
                                activetab === index ? 'bg-transparent card-tab active' :
                                'bg-transparent card-tab'
                            } 
                                onClick={()=>setActiveTab(index)}
                                key={index}    
                                
                            >
                                <Flex 
                                    vertical={index === 0 ? true : false} 
                                    gap={index === 0 ? 10 : 30}
                                    align={index === 0 ? null : 'end' }
                                >
                                    <Title className='text-white m-0' level={4}>
                                        {
                                            heading?.title
                                        }
                                    </Title>
                                    <Text className='text-gray'>
                                        {
                                            heading?.desc
                                        }
                                    </Text>
                                </Flex>
                            </Card>
                            {
                                index < data?.head?.length - 1 &&
                                <Divider className='bg-divider m-0' />
                            }
                            </div>
                        )
                    }
                </div>
            </Col>
            {
                data?.imgAr?.map((items,i)=>
                    <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} key={i}
                        className={
                            activetab === i ? 'd-show' : 'd-none'
                        }
                        
                    >
                        <div
                        >
                            <Card className='card-bg h-100 border0'>
                                <Image src={'/assets/images/'+items?.img} width={'100%'} className='rounded' preview={false} />
                            </Card>
                        </div>
                    </Col>
                )
            }
        </Row>
    </motion.div>
  )
}

export {Recentworks}