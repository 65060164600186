import  React from "react";
import {motion, spring} from 'framer-motion'
const EfficientSvg1 = () => (
  <motion.svg

    xmlns="http://www.w3.org/2000/svg"
    width="115.000000pt"
    height="115.000000pt"
    viewBox="0 0 115.000000 115.000000"
    preserveAspectRatio="xMidYMid meet"
    initial={{rotate: 0}}
      animate={{ rotate: 360}}
      transition={{
        duration:4,
        delay: .3,
        type:spring,
        repeat:Infinity
      }}
    
  >
  
    <g
      transform="translate(0.000000,115.000000) scale(0.100000,-0.100000)"
      fill="#000"
      stroke="none"
    >
      <path
      d="M555 1119 c-4 -6 -7 -47 -6 -90 l2 -79 -36 0 c-25 0 -43 -8 -60 -25 l-25 -24 0 -333 c1 -246 4 -337 13 -349 17 -23 52 -39 81 -39 26 0 26 0 26 -84 0 -88 6 -102 40 -91 18 6 20 15 20 91 l0 84 29 0 c17 0 42 10 58 24 l28 24 0 335 0 335 -24 26 c-17 18 -35 26 -57 26 l-33 0 -3 88 c-3 80 -5 87 -25 90 -11 2 -24 -2 -28 -9z m103 -548 c2 -245 -1 -316 -10 -323 -19 -11 -116 -10 -139 2 -18 10 -19 24 -19 310 0 164 3 306 6 315 5 13 20 15 83 13 l76 -3 3 -314z" />
      
      <path
       d="M967 983 c-4 -3 -7 -30 -7 -60 l0 -53 -33 0 c-23 0 -41 -8 -58 -26 -22 -24 -24 -34 -27 -157 -4 -160 5 -198 52 -221 19 -9 41 -16 50 -16 12 0 16 -11 16 -48 0 -65 22 -88 54 -57 11 12 14 28 10 58 l-6 42 49 15 c67 21 73 36 73 213 0 144 -1 149 -25 172 -17 18 -35 25 -61 25 l-37 0 5 48 c3 26 4 53 1 60 -5 13 -45 17 -56 5z m108 -195 c3 -13 4 -77 3 -143 l-3 -120 -82 -3 -83 -3 0 139 c0 76 3 142 7 145 3 4 39 7 79 7 68 0 74 -2 79 -22z" />
      
      <path
      d="M142 828 c-7 -7 -12 -36 -12 -65 l0 -53 -33 0 c-74 0 -92 -52 -85 -246 3 -113 4 -117 31 -140 16 -13 41 -24 57 -24 28 0 29 -1 32 -57 3 -52 5 -58 27 -61 32 -5 42 14 35 71 l-7 47 37 0 c27 0 44 7 62 27 25 27 25 28 22 184 -3 178 -10 195 -77 201 l-36 3 0 53 c0 36 -5 56 -15 62 -20 13 -24 12 -38 -2z m103 -323 l0 -140 -80 0 -80 0 -3 129 c-1 72 0 136 3 143 3 10 24 13 82 11 l78 -3 0 -140z" />
    </g>
  </motion.svg>
);
export {EfficientSvg1};
