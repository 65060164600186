import React from 'react'
import { Card, Flex, Typography } from 'antd'
import Marquee from 'react-fast-marquee'

const { Title } = Typography
const MarqueeBrands = () => {

    const data = [
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        },
        {
            img:'logo.png'
        }
    ]

  return (
    <div className='padding'>
        <Flex vertical gap={40}>
        <Marquee 
            gradientWidth={200} 
            gradient={true} 
            gradientColor='rgba(24, 35, 47, 1), rgba(24, 35, 47, .5)'
        >
            {
                data?.map((items,index)=>
                    <Card className='bg-transparent card-cs mx-2' key={index}>
                        <Flex align='center' gap={20}> 
                            <img src={'/assets/images/'+items?.img} width={30} alt=''/>
                            <Title level={5} className='m-0 text-white'>LogoIspum</Title>
                        </Flex>
                    </Card>
                )
            }
        </Marquee> 
        <Marquee 
            gradientWidth={200} 
            gradient={true} 
            gradientColor='rgba(24, 35, 47, 1), rgba(24, 35, 47, 0)'
            direction='right'
        >
            {
                data?.map((items,index)=>
                    <Card className='bg-transparent card-cs mx-2' key={index}>
                        <Flex align='center' gap={20}> 
                            <img src={'/assets/images/'+items?.img} width={30} alt='' />
                            <Title level={5} className='m-0 text-white'>LogoIspum</Title>
                        </Flex>
                    </Card>
                )
            }
        </Marquee> 
        </Flex>
    </div>
  )
}

export {MarqueeBrands}