import React from 'react'
import {motion} from 'framer-motion'
const ShieldBlockchainSvg = () => {
    return (
      <motion.svg
  initial={{}}
  animate={{opacity:1,
    // rotateY:360
  }}
  transition={{duration:4,
    repeat:Infinity,
    ease:'linear'
  }}
    xmlns="http://www.w3.org/2000/svg"
    width="120.000000pt"
    height="125.000000pt"
    viewBox="0 0 120.000000 125.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,125.000000) scale(0.100000,-0.100000)"
      fill="#000"
      stroke="none"
    >
      <motion.path
      initial={{opacity:1}}
      animate={{
        fill:["#fff", "#000", "#fff", ]
      }}
      transition={{
        duration:.4,
        repeat:Infinity,
        ease:'linear'
      }}


      d="M535 1240 c-61 -9 -97 -24 -214 -88 -51 -28 -128 -65 -173 -83 -97 -39 -102 -48 -118 -208 -36 -356 106 -649 390 -808 67 -37 130 -58 139 -47 8 12 91 13 91 2 0 -28 178 60 265 131 197 162 296 431 266 724 -14 140 -26 168 -82 191 -24 9 -116 53 -204 97 -153 77 -264 112 -273 88 -2 -5 -13 -9 -23 -9 -11 0 -17 5 -14 10 3 6 5 9 3 9 -2 -1 -25 -5 -53 -9z m195 -79 c25 -10 90 -43 145 -74 54 -31 127 -66 162 -77 58 -20 63 -24 70 -58 4 -20 8 -107 8 -192 0 -135 -3 -165 -23 -230 -65 -206 -179 -342 -361 -433 -108 -54 -140 -54 -245 -4 -186 88 -302 227 -368 437 -20 66 -23 95 -23 240 0 203 4 213 91 244 33 12 92 40 130 61 177 100 229 118 319 108 28 -3 70 -13 95 -22z" />
      <motion.path
      initial={{scale:0.4}}
      animate={{
        scale:1.1,
        rotate:360
      }}
      transition={{
        duration:3,
        ease:"linear",
        repeat:Infinity
      }}
      d="M478 890 c-64 -33 -122 -69 -128 -79 -5 -11 -10 -84 -10 -168 0 -119 3 -153 16 -171 18 -26 223 -132 254 -132 26 0 217 95 248 123 21 20 22 27 22 183 l0 163 -27 21 c-37 29 -221 120 -241 119 -10 0 -70 -27 -134 -59z m332 -110 c0 -3 -44 -27 -98 -54 l-98 -48 -102 48 c-56 27 -102 51 -102 54 0 3 46 28 102 56 l101 50 98 -50 c54 -28 99 -53 99 -56z m-230 -261 c0 -56 -4 -99 -8 -97 -4 2 -43 21 -85 43 l-77 40 0 101 0 101 85 -44 85 -44 0 -100z m240 88 l0 -103 -85 -42 c-47 -23 -87 -42 -90 -42 -3 0 -5 45 -5 100 l0 100 88 44 c48 24 88 44 90 45 1 0 2 -45 2 -102z" />
    </g>
  </motion.svg>
  )
}

export {ShieldBlockchainSvg}