import React, { useRef } from "react";
import { Button, Card, Col, Flex, Image, Row, Typography } from "antd";
import { motion, useScroll, useTransform } from "framer-motion";
import { Typewriter } from "../../PageComponents";
import { BlockChainSvg1, WhySecuritySvg, WhySecuritySvg1 } from "../../../SvgsFile";
const { Text, Title } = Typography;

const WhySecurity = () => {
  // const targetRef = useRef(null)
  // const { scrollYProgress } = useScroll({
  //     target: targetRef,
  //     offset:["end end", "end start"]
  // })
  // const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
  // const scale = useTransform( scrollYProgress, [0, .5], [1, 1.5] )

  const lineVariants = {
    animate: {
      strokeDasharray: [0, 1000], 
      transition: {
        duration: 3,                
        ease: "easeInOut",          
        repeat: Infinity,          
        repeatType: "loop",         
      },
    },
  };

  const messages = [
    'Digital World'
  ]

  return (
    <div
      className="padding"
      // ref={targetRef}
      // style={{
      //     opacity,
      //     scale,
      // }}
    >

      
      <Row gutter={[24, 24]} align={"middle"}>
        <Col
          lg={{ span: 14 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Flex vertical gap={30}>
            <Flex gap={10}>
            {/* <motion.div
              className="sm-icon"
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                delay: 0.3,
                duration: .3,
                type: "spring",
                stiffness: 300,
              }}
              viewport={{ once: true }}
              style={{width: 70, height:70}}
            >
              <WhySecuritySvg />
            </motion.div>
            <motion.div className='grad-line'
              initial={{ y: 100, opacity: 0,}}
              whileInView={{
                  opacity: 1,
                  y: 0,
              }}
              transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
              viewport={{once: true}}
            ></motion.div> */}
            <motion.div
              className="sm-icon"
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                delay: 0.3,
                duration: .3,
                type: "spring",
                stiffness: 300,
              }}
              viewport={{ once: true }}
              style={{width: 40, height:40}}
            >
              <WhySecuritySvg />
            </motion.div>
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                viewport={{once: true}}
            >
                
                <Title level={1} className='m-0 gradient-text'>
                    <Typewriter messages={messages} speed={150} />
                </Title>
            </motion.div>
            </Flex>
            
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                delay: 0.2,
                duration: .5,
                type: "spring",
                stiffness: 300,
              }}
              viewport={{ once: true }}
            >
              <Text className="text-gray fs-16">
                Mozeff leverages powerful encryption and blockchain technology
                to provide industry-leading protection for your sensitive data.
              </Text>
            </motion.div>
            <motion.Flex
              initial={{ y: "100%", opacity: 0 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{ delay: 0.2, duration: .6 }}
              viewport={{ once: true }}
            >
              <Button className='btn w-auto'>
                <div className='content vertical '>
                  <div className="text">Learn More</div>
                  <div className="hover-text">Learn More</div>
                </div>
              </Button>
            </motion.Flex>
          </Flex>
        </Col>
        <Col
          lg={{ span: 10 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card className="card-grad card-extra">
            <div style={{width: 250}}>
              <BlockChainSvg1 />
            </div>
            <Title level={5} className="text-white m-0">
              Blockchain: Redefining trust in the digital world.
            </Title>
            <div className='parent-shap'>
              <div className='bg-shape'></div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export { WhySecurity };
