import React from "react";
import { motion } from "framer-motion";

const CryptoCurrencySvg = () => {
  return (
    <>
      <motion.svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
        // enable-background="new 0 0 512 512"
        // xml:space="preserve"
      >
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M1.000002,250.000000
	C1.000000,167.035675 1.000000,84.071358 1.000000,1.053519
	C171.557602,1.053519 342.115234,1.053519 513.000000,1.053519
	C513.000000,83.688393 513.000000,166.377213 512.760010,249.879395
	C509.138641,246.690399 504.838531,245.841888 499.697540,245.891495
	C475.067108,246.129257 450.433167,245.998215 425.800446,245.998215
	C424.026917,245.998215 422.253387,245.998215 420.444824,245.998215
	C420.444824,253.611954 420.444824,260.661346 420.444824,268.001770
	C422.539612,268.001770 424.348541,268.001770 426.157471,268.001770
	C448.626495,268.001770 471.095551,268.021606 493.564514,267.989594
	C500.266602,267.980042 507.333221,269.408417 512.715088,263.589935
	C512.810059,263.726624 512.905029,263.863312 513.000000,264.000000
	C513.000000,346.964325 513.000000,429.928619 513.000000,512.946472
	C342.442383,512.946472 171.884766,512.946472 1.000000,512.946472
	C1.000000,430.311615 1.000000,347.622772 1.239671,264.120667
	C4.864761,267.300842 9.160881,268.158936 14.303879,268.109009
	C38.926414,267.869965 63.552551,268.001770 88.177406,268.001770
	C89.952034,268.001770 91.726662,268.001770 93.553452,268.001770
	C93.553452,260.400970 93.553452,253.353165 93.553452,245.998215
	C91.463898,245.998215 89.657372,245.998215 87.850845,245.998215
	C65.388985,245.998215 42.927097,245.979721 20.465273,246.009537
	C13.754854,246.018448 6.686028,244.602554 1.290761,250.405731
	C1.193843,250.270493 1.096923,250.135239 1.000002,250.000000
M306.161499,43.395245
	C306.109070,61.930817 306.046814,80.466385 306.045471,99.001961
	C306.045441,99.643768 306.653992,100.285629 307.503571,101.429977
	C314.582947,104.171135 321.662323,106.912285 329.001007,109.753830
	C329.001007,87.489883 329.007629,65.501648 328.997070,43.513409
	C328.993835,36.776283 324.689026,32.174545 318.284485,31.999962
	C311.971863,31.827887 307.437744,36.197178 306.161499,43.395245
M207.838333,470.604980
	C207.890839,452.069641 207.953171,433.534271 207.954926,414.998932
	C207.954987,414.352112 207.351547,413.705261 206.539368,412.518250
	C199.475250,409.790527 192.411133,407.062805 184.998932,404.200684
	C184.998932,426.532196 184.991058,448.583069 185.003738,470.633942
	C185.007538,477.241577 189.438263,481.877014 195.798584,482.000000
	C202.048767,482.120850 206.572342,477.729675 207.838333,470.604980
M306.986847,471.822327
	C307.841980,478.211456 312.502014,482.313812 318.546997,481.999054
	C324.673920,481.679993 328.988892,477.052704 328.995789,470.717743
	C329.012360,455.557709 329.001099,440.397675 329.001099,425.237640
	C329.001099,418.304260 329.001099,411.370911 329.001099,404.048798
	C321.328339,407.192749 314.153595,410.132660 306.172089,413.399536
	C306.116028,431.934082 306.050262,450.468597 306.042480,469.003174
	C306.042206,469.682526 306.609741,470.362183 306.986847,471.822327
M207.829819,100.600174
	C207.885239,82.065681 207.950378,63.531189 207.957535,44.996677
	C207.957794,44.317307 207.390167,43.637718 207.013107,42.177677
	C206.158539,35.788883 201.483261,31.677992 195.439072,32.000813
	C189.321518,32.327553 185.011292,36.964554 185.004166,43.300713
	C184.987671,57.956055 184.998886,72.611427 184.998871,87.266792
	C184.998871,94.692810 184.998886,102.118820 184.998886,109.957184
	C192.689590,106.809898 199.859512,103.875740 207.829819,100.600174
M261.494415,185.000000
	C253.735382,185.000000 245.976364,185.000000 237.999664,185.000000
	C237.999664,178.342865 238.014893,172.361313 237.995804,166.379868
	C237.971985,158.918823 233.309875,154.077499 226.394638,153.964355
	C220.667725,153.870667 214.467728,159.231430 214.923752,166.587311
	C215.299698,172.651596 215.000504,178.757751 215.000504,185.000137
	C207.945740,185.000137 201.623474,184.983093 195.301331,185.004715
	C188.382935,185.028381 185.008072,188.598129 185.000092,195.848541
	C184.991653,203.491989 188.267502,206.980423 195.502975,206.996918
	C201.930313,207.011566 208.357712,207.000000 214.626617,207.000000
	C214.626617,240.731094 214.626617,273.784424 214.626617,307.000000
	C210.261932,307.000000 206.270493,307.000000 202.279053,307.000000
	C188.043808,307.000000 185.008835,308.908966 185.000046,317.868439
	C184.991089,327.000183 188.050461,329.000000 202.029449,329.000000
	C206.285782,329.000000 210.542130,329.000000 215.000565,329.000000
	C215.000565,335.012543 215.000534,340.330109 215.000565,345.647705
	C215.000626,355.097107 219.017654,360.057861 226.622543,360.000092
	C234.139297,359.942993 237.999374,354.988281 237.999405,345.396881
	C237.999420,339.975983 237.999405,334.555084 237.999405,329.238281
	C251.021820,329.238281 263.294464,329.238281 276.000641,329.238281
	C276.000641,335.923370 275.917267,342.381012 276.024445,348.835510
	C276.128662,355.112305 281.095459,359.928680 287.311340,360.000031
	C293.796265,360.074463 298.856567,355.239197 298.981140,348.706970
	C299.073273,343.876617 299.326202,339.014618 298.908295,334.218933
	C298.481995,329.327057 299.841858,326.726929 305.027679,325.722504
	C307.702118,325.204498 310.089569,322.867371 312.454407,321.143341
	C327.833069,309.932098 331.875458,294.239532 327.819153,277.301727
	C326.063049,269.968933 321.437531,263.323273 317.850494,255.810654
	C325.341095,247.947983 329.294830,237.741852 329.197876,225.300644
	C329.064789,208.227448 317.654236,192.783951 302.151123,186.940231
	C300.805786,186.433105 299.229980,184.448044 299.142303,183.062119
	C298.795807,177.585434 298.708801,172.054672 299.071106,166.580063
	C299.556793,159.241119 293.348480,153.876877 287.585754,153.964539
	C280.693115,154.069366 276.027832,158.927261 276.004395,166.391632
	C275.985229,172.492722 276.000488,178.593933 276.000488,185.000000
	C271.100555,185.000000 266.795654,185.000000 261.494415,185.000000
M396.527252,153.998611
	C392.667358,153.998611 388.807434,153.998611 384.475586,153.998611
	C389.170959,161.047897 393.471619,167.156738 397.363190,173.515930
	C398.949890,176.108795 400.612061,177.092102 403.667328,177.073700
	C425.976898,176.939377 448.287598,177.011993 470.597961,176.995651
	C478.450867,176.989899 482.989868,172.381241 482.997070,164.438461
	C483.010803,149.287415 483.000854,134.136337 483.000763,118.985268
	C483.000763,117.392426 483.000732,115.799576 483.000732,114.826530
	C475.036041,114.826530 467.678955,114.826530 459.852081,114.826530
	C459.852081,127.581047 459.852081,140.648956 459.852081,153.998611
	C438.758301,153.998611 418.137787,153.998611 396.527252,153.998611
M445.500000,336.998627
	C431.514893,336.998627 417.529602,336.965271 403.544952,337.046143
	C402.010437,337.054993 399.740570,337.200928 399.065521,338.163025
	C394.172882,345.136108 389.602509,352.335327 384.456055,360.186218
	C410.098022,360.186218 434.953583,360.186218 460.274414,360.186218
	C460.274414,373.740387 460.274414,386.768463 460.274414,399.171265
	C468.001373,399.171265 475.253082,399.171265 483.000702,399.171265
	C483.000702,398.312775 483.000702,396.850769 483.000702,395.388763
	C483.000793,380.071747 483.012726,364.754730 482.996033,349.437714
	C482.987457,341.581940 478.380798,337.017853 470.474457,337.002106
	C462.483002,336.986206 454.491486,336.998627 445.500000,336.998627
M41.081738,176.998123
	C61.234245,176.999222 81.410583,176.423859 101.528748,177.255600
	C110.947205,177.644989 117.133049,175.459778 121.066170,166.599564
	C122.997162,162.249542 126.481041,158.588882 129.770752,153.889740
	C103.940933,153.889740 79.192680,153.889740 54.003769,153.889740
	C54.003769,140.452377 54.003769,127.364861 54.003769,114.819305
	C46.177444,114.819305 38.796406,114.819305 30.999147,114.819305
	C30.999147,130.468475 31.178682,146.600677 30.916822,162.725693
	C30.801006,169.857498 33.174126,174.788330 41.081738,176.998123
M30.999224,381.499146
	C30.999224,387.595215 30.999224,393.691284 30.999224,399.168457
	C38.959209,399.168457 46.316570,399.168457 54.148754,399.168457
	C54.148754,386.410614 54.148754,373.342407 54.148754,359.707397
	C79.435562,359.707397 104.246956,359.707397 129.349762,359.707397
	C124.845512,352.958038 120.534035,346.855988 116.644844,340.495728
	C115.059692,337.903412 113.421173,336.907074 110.360878,336.925659
	C88.052307,337.061310 65.742523,336.988953 43.433144,337.003815
	C35.574314,337.009064 31.014942,341.616730 31.002823,349.532623
	C30.987022,359.854858 30.999126,370.177124 30.999224,381.499146
z"
        />
        <motion.path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M1.000002,250.464645
	C1.096923,250.135239 1.193843,250.270493 1.290761,250.405731
	C6.686028,244.602554 13.754854,246.018448 20.465273,246.009537
	C42.927097,245.979721 65.388985,245.998215 87.850845,245.998215
	C89.657372,245.998215 91.463898,245.998215 93.553452,245.998215
	C93.553452,253.353165 93.553452,260.400970 93.553452,268.001770
	C91.726662,268.001770 89.952034,268.001770 88.177406,268.001770
	C63.552551,268.001770 38.926414,267.869965 14.303879,268.109009
	C9.160881,268.158936 4.864761,267.300842 1.239671,263.653687
	C1.000000,259.643097 1.000000,255.286194 1.000002,250.464645
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,263.535339
	C512.905029,263.863312 512.810059,263.726624 512.715088,263.589935
	C507.333221,269.408417 500.266602,267.980042 493.564514,267.989594
	C471.095551,268.021606 448.626495,268.001770 426.157471,268.001770
	C424.348541,268.001770 422.539612,268.001770 420.444824,268.001770
	C420.444824,260.661346 420.444824,253.611954 420.444824,245.998215
	C422.253387,245.998215 424.026917,245.998215 425.800446,245.998215
	C450.433167,245.998215 475.067108,246.129257 499.697540,245.891495
	C504.838531,245.841888 509.138641,246.690399 512.760010,250.346375
	C513.000000,254.356903 513.000000,258.713806 513.000000,263.535339
z"
        />
        <motion.path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          initial={{
            scale: 0.9,
          }}
          animate={{
            scale: 1.4,
          }}
          transition={{
            duration: 1,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse",
          }}
          d="
M261.992554,185.000000
	C266.795654,185.000000 271.100555,185.000000 276.000488,185.000000
	C276.000488,178.593933 275.985229,172.492722 276.004395,166.391632
	C276.027832,158.927261 280.693115,154.069366 287.585754,153.964539
	C293.348480,153.876877 299.556793,159.241119 299.071106,166.580063
	C298.708801,172.054672 298.795807,177.585434 299.142303,183.062119
	C299.229980,184.448044 300.805786,186.433105 302.151123,186.940231
	C317.654236,192.783951 329.064789,208.227448 329.197876,225.300644
	C329.294830,237.741852 325.341095,247.947983 317.850494,255.810654
	C321.437531,263.323273 326.063049,269.968933 327.819153,277.301727
	C331.875458,294.239532 327.833069,309.932098 312.454407,321.143341
	C310.089569,322.867371 307.702118,325.204498 305.027679,325.722504
	C299.841858,326.726929 298.481995,329.327057 298.908295,334.218933
	C299.326202,339.014618 299.073273,343.876617 298.981140,348.706970
	C298.856567,355.239197 293.796265,360.074463 287.311340,360.000031
	C281.095459,359.928680 276.128662,355.112305 276.024445,348.835510
	C275.917267,342.381012 276.000641,335.923370 276.000641,329.238281
	C263.294464,329.238281 251.021820,329.238281 237.999405,329.238281
	C237.999405,334.555084 237.999420,339.975983 237.999405,345.396881
	C237.999374,354.988281 234.139297,359.942993 226.622543,360.000092
	C219.017654,360.057861 215.000626,355.097107 215.000565,345.647705
	C215.000534,340.330109 215.000565,335.012543 215.000565,329.000000
	C210.542130,329.000000 206.285782,329.000000 202.029449,329.000000
	C188.050461,329.000000 184.991089,327.000183 185.000046,317.868439
	C185.008835,308.908966 188.043808,307.000000 202.279053,307.000000
	C206.270493,307.000000 210.261932,307.000000 214.626617,307.000000
	C214.626617,273.784424 214.626617,240.731094 214.626617,207.000000
	C208.357712,207.000000 201.930313,207.011566 195.502975,206.996918
	C188.267502,206.980423 184.991653,203.491989 185.000092,195.848541
	C185.008072,188.598129 188.382935,185.028381 195.301331,185.004715
	C201.623474,184.983093 207.945740,185.000137 215.000504,185.000137
	C215.000504,178.757751 215.299698,172.651596 214.923752,166.587311
	C214.467728,159.231430 220.667725,153.870667 226.394638,153.964355
	C233.309875,154.077499 237.971985,158.918823 237.995804,166.379868
	C238.014893,172.361313 237.999664,178.342865 237.999664,185.000000
	C245.976364,185.000000 253.735382,185.000000 261.992554,185.000000
M255.501434,207.000000
	C249.750015,207.000000 243.998596,207.000000 238.336426,207.000000
	C238.336426,220.356125 238.336426,233.061691 238.336426,245.002441
	C255.872330,245.002441 273.096497,245.631958 290.247589,244.783630
	C301.900848,244.207230 308.989868,231.881866 305.166718,220.728806
	C301.620331,210.383011 294.811523,207.729172 284.428680,207.115585
	C275.149475,206.567215 265.812317,207.000092 255.501434,207.000000
M267.499725,268.000000
	C257.756683,268.000000 248.013611,268.000000 238.327103,268.000000
	C238.327103,281.337158 238.327103,294.042450 238.327103,306.002838
	C255.851151,306.002838 273.044128,306.178040 290.230835,305.925476
	C298.263367,305.807434 306.264648,296.762756 306.260376,287.488129
	C306.256104,278.276550 298.292145,269.492615 290.207123,269.016602
	C282.976349,268.590851 275.735718,268.332336 267.499725,268.000000
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M397.022278,153.998611
	C418.137787,153.998611 438.758301,153.998611 459.852081,153.998611
	C459.852081,140.648956 459.852081,127.581047 459.852081,114.826530
	C467.678955,114.826530 475.036041,114.826530 483.000732,114.826530
	C483.000732,115.799576 483.000763,117.392426 483.000763,118.985268
	C483.000854,134.136337 483.010803,149.287415 482.997070,164.438461
	C482.989868,172.381241 478.450867,176.989899 470.597961,176.995651
	C448.287598,177.011993 425.976898,176.939377 403.667328,177.073700
	C400.612061,177.092102 398.949890,176.108795 397.363190,173.515930
	C393.471619,167.156738 389.170959,161.047897 384.475586,153.998611
	C388.807434,153.998611 392.667358,153.998611 397.022278,153.998611
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M446.000000,336.998627
	C454.491486,336.998627 462.483002,336.986206 470.474457,337.002106
	C478.380798,337.017853 482.987457,341.581940 482.996033,349.437714
	C483.012726,364.754730 483.000793,380.071747 483.000702,395.388763
	C483.000702,396.850769 483.000702,398.312775 483.000702,399.171265
	C475.253082,399.171265 468.001373,399.171265 460.274414,399.171265
	C460.274414,386.768463 460.274414,373.740387 460.274414,360.186218
	C434.953583,360.186218 410.098022,360.186218 384.456055,360.186218
	C389.602509,352.335327 394.172882,345.136108 399.065521,338.163025
	C399.740570,337.200928 402.010437,337.054993 403.544952,337.046143
	C417.529602,336.965271 431.514893,336.998627 446.000000,336.998627
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M40.644844,176.987061
	C33.174126,174.788330 30.801006,169.857498 30.916822,162.725693
	C31.178682,146.600677 30.999147,130.468475 30.999147,114.819305
	C38.796406,114.819305 46.177444,114.819305 54.003769,114.819305
	C54.003769,127.364861 54.003769,140.452377 54.003769,153.889740
	C79.192680,153.889740 103.940933,153.889740 129.770752,153.889740
	C126.481041,158.588882 122.997162,162.249542 121.066170,166.599564
	C117.133049,175.459778 110.947205,177.644989 101.528748,177.255600
	C81.410583,176.423859 61.234245,176.999222 40.644844,176.987061
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M30.999224,380.999268
	C30.999126,370.177124 30.987022,359.854858 31.002823,349.532623
	C31.014942,341.616730 35.574314,337.009064 43.433144,337.003815
	C65.742523,336.988953 88.052307,337.061310 110.360878,336.925659
	C113.421173,336.907074 115.059692,337.903412 116.644844,340.495728
	C120.534035,346.855988 124.845512,352.958038 129.349762,359.707397
	C104.246956,359.707397 79.435562,359.707397 54.148754,359.707397
	C54.148754,373.342407 54.148754,386.410614 54.148754,399.168457
	C46.316570,399.168457 38.959209,399.168457 30.999224,399.168457
	C30.999224,393.691284 30.999224,387.595215 30.999224,380.999268
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M207.029419,100.941589
	C199.859512,103.875740 192.689590,106.809898 184.998886,109.957184
	C184.998886,102.118820 184.998871,94.692810 184.998871,87.266792
	C184.998886,72.611427 184.987671,57.956055 185.004166,43.300713
	C185.011292,36.964554 189.321518,32.327553 195.439072,32.000813
	C201.483261,31.677992 206.158539,35.788883 207.022583,43.036900
	C207.031189,62.911278 207.030304,81.926437 207.029419,100.941589
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M306.978882,413.072540
	C314.153595,410.132660 321.328339,407.192749 329.001099,404.048798
	C329.001099,411.370911 329.001099,418.304260 329.001099,425.237640
	C329.001099,440.397675 329.012360,455.557709 328.995789,470.717743
	C328.988892,477.052704 324.673920,481.679993 318.546997,481.999054
	C312.502014,482.313812 307.841980,478.211456 306.977325,470.963074
	C306.971497,451.093384 306.975189,432.082947 306.978882,413.072540
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M207.087280,471.041687
	C206.572342,477.729675 202.048767,482.120850 195.798584,482.000000
	C189.438263,481.877014 185.007538,477.241577 185.003738,470.633942
	C184.991058,448.583069 184.998932,426.532196 184.998932,404.200684
	C192.411133,407.062805 199.475250,409.790527 206.773895,413.252991
	C207.034714,433.005737 207.061005,452.023712 207.087280,471.041687
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M306.912506,42.958572
	C307.437744,36.197178 311.971863,31.827887 318.284485,31.999962
	C324.689026,32.174545 328.993835,36.776283 328.997070,43.513409
	C329.007629,65.501648 329.001007,87.489883 329.001007,109.753830
	C321.662323,106.912285 314.582947,104.171135 307.249207,100.714798
	C306.967377,80.985939 306.939941,61.972256 306.912506,42.958572
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M306.536987,43.176910
	C306.939941,61.972256 306.967377,80.985939 306.986847,100.463547
	C306.653992,100.285629 306.045441,99.643768 306.045471,99.001961
	C306.046814,80.466385 306.109070,61.930817 306.536987,43.176910
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M207.429626,100.770882
	C207.030304,81.926437 207.031189,62.911278 207.059631,43.427177
	C207.390167,43.637718 207.957794,44.317307 207.957535,44.996677
	C207.950378,63.531189 207.885239,82.065681 207.429626,100.770882
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M207.462799,470.823334
	C207.061005,452.023712 207.034714,433.005737 207.018921,413.523071
	C207.351547,413.705261 207.954987,414.352112 207.954926,414.998932
	C207.953171,433.534271 207.890839,452.069641 207.462799,470.823334
z"
        />
        <path
          fill="#FFF"
          opacity="1.000000"
          stroke="none"
          d="
M306.575500,413.236023
	C306.975189,432.082947 306.971497,451.093384 306.940247,470.572754
	C306.609741,470.362183 306.042206,469.682526 306.042480,469.003174
	C306.050262,450.468597 306.116028,431.934082 306.575500,413.236023
z"
        />
        <path
          fill="#000"
          opacity="1.000000"
          stroke="none"
          d="
M256.001099,207.000000
	C265.812317,207.000092 275.149475,206.567215 284.428680,207.115585
	C294.811523,207.729172 301.620331,210.383011 305.166718,220.728806
	C308.989868,231.881866 301.900848,244.207230 290.247589,244.783630
	C273.096497,245.631958 255.872330,245.002441 238.336426,245.002441
	C238.336426,233.061691 238.336426,220.356125 238.336426,207.000000
	C243.998596,207.000000 249.750015,207.000000 256.001099,207.000000
z"
        />
        <path
          fill="#000"
          opacity="1.000000"
          stroke="none"
          d="
M267.999573,268.000000
	C275.735718,268.332336 282.976349,268.590851 290.207123,269.016602
	C298.292145,269.492615 306.256104,278.276550 306.260376,287.488129
	C306.264648,296.762756 298.263367,305.807434 290.230835,305.925476
	C273.044128,306.178040 255.851151,306.002838 238.327103,306.002838
	C238.327103,294.042450 238.327103,281.337158 238.327103,268.000000
	C248.013611,268.000000 257.756683,268.000000 267.999573,268.000000
z"
        />
      </motion.svg>
    </>
  );
};

export {CryptoCurrencySvg};
