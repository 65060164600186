import React from 'react'
import { Button, Card, Col, Flex, Image, Row, Space, Typography } from 'antd'
import { UnderlineDesign } from '../UnderlineDesign'

const { Text, Title } = Typography
const PricingCards = () => {

    const data = [
        {
            subtitle:'Silver',
            title:'$1000',
            list:[
                'Transactions, once recorded',
                'Ensures trust ',
                'Provides a tamper-proof',
            ],
        },
        {
            subtitle:'Gold',
            title:'$2000',
            list:[
                'Transactions, once recorded',
                'Ensures trust ',
                'Provides a tamper-proof',
            ],
        },
        {
            subtitle:'Diamond',
            title:'$3000',
            list:[
                'Transactions, once recorded',
                'Ensures trust ',
                'Provides a tamper-proof',
            ],
        },
    ]
    

  return (
    <Row gutter={[24,24]} className='padding'>
        <Col span={24}>
            <Flex vertical align='center' gap={10} className='mb-3'>
                <Title className='gradient-text text-center m-0'>
                    Package Pricing
                </Title>
                <UnderlineDesign />
                <Text className='text-gray'>
                    Your Personalised Incubator
                </Text>
            </Flex>
        </Col>
        {
            data?.map((data)=>
                <Col lg={{span:8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                    <Card className='bg-transparent border0 rounded shadow card-design h-100' >
                        <Flex vertical gap={15}>
                            <Flex vertical gap={10}>
                                <Title level={4} className='text-gray m-0'>{data?.subtitle}</Title>
                                <UnderlineDesign />
                            </Flex>
                            <Flex vertical gap={15}>
                                <Title level={1} className='gradient-text m-0'>
                                    {
                                        data?.title
                                    }
                                    <span style={{fontSize: 21}}>
                                        /<sub >month</sub>
                                    </span>
                                </Title>
                                <Flex vertical>
                                    {data?.list.map((item)=> (
                                        <Space size={12} align='start' className='mb-1'>
                                            <Image src="/assets/icons/check.png" alt="check" width={12} preview={false} />
                                            <Text className='text-gray'>{item}</Text>
                                        </Space>
                                    ))}
                                </Flex>
                                <Button className='btn w-auto mt-3'>
                                    <div className='content vertical '>
                                        <div className="text">Select Plan</div>
                                        <div className="hover-text">Select Plan</div>
                                    </div>
                                </Button>
                            </Flex>
                        </Flex>
                    </Card>
                </Col>
            )
        }
    </Row>
  )
}

export {PricingCards}