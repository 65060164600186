import React from 'react'
import { MyInput } from '../../PageComponents'
import { Avatar, Card, Col, Divider, Flex, Image, Input, List, Row, Space, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { blogData } from '../../../data'
import { Link } from 'react-router-dom'

const { Title , Text } = Typography
const BlogSider = () => {

  const categories = [
    { name: 'Bitcoin', count: '(9)' },
    { name: 'Web 3.0', count: '(5)' },
    { name: 'NFT', count: '(3)' },
    { name: 'Crypto', count: '(4)' }
  ];

  return (
    <div className='py-4'>
      <Row>
        <Col span={24}>
          {/* <MyInput 
            name='search'
            message='Please enter search'
            placeholder='Search'
            suffix={<SearchOutlined size='large' />}
          /> */}
          <Input 
            className='custom-input placeholder-invert' 
            suffix={<SearchOutlined style={{fontSize:16}} />} 
            placeholder='Search blog ...'
          />
        </Col>
        <Col span={24}>
          <Divider className='bg-divider' />
        </Col>
        <Col span={24}>
          <Card className='blog-about-card'>
            <Space size={10} direction='vertical'>
              <Title level={5} className='m-0 text-gray'>ABOUT</Title>
              <Space size={10}>
                <Avatar size={55} src='/assets/images/logo-r.png'  />
                <Space direction='vertical' size={0}>
                  <Title className='m-0 text-white' level={4}>Mozeff</Title>
                  <Text className='fs-12 text-gray'>Web 3.0 Consulting Services</Text>
                </Space>
              </Space>
              <Text className='text-gray text-justify'>Web 3.0 Consulting helps businesses embrace the future of the internet with blockchain, smart contracts, and decentralized technologies. We provide tailored solutions for dApps, NFTs, and metaverse integration, empowering you to innovate and lead in the evolving digital landscape.</Text>
              <Space className='text-white' align='center'>
                <img src='/assets/icons/map.png' width={20} height={20} />
                Ukraine, Odesso, 65058
              </Space>
              <Flex gap={10}>
                  <Link to={'/'}>
                      <Image src='/assets/icons/twitter.png' width={'23px'} preview={false} />
                  </Link>
                  <Link to={'/'}>
                      <Image src='/assets/icons/facebook.png' width={'23px'} preview={false} />
                  </Link>
                  <Link to={'/'}>
                      <Image src='/assets/icons/linkedin.png' width={'23px'} preview={false} />
                  </Link>
                  <Link to={'/'}>
                      <Image src='/assets/icons/insta.png' width={'23px'} preview={false} />
                  </Link>
              </Flex>
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Divider className='bg-divider' />
        </Col>
        <Col span={24}>
          <Card className='blog-about-card'>
            <Space direction='vertical w-100'>
              <Title level={4} className='text-white'>CATEGORIES</Title>
              <List
                className='text-white sider-list'
                dataSource={categories}
                renderItem={(item) => (
                  <Space className='w-100 space-btw'>
                    <Text className='fs-500 fs-16 text-white'>{item.name}</Text>
                    <Text className='fs-500 fs-16 text-white'>{item.count}</Text>
                  </Space>
                )}
              />
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Divider className='bg-divider' />
        </Col>
        <Col span={24}>
          <Space direction='vertical'>
            <Title level={4} className='text-white'>Recent Posts</Title>
            <List
              className='text-white sider-list'
              dataSource={blogData}
              renderItem={(item) => (
                <List.Item>
                  <Space>
                    <Row gutter={[16, 16]}>
                      <Col span={10}>
                        <div>
                          <Image src={item.image} alt={item.title} preview={false} />
                        </div>
                      </Col>
                      <Col span={14}>
                        <Space className='w-100 h-100 ' direction='vertical'>
                          <Text className='text-gray'>{item.date}</Text>
                          <Title  className='m-0 text-white' level={4}>{item.title}</Title>
                        </Space>
                      </Col>
                    </Row>
                  </Space>
                </List.Item>
              )}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Divider className='bg-divider' />
        </Col>
        <Col span={24}>
          <Space direction='vertical'>
            <Title level={4} className='text-white'>Popular Tags</Title>
            <Space wrap>
              <Tag className='blog-tag'>Bitcoin</Tag>
              <Tag className='blog-tag'>Web 2</Tag>
              <Tag className='blog-tag'>Nft</Tag>
              <Tag className='blog-tag'>Crypto</Tag>
              <Tag className='blog-tag'>Web 3</Tag>
              <Tag className='blog-tag'>Blockchain</Tag>
              <Tag className='blog-tag'>Web 2</Tag>
              <Tag className='blog-tag'>Crypto</Tag>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <Divider className='bg-divider' />
        </Col>
        <Col span={24}>
          <Space direction='vertical'>
            <Title level={4} className='text-white'>Popular Posts</Title>
            <List
              className='text-white sider-list'
              dataSource={blogData}
              renderItem={(item) => (
                <List.Item>
                  <Space>
                    <Row gutter={[16, 16]}>
                      <Col span={10}>
                        <div className=''>
                        <Image src={item.image} alt={item.title}  preview={false} />
                        </div>
                      </Col>
                      <Col span={14}>
                        <Space className='w-100 h-100' direction='vertical'>
                          <Text className='text-gray'>{item.date}</Text>
                          <Title  className='m-0 text-white' level={4}>{item.title}</Title>
                        </Space>
                      </Col>
                    </Row>
                  </Space>
                </List.Item>
              )}
            />
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export {BlogSider}