import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './index.css'
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Card, Flex,  Typography } from 'antd';
import { servicesMainData } from '../../data'; 
import { useNavigate } from 'react-router-dom';

const {Text, Title} = Typography
const SlideCard = () => {

  const navigate = useNavigate()
  

  return (
    <div >
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      >
        
          {
            servicesMainData?.map((items, index)=>
              <SwiperSlide key={index}>
              <Card className='border0 bg-transparent h-100 cursor'>
                <Flex vertical gap={20} align='center'>
                  <div style={{width: 120, height: 120}}>
                    {
                      items?.img
                    }
                  </div>
                  <Title level={3} className='gradient-text m-0'>
                    {
                      items?.title
                    }
                  </Title>
                  <Text className='text-gray fs-16' align='center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.</Text>
                </Flex>
              </Card>
              </SwiperSlide>
            )
          }
        
      </Swiper>
    </div>
  );
}

export {SlideCard};