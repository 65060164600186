import { RouterCust } from "./RouterCust"


function App() {
  return (
    <>
      <RouterCust />
    </>
  )
}

export default App
