import React from 'react'
import { Typography,Flex, Row, Col } from 'antd'
import { Breadcrumb, Partners } from '../../components'
import { motion } from 'framer-motion'

const { Text, Title } = Typography
const LearnMorePage = () => {

    const data = {
        partitle:'Learn More About Web 3.0',
        detail:[
            {
                desc:'Mozeff was founded with a clear vision: to revolutionize the financial industry by harnessing the power of Web3 technology. We believe that the future of finance should be accessible, transparent, and secure for everyone. Our mission is to break down barriers and create financial solutions that empower individuals and businesses alike. At the core of our values are innovation, integrity, and inclusivity. We are committed to building a financial ecosystem that is fair, equitable, and sustainable, ensuring that everyone has the opportunity to thrive in the digital economy.'
            },
            {
                desc:'Mozeff was founded with a clear vision: to revolutionize the financial industry by harnessing the power of Web3 technology. We believe that the future of finance should be accessible, transparent, and secure for everyone. Our mission is to break down barriers and create financial solutions that empower individuals and businesses alike. At the core of our values are innovation, integrity, and inclusivity. We are committed to building a financial ecosystem that is fair, equitable, and sustainable, ensuring that everyone has the opportunity to thrive in the digital economy.'
            },
        ]
    }
  return (
    <div>
        <Breadcrumb title='Learn More' pageName='Learn More' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='container mt-5'>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Flex vertical justify='center'>
                        <motion.div
                            initial={{ opacity: 0}}
                            whileInView={{
                                opacity: 1
                            }}
                            transition={{ duration: .3, ease: 'easeInOut' }}
                            viewport={{once:true}}
                        >
                            <Title level={1} className='gradient-text text-center'>
                                {data?.partitle}
                            </Title>
                        </motion.div>
                        {
                            data?.detail?.map((item,index)=>
                                <motion.div
                                    key={index}
                                    initial={{ y: '-10%', opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{ duration: .3, type:'spring', stiffness: 300 }}
                                    viewport={{once:true}}
                                    className='py-3'
                                >
                                    <Text className='text-gray fs-16 text-justify' key={index}>
                                        {
                                            item?.desc
                                        }
                                    </Text>
                                </motion.div>
                            )
                        }
                    </Flex>
                </Col>
                <Col span={24}>
                    <Partners />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {LearnMorePage}