
import React from 'react'
import { Card, Col, Descriptions, Image, Row, Space, Typography } from 'antd'
import { motion } from 'framer-motion'
import { UnderlineDesign } from '../../UnderlineDesign'

const { Title, Text } = Typography

const HowNftWorks = () => {
    const data =[
        {
            icon: "/assets/icons/wallet.png",
            title: "Set up your Wallet",
            descriptions:"Upload your NFTs quickly and share your digital assets with the world, making them accessible to collectors everywhere. With an intuitive interface, you can effortlessly transform your creations into blockchain-verified digital collectibles. Simplify the process and bring your unique content to the global stage in just a few clicks.",
        },
        {
            icon: "/assets/icons/upload.png",
            title: "Create your Collection",
            descriptions:"Design and launch your unique NFT collection, showcasing your creativity to a global audience. Transform your art, music, or innovations into digital assets that stand out in the growing blockchain marketplace. Build a loyal following and gain visibility through decentralized platforms that connect you with collectors worldwide.",
        },
        {
            icon: "/assets/icons/sell.png",
            title: "Add your NFTs",
            descriptions:"Easily set up your crypto wallet to begin your NFT journey, enabling secure and seamless transactions. Protect your digital assets with blockchain technology, ensuring authenticity and ownership verification. With a reliable wallet, you can manage your NFTs, trade confidently, and explore new opportunities in the digital space.",
        }
    ]
    
  return (
    <div className='padding'>
        <Row gutter={[30, 30]}>
            <Col span={24}>
                <Space className=' w-100' align='center'>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Title level={1} className='gradient-text mb-1'>
                            How NFT Works
                        </Title>
                        <UnderlineDesign />
                    </motion.div>
                </Space>
            </Col>
            {
                data.map((item,index)=>(
                    <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <Space direction='vertical' size={30}  >
                            <Card direction='vertical' className='gradient center border0' style={{width: "70px" ,height: "70px"}}>
                                <Image src={item.icon} alt={item.title} width={40} height={40}  />
                            </Card>
                            <Space direction="vertical" size={15} >
                                <Title level={3} className='text-white m-0'>{item.title}</Title> 
                                <Text className='text-gray text-justify'>{item.descriptions} </Text>
                            </Space>
                        </Space>
                    </Col>
                ))
            }
        </Row>
    </div>
  )
}

export {HowNftWorks}