import React, { useRef } from "react";
import { Col, Row, Space, Typography } from "antd";
import CountUp from "react-countup";
import "./index.css";
import { motion, useInView } from "framer-motion";

const CounterComponent = () => {
  const targetRef = useRef(null);
  const isInView = useInView(targetRef, { once: true });

  const data = [
    {
      count: 150,
      sign: "k+",
      title: "Active Users",
      id: "first",
    },
    {
      count: 4.9,
      title: "Rating Out of 5",
    },
    {
      count: 99,
      sign: "k+",
      title: "Positive Reviews",
    },
    {
      count: 85,
      sign: "k+",
      title: "Users Satisfied",
    },
  ];

  return (
    <motion.div className="padding" ref={targetRef}>
      <Row gutter={[24, 24]}>
        {data?.map((item, index) => (
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            className="text-center"
            key={index}
          >
            <Space
              direction="vertical"
              align="center"
              className={item?.id === "first" ? null : "countspace"}
            >
              <Typography.Title level={1} className="text-white m-0 fontst">
                {isInView ? (
                  <CountUp duration={2} end={item.count} />
                ) : (
                  0
                )}
                {item?.sign}
              </Typography.Title>
              <Typography.Text className="text-white">
                {item?.title}
              </Typography.Text>
            </Space>
          </Col>
        ))}
      </Row>
    </motion.div>
  );
};

export { CounterComponent };
