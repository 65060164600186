import React from 'react'
import { Button, Card, Col,  Flex, Row, Typography } from 'antd'
import { motion } from "framer-motion";
import { ShieldBlockchainSvg, ShieldBlockchainSvg1 } from '../../../SvgsFile';
import { Typewriter } from '../../PageComponents';
const { Text, Title } = Typography

const UnleashFinance = () => {

    // const targetRef = useRef(null)
    // const { scrollYProgress } = useScroll({
    //     target: targetRef,
    //     offset:["end end", "end start"]
    // })
    // const opacity = useTransform( scrollYProgress, [0, .5], [1, 0] )
    // const scale = useTransform( scrollYProgress, [0, .5], [1, .5] )

    const messages = [
        'The Potential Of Finance'
    ]

    const orbits = [
        { radius: 20, centerX: 150, centerY: 150, duration: 3 },
        { radius: 40, centerX: 150, centerY: 150, duration: 5 },
        { radius: 60, centerX: 150, centerY: 150, duration: 7 },
        { radius: 80, centerX: 150, centerY: 150, duration: 9 },
        { radius: 100, centerX: 150, centerY: 150, duration: 4 },
      ];
    

  return (
    <motion.div className='padding'
        // ref={targetRef}
        // style={{
        //     opacity,
        //     scale,
        // }}
    >
        <Row gutter={[24,24]} justify={'space-between'} align={'middle'}>
            <Col lg={{span: 12}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}} >
                <Flex vertical gap={30}>
                    <Flex gap={10}>
                        {/* 
                        <motion.div className='grad-line'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        ></motion.div> */}
                        {/* <motion.div className='sm-icon'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                            style={{width: 40, height:40}}
                        >
                            <ShieldBlockchainSvg1 />
                        </motion.div> */}
                        <motion.div className='sm-icon'
                            initial={{ y: 100, opacity: 0,}}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .3, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                            style={{width: 40, height:40}}
                        >
                            <ShieldBlockchainSvg />
                        </motion.div>
                        <motion.div
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{ delay: .4, duration: .3, type:'spring', stiffness: 300 }}
                            viewport={{once: true}}
                        >
                            
                            <Title level={1} className='m-0 gradient-text'>
                                <Typewriter messages={messages} speed={150} />
                            </Title>
                        </motion.div>
                    </Flex>
                    <motion.div
                        initial={{ y: -100, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .5, duration: .3, type:'spring', stiffness: 300 }}
                        viewport={{once: true}}
                    >
                        <Text className='text-gray fs-16'
                        >
                            Experience the power of decentralized finance right at your fingertips, transforming the way you manage your assets and engage with the financial world.
                        </Text>
                    </motion.div>
                    <motion.Flex
                        initial={{ y: -50, opacity: 0 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{ delay: .6, duration: .3, }}
                        viewport={{once: true}}
                    >
                        <Button className='btn w-auto'>
                            <div className='content vertical '>
                                <div className="text">Learn More</div>
                                <div className="hover-text">Learn More</div>
                            </div>
                        </Button>
                    </motion.Flex>
                </Flex>
            </Col>
            <Col lg={{span: 10}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                <Card className='card-grad card-extra'>
                    <motion.div
                        initial={{ x: '-30vw' }}
                        animate={{ x: 500 }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: "loop",
                        }}
                        className='top-small-bar'
                    ></motion.div>
                    <Flex justify='center'>
                        <div style={{width: 300}}>
                            <motion.svg width="100%" height="100%" viewBox="0 0 300 300">
                                <motion.line
                                    x1="0"
                                    y1="150"
                                    x2="300"
                                    y2="150"
                                    stroke="white"
                                    strokeWidth="1"
                                    strokeDasharray="5 5"
                                    animate={{
                                    strokeDashoffset: [0, 20, 0],
                                    }}
                                    transition={{
                                    repeat: Infinity,
                                    duration: 2,
                                    ease: "linear",
                                    }}
                                />
                                {orbits.map((orbit, index) => (
                                    <circle
                                    key={index}
                                    cx={orbit.centerX}
                                    cy={orbit.centerY}
                                    r={orbit.radius}
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="1"
                                    />
                                ))}

                                {orbits.map((orbit, index) => {
                                    return (
                                    <motion.circle
                                        key={index}
                                        cx={orbit.centerX + orbit.radius}
                                        cy={orbit.centerY}
                                        r="5"
                                        fill="white"
                                        animate={{
                                        rotate: 360, 
                                        transition: {
                                            repeat: Infinity, 
                                            duration: orbit.duration, 
                                            ease: "linear", 
                                        },
                                        }}
                                        style={{
                                            originX: '50%',
                                            originY: '50%',
                                        }}
                                    />
                                    );
                                })}
                            </motion.svg>
                        </div>
                    </Flex>
                    <Title level={5} className='text-white m-0' style={{transform: 'translateY(-60px)'}}>
                        DeFi: Finance without limits.
                    </Title>
                    <div className='parent-shap'>
                        <div className='bg-shape'></div>
                    </div>
                </Card>                        
            </Col>
        </Row>
    </motion.div>
  )
}

export {UnleashFinance}