import React from "react";
import { motion } from "framer-motion";

const DeeplearningSvg = () => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 300.000000 300.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.10, written by Peter Selinger 2001-2011
        </metadata>
        <g
          transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
          fill="#fff"
          stroke="none"
        >
          <path
            d="M1165 2974 c-159 -28 -319 -95 -443 -186 -78 -57 -181 -162 -232
-238 -63 -92 -135 -251 -166 -365 -26 -93 -28 -114 -28 -300 -1 -175 2 -211
21 -287 63 -246 193 -464 359 -599 l66 -54 -6 -125 c-8 -189 -41 -338 -136
-610 -41 -119 -49 -167 -28 -188 17 -17 1329 -17 1346 0 14 14 15 86 2 148 -6
25 -31 109 -57 186 -25 78 -43 149 -40 157 3 9 18 24 32 33 28 18 42 16 305
-41 120 -27 212 -15 251 31 8 11 23 48 33 83 9 35 37 102 61 149 25 46 45 95
45 108 0 22 -25 51 -74 87 -16 12 -14 14 23 25 22 7 45 21 51 32 5 10 10 57
10 104 l0 85 58 11 c77 14 86 21 90 63 6 68 -49 179 -160 325 -81 107 -81 106
-83 302 -2 191 -12 270 -51 400 -115 378 -410 617 -827 670 -113 14 -326 11
-422 -6z m431 -89 c395 -59 649 -284 746 -660 16 -63 21 -122 25 -280 3 -142
9 -214 20 -248 9 -28 49 -93 95 -155 70 -93 128 -193 128 -221 0 -5 -28 -14
-62 -20 -37 -6 -68 -18 -75 -28 -8 -11 -13 -51 -13 -109 l0 -92 -29 -10 c-43
-15 -71 -48 -71 -82 0 -23 11 -42 45 -76 25 -24 45 -46 45 -48 0 -2 -16 -30
-35 -62 -20 -32 -45 -90 -56 -129 -12 -38 -26 -74 -33 -78 -17 -12 -119 0
-236 29 -158 38 -219 41 -272 15 -101 -50 -109 -109 -40 -316 27 -82 52 -165
56 -182 l6 -33 -591 0 c-559 0 -591 1 -584 18 4 9 25 69 47 132 84 248 118
424 118 620 0 119 0 120 -27 142 -84 67 -222 221 -267 296 -166 280 -200 619
-95 938 115 346 408 589 775 643 97 14 269 12 380 -4z m1073 -1593 c1 -12 -47
-37 -56 -29 -2 3 7 20 19 39 18 28 24 32 30 19 4 -9 7 -22 7 -29z m-128 -61
c-24 -27 -26 -27 -29 -9 -4 22 13 38 39 38 13 0 11 -6 -10 -29z m-65 -213
c-44 -36 -45 -57 -6 -101 17 -19 30 -45 30 -58 -1 -22 -2 -22 -14 6 -8 17 -28
43 -45 59 -42 40 -40 67 7 102 20 15 41 35 47 43 8 14 10 14 13 -3 3 -12 -9
-29 -32 -48z m-1714 -25 c22 -27 30 -43 20 -43 -4 0 -18 14 -30 30 -24 32 -15
42 10 13z m-115 -903 c25 -30 25 -30 141 -31 l117 -2 -145 -8 c-82 -5 -148 -5
-153 0 -9 9 0 71 9 71 3 0 17 -13 31 -30z m1240 -24 c-5 -13 -23 -16 -94 -14
-75 2 -79 2 -26 5 54 3 65 8 85 32 21 26 24 27 32 10 5 -10 6 -25 3 -33z
m-189 -11 c-7 -7 -770 -7 -776 0 -3 3 172 5 388 5 216 0 391 -2 388 -5z"
          />
          <motion.path
            fill="#fff"
            initial={{
              rotate: 360,
              scale: 0.8,
            }}
            animate={{
              rotate: 0,
              scale: 0.6,
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            d="M1700 2758 c-71 -37 -100 -120 -65 -188 15 -28 14 -32 -11 -70 -26
                    -38 -29 -40 -80 -40 l-54 0 0 104 c0 132 -3 136 -110 136 -97 0 -110 -10 -110
                    -84 l0 -53 -42 -12 c-24 -7 -62 -23 -85 -36 l-42 -23 -40 39 c-21 21 -47 39
                    -57 39 -24 0 -224 -201 -224 -225 0 -10 18 -35 39 -55 l39 -38 -24 -48 c-14
                    -27 -30 -65 -36 -86 -11 -38 -12 -38 -64 -38 -81 0 -84 -7 -84 -166 0 -75 5
                    -144 10 -155 9 -15 22 -19 69 -19 l58 0 13 -42 c7 -24 23 -64 36 -89 l23 -46
                    -40 -39 c-21 -21 -39 -45 -39 -53 0 -23 201 -221 224 -221 11 0 37 16 57 36
                    l37 36 54 -26 c29 -14 68 -29 86 -32 31 -6 32 -8 32 -56 0 -82 7 -88 108 -88
                    55 0 92 4 100 12 8 8 12 49 12 118 l0 106 53 -1 c50 0 53 -2 80 -41 26 -39 27
                    -43 12 -70 -63 -121 86 -255 203 -184 126 77 73 270 -75 270 -34 0 -45 5 -54
                    23 -7 12 -25 39 -41 60 -30 37 -30 37 -104 37 l-74 0 0 70 0 70 86 0 c82 0 90
                    -2 159 -40 71 -39 72 -41 78 -86 4 -36 13 -55 40 -82 30 -30 44 -36 89 -39 62
                    -5 106 17 138 69 73 121 -67 265 -193 200 l-38 -20 -77 44 -77 44 -102 0 -103
                    0 0 90 0 90 164 0 c156 0 165 -1 171 -20 10 -33 71 -72 118 -77 107 -10 191
                    112 142 206 -8 16 -28 40 -45 55 -61 51 -181 26 -212 -44 l-13 -30 -162 0
                    -163 0 0 95 0 95 104 0 c103 0 105 0 181 41 75 41 78 42 105 25 38 -22 108
                    -20 148 4 120 74 79 253 -61 267 -79 8 -157 -59 -157 -136 0 -34 -4 -38 -70
                    -73 -65 -36 -74 -38 -160 -38 l-90 0 0 70 0 70 80 0 80 0 37 59 c35 57 38 59
                    78 59 154 -3 202 209 62 272 -54 25 -73 25 -127 -2z m133 -48 c73 -58 26 -180
                    -69 -180 -35 0 -84 -33 -112 -76 -12 -19 -29 -34 -38 -34 -10 0 -6 8 14 25 46
                    39 57 76 42 140 -15 61 -4 104 34 130 30 22 98 19 129 -5z m-470 -58 c3 -4 -5
                    -19 -18 -34 -19 -22 -24 -24 -29 -12 -12 31 31 73 47 46z m77 -12 c17 -32 1
                    -44 -26 -19 -13 12 -21 26 -18 30 9 16 33 9 44 -11z m-40 -164 l0 -134 -52
                    -12 c-75 -18 -143 -54 -200 -108 -178 -167 -184 -442 -12 -617 58 -59 152
                    -111 220 -121 l44 -6 0 -134 c0 -127 -1 -134 -20 -134 -18 0 -20 7 -20 54 0
                    30 -4 58 -10 61 -5 3 -36 13 -67 21 -32 8 -86 31 -121 49 -34 19 -68 35 -74
                    35 -7 0 -28 -16 -48 -35 l-36 -35 -54 55 -54 55 32 33 c36 37 40 67 14 105
                    -24 35 -58 117 -67 162 -11 52 -22 60 -79 60 l-51 0 -1 80 0 80 52 0 c57 0 68
                    8 79 60 4 19 24 72 46 117 21 45 39 91 39 102 0 11 -16 32 -35 48 l-35 29 58
                    57 58 57 34 -35 c19 -19 41 -35 50 -35 9 0 45 15 80 34 36 19 88 40 115 47 69
                    17 75 23 75 85 0 47 2 54 20 54 19 0 20 -7 20 -134z m-80 79 c0 -24 -24 -45
                    -36 -32 -5 5 26 56 34 57 1 0 2 -11 2 -25z m-280 -61 c17 -15 46 -30 63 -34
                    27 -6 29 -8 13 -15 -13 -6 -28 -3 -45 8 -66 42 -68 42 -111 13 -36 -24 -100
                    -98 -100 -116 0 -4 11 -24 25 -44 28 -42 30 -61 10 -96 -14 -24 -15 -24 -9 15
                    5 34 1 46 -25 79 -17 21 -31 41 -31 44 0 8 163 172 171 172 4 0 22 -12 39 -26z
                    m433 -106 c-15 -15 -28 -28 -30 -28 -2 0 -3 26 -3 57 l0 57 30 -29 30 -29 -27
                    -28z m547 27 c5 -3 17 -20 26 -37 22 -42 11 -90 -27 -122 -35 -30 -72 -33
                    -129 -10 -32 13 -47 14 -66 6 -30 -14 -30 -14 1 28 14 19 25 46 25 61 0 73
                    101 117 170 74z m-570 -125 c0 -37 6 -56 24 -77 l24 -28 -24 -28 c-21 -25 -24
                    -38 -24 -113 0 -78 2 -87 26 -111 l25 -25 -25 -27 c-24 -25 -26 -34 -26 -111
                    0 -77 2 -86 26 -111 l25 -27 -25 -25 c-22 -23 -26 -35 -26 -88 0 -55 4 -67 27
                    -91 l27 -28 -32 -33 -32 -32 0 63 c0 86 -15 107 -99 132 -183 54 -301 219
                    -289 403 11 166 118 299 281 349 42 12 74 29 85 43 23 32 32 22 32 -35z m-619
                    -232 c-5 -13 -18 -24 -32 -26 l-24 -4 24 26 c30 32 43 33 32 4z m-87 -26 c3
                    -5 -8 -21 -24 -37 l-28 -27 2 24 c2 14 6 30 10 36 8 14 32 16 40 4z m1276 -49
                    c62 -56 42 -151 -36 -172 -47 -13 -86 3 -137 56 l-37 39 38 41 c21 22 47 45
                    58 51 35 20 81 14 114 -15z m-1292 -173 l23 -30 -25 0 c-23 0 -36 16 -36 46 0
                    22 14 16 38 -16z m96 -42 c24 -34 19 -44 -9 -18 -14 13 -25 27 -25 32 0 16 19
                    8 34 -14z m86 -201 c0 -9 -12 -35 -26 -56 -24 -37 -25 -41 -10 -69 9 -17 20
                    -34 26 -37 5 -3 10 -10 10 -15 0 -5 -18 9 -40 31 -22 22 -40 42 -40 45 0 3 14
                    25 31 49 26 36 31 50 25 77 -6 30 -5 31 8 13 9 -11 16 -28 16 -38z m1085 9
                    c70 -30 83 -120 24 -170 -67 -57 -169 -9 -169 78 0 13 -9 35 -21 50 l-20 26
                    28 0 c15 0 46 7 68 15 50 18 49 18 90 1z m-346 -209 c32 -52 57 -69 113 -78
                    98 -15 139 -100 82 -168 -48 -58 -129 -49 -168 18 -17 30 -18 38 -7 82 17 64
                    7 102 -36 144 -24 23 -31 35 -20 35 8 0 25 -15 36 -33z m-529 3 c12 -8 11 -10
                    -6 -10 -12 0 -40 -14 -64 -31 -38 -28 -45 -30 -66 -18 -13 7 -31 24 -40 37
                    l-17 25 31 -21 c39 -28 60 -28 97 3 33 27 43 29 65 15z m188 -79 c8 -4 12 -17
                    10 -28 -3 -18 -5 -17 -26 9 -23 27 -14 38 16 19z m62 -125 c0 -3 -11 -6 -25
                    -6 -18 0 -26 7 -31 24 -10 41 5 49 32 17 13 -16 24 -32 24 -35z m80 40 c0 -30
-13 -46 -36 -46 l-25 0 23 30 c24 32 38 38 38 16z"
          />
        </g>
      </svg>
    </>
  );
};

export {DeeplearningSvg};
