import React from 'react';
import { Card, Form, Flex, Typography, Button } from 'antd';
import ReactApexChart from 'react-apexcharts';

const { Text, Title } = Typography;

const ServicesLineChart = () => {
  const [form] = Form.useForm();

  const chartData = {
    series: [
      {
        name: 'Transaction Volume',
        data: [120, 140, 130, 400, 300, 170, 180, 400, 0],
      },
      {
        name: 'Gas Fees',
        data: [50, 340, 55, 190, 65, 75, 300, 85, 90],
      },
      {
        name: 'Active Wallets',
        data: [200, 220, 400, 230, 240, 20, 260, 270, 280],
      },
      
    ],
    options: {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
        ],
        labels: {
          style: {
            colors: '#fff',
          },
        },
      },
      grid: {
        show: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: '#fff',
          },
        },
      },
      colors: ['#007BFF', '#00C49F', '#FFBB28'],
      legend: {
        position: 'top',
        labels:{
          colors:"#fff" ,
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={300}
      />
    </div>
  );
};

export { ServicesLineChart };
